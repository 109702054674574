.report_main {
  height: 90vh;
  width: 100%;
  .accounts_report {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .first_point {
      width: 30%;

      .welcome {
        font-size: 1.4rem;
        margin: 2rem 0 3rem 0;
        font-weight: 600;
        color: rgb(6, 111, 153);
      }

      .form_input {
        width: 100%;
        margin-bottom: 1rem;

        label {
          font-size: 1.3rem;
          font-weight: 600;
        }

        input {
          @include input;
          width: 100%;
          font-size: 1.3rem;
        }
      }

      .from_to {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;

        .covering {
          font-size: 1.4rem;
          font-weight: 600;
          color: rgb(6, 111, 153);
          width: 100%;
        }
        .form_input {
          width: 45%;

          input {
            @include input;
            width: 100%;
          }
        }
      }
      .submit {
        @include submit_button;
        font-size: 1.3rem;
        margin-top: 2rem;
      }
    }

    .main_report {
      width: 100%;
      height: 93%;
      padding: 2rem;
      display: grid;
      grid-template-columns: 0.25fr 1fr;
      grid-column-gap: 2rem;

      .summary_of-firstpoint {
        background: #eee;
        padding: 1rem;
        height: fit-content;
        height: -moz-fit-content;
        .info_captured {
          font-size: 1.3rem;
          font-weight: 600;
          margin-bottom: 1rem;

          span {
            font-weight: 500;
          }
        }
      }

      .other_forms {
        height: 100%;
        width: 100%;
        overflow-y: auto;

        .form_section {
          margin-bottom: 5rem;
          .form_title {
            font-size: 1.7rem;
            font-weight: 600;
            margin-bottom: 2rem;
            color: rgb(32, 0, 107);
          }

          .form_container {
            margin: 0 0 1rem 0rem;

            input,
            select {
              @include input;
              width: 20%;
              margin-right: 1rem;
              font-size: 1.3rem;
              padding: 0.8rem 1rem;
            }

            .inv {
              width: 10%;
            }
            .descr {
              width: 30%;
            }

            .amount {
              width: 10%;
            }

            .add {
              @include primary_button;
              font-size: 1.3rem;
              color: teal;
            }
          }

          .table {
            thead,
            tbody {
              tr {
                th,
                td {
                  font-size: 1.3rem;
                }
              }
            }
          }

          .total_container {
            font-size: 1.4rem;
            font-weight: bold;
            justify-content: flex-end;
            background: rgb(193, 232, 248);
            padding: 1rem;
            span {
              margin: 0 2rem;
              font-weight: 500;
            }
          }
        }
      }
    }
    .submit {
      @include submit_button;
      font-size: 1.3rem;
    }
  }

  .bd_container {
    height: 90vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .submit {
      @include submit_button;
      font-size: 1.3rem;
      margin-top: 5rem;
    }

    .first_section {
      height: 100%;
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .words {
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 5rem;
        width: 50rem;
      }

      input {
        @include input;
        width: 40rem;
      }

      .date_container {
        width: 40rem;
        display: flex;
        margin-top: 2rem;
        .form_input {
          margin-right: 2rem;
          width: 32%;

          label {
            font-size: 1.3rem;
            font-weight: 600;
          }

          input {
            @include input;
            width: 100%;
            font-size: 1.3rem;
          }
        }
      }
    }

    .stepper_container {
      display: flex;
      height: 100%;
      width: 100%;
      .stepper_section-container {
        background: rgb(247, 247, 247);
        height: 100%;
        width: 19%;
        padding: 0rem 2rem 2rem 2rem;

        img {
          height: 5rem;
          width: 5rem;
          object-fit: contain;
        }

        .hold_steps-btn {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 95%;
          .step {
            background: none;
            outline: none;
            border: none;
            font-size: 1.3rem;
            color: rgb(138, 138, 138);
            width: 100%;
            text-align: left;
            padding: 0;
            display: flex;
            align-items: center;
            color: grey;

            .num {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 4rem;
              width: 4rem;
              background: grey;
              border-radius: 50px;
              margin-right: 1rem;
              color: #eee;
              position: relative;

              &::after {
                content: '';
                height: 8rem;
                width: 0.2rem;
                display: block;
                background: grey;
                position: absolute;
                bottom: -8rem;
              }
            }

            &:hover {
              cursor: not-allowed;
            }
          }

          .active {
            font-weight: 600;
            color: $employee_profile-color;
            cursor: pointer !important;
            position: relative;
            .num {
              background: $employee_profile-color;
              color: rgb(183, 183, 255);
              font-weight: 700;

              &::after {
                content: '';
                height: 8rem;
                width: 0.2rem;
                display: block;
                background: $employee_profile-color;
                position: absolute;
                bottom: -8rem;
              }
            }
          }
          .completed {
            font-weight: 600;
            color: $employee_profile-color;
            cursor: pointer !important;

            .num {
              background: $employee_profile-color;
              color: rgb(183, 183, 255);
              font-weight: 700;

              &::after {
                content: '';
                height: 8rem;
                width: 0.2rem;
                display: block;
                background: $employee_profile-color;
                position: absolute;
                bottom: -8rem;
              }
            }
          }
          .finish {
            margin-left: 5rem;
          }
        }
      }

      .step_content-container {
        padding: 1rem;
        .subject {
          font-size: 1.3rem;

          button {
            border: none;
            outline: none;
            background: $employee_profile-color;
            color: #e7e7e7;
            padding: 1rem;
            border-radius: 5px;
            margin-left: 1rem;
          }
        }

        .form_container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .form_title {
            font-size: 1.7rem;
            margin-bottom: 2rem;
            width: 100%;
          }

          .form_input {
            width: 49%;
            margin-bottom: 1rem;

            label {
              font-size: 1.3rem;
              font-weight: 600;
            }

            input {
              @include input;
              width: 100%;
              font-size: 1.3rem;
            }
          }

          .submit {
            margin-top: 2rem;
          }
        }

        .old_enteries {
          display: flex;
          margin-top: 2rem;
          justify-content: space-between;

          .entry {
            width: 49%;
            background: #eee;
            padding: 1rem;
            border-radius: 5px;
            .info {
              font-size: 1.3rem;
              font-weight: 600;
              .data {
                font-weight: normal;
              }
            }
          }
        }

        .button_container {
          .submit {
            margin-top: 1rem;
          }

          .save {
            background: none;
            border: none;
            outline: none;
            font-size: 1.3rem;
            margin-left: 1rem;
          }
        }
      }
    }

    .second_bd-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      .first_section {
        width: 40%;
      }

      .form_input {
        width: 100%;
        margin-bottom: 1rem;

        label {
          font-size: 1.3rem;
          font-weight: 600;
        }

        textarea {
          @include input;
          width: 100%;
          font-size: 1.3rem;
          resize: none;
        }
      }

      .main_report {
        width: 100%;
        height: 93%;
        padding: 2rem;
        display: grid;
        grid-template-columns: 0.25fr 1fr;
        grid-column-gap: 2rem;

        .summary_of-firstpoint {
          background: #eee;
          padding: 1rem;
          height: fit-content;
          height: -moz-fit-content;
          .info_captured {
            font-size: 1.3rem;
            font-weight: 600;
            margin-bottom: 1rem;

            span {
              font-weight: 500;
            }
          }

          .label {
            font-size: 1.3rem;
            font-weight: 700;
          }

          .achieved {
            font-size: 1.3rem;
          }
        }

        .contain_form {
          height: 100%;
          .other_forms {
            height: 90%;
            width: 100%;
            overflow-y: auto;

            .form_section {
              margin-bottom: 5rem;
              .form_title {
                font-size: 1.7rem;
                font-weight: 600;
                margin-bottom: 2rem;
                color: rgb(32, 0, 107);
              }

              .form_container {
                margin: 0 0 1rem 0rem;

                input,
                select {
                  @include input;
                  width: 20%;
                  margin-right: 1rem;
                  font-size: 1.3rem;
                  padding: 0.8rem 1rem;
                }

                .inv {
                  width: 10%;
                }
                .descr {
                  width: 30%;
                }

                .amount {
                  width: 10%;
                }

                .add {
                  @include primary_button;
                  font-size: 1.3rem;
                  color: teal;
                }
              }

              .table {
                thead,
                tbody {
                  tr {
                    th,
                    td {
                      font-size: 1.3rem;
                    }
                  }
                }
              }

              .total_container {
                font-size: 1.4rem;
                font-weight: bold;
                justify-content: flex-end;
                background: rgb(193, 232, 248);
                padding: 1rem;
                span {
                  margin: 0 2rem;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }

  .environmental_container {
    padding: 2rem;
    height: 100%;
    width: 100%;
    .from_to {
      width: 20%;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 2rem;

      .covering {
        font-size: 1.4rem;
        font-weight: 600;
        color: rgb(6, 111, 153);
        width: 100%;
      }
      .form_input {
        width: 45%;
        label {
          font-size: 1.2rem;
          font-weight: 600;
        }

        input {
          @include input;
          width: 100%;
        }
      }
    }

    .other_forms {
      height: 70vh;
      width: 100%;
      overflow-y: auto;

      .form_section {
        margin-bottom: 5rem;
        .form_title {
          font-size: 1.7rem;
          font-weight: 600;
          margin-bottom: 2rem;
          color: rgb(32, 0, 107);
        }

        .form_container {
          margin: 0 0 1rem 0rem;

          input,
          select {
            @include input;
            width: 20%;
            margin-right: 1rem;
            font-size: 1.3rem;
            padding: 0.8rem 1rem;
          }

          .inv {
            width: 10%;
          }
          .descr {
            width: 20%;
          }

          .amount {
            width: 10%;
          }

          .add {
            @include primary_button;
            font-size: 1.3rem;
            color: teal;
          }
        }

        .table {
          thead,
          tbody {
            tr {
              th,
              td {
                font-size: 1.3rem;
              }
            }
          }
        }

        .total_container {
          font-size: 1.4rem;
          font-weight: bold;
          justify-content: flex-end;
          background: rgb(193, 232, 248);
          padding: 1rem;
          span {
            margin: 0 2rem;
            font-weight: 500;
          }
        }
      }
    }
  }
  .hr_container {
    padding: 2rem;
    height: 100%;
    width: 100%;
    .from_to {
      width: 20%;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 2rem;

      .covering {
        font-size: 1.4rem;
        font-weight: 600;
        color: rgb(6, 111, 153);
        width: 100%;
      }
      .form_input {
        width: 45%;
        label {
          font-size: 1.2rem;
          font-weight: 600;
        }

        input {
          @include input;
          width: 100%;
        }
      }
    }

    .other_forms {
      height: 70vh;
      width: 100%;
      overflow-y: auto;

      .form_section {
        margin-bottom: 5rem;
        .form_title {
          font-size: 1.7rem;
          font-weight: 600;
          margin-bottom: 2rem;
          color: rgb(32, 0, 107);
        }

        .form_container {
          margin: 0 0 1rem 0rem;

          input,
          select {
            @include input;
            width: 20%;
            margin-right: 1rem;
            font-size: 1.3rem;
            padding: 0.8rem 1rem;
          }

          .inv {
            width: 10%;
          }
          .descr {
            width: 20%;
          }

          .amount {
            width: 10%;
          }

          .add {
            @include primary_button;
            font-size: 1.3rem;
            color: teal;
          }
        }

        .table {
          thead,
          tbody {
            tr {
              th,
              td {
                font-size: 1.3rem;
              }
            }
          }
        }

        .total_container {
          font-size: 1.4rem;
          font-weight: bold;
          justify-content: flex-end;
          background: rgb(193, 232, 248);
          padding: 1rem;
          span {
            margin: 0 2rem;
            font-weight: 500;
          }
        }
      }
    }
  }

  .hse_container {
    padding: 2rem;
    height: 100%;
    width: 100%;
    .first_point {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .welcome {
        font-size: 1.4rem;
        margin: 2rem 0 3rem 0;
        font-weight: 600;
        color: rgb(6, 111, 153);
      }

      .form_input {
        width: 30%;
        margin-bottom: 1rem;

        label {
          font-size: 1.3rem;
          font-weight: 600;
        }

        input,
        select {
          @include input;
          width: 100%;
          font-size: 1.3rem;
        }
      }

      .from_to {
        width: 30%;
        flex-wrap: wrap;
        justify-content: space-between;

        .covering {
          font-size: 1.4rem;
          font-weight: 600;
          color: rgb(6, 111, 153);
          width: 100%;
        }
        .form_input {
          width: 45%;

          input,
          select {
            @include input;
            width: 100%;
          }
        }
      }
      .submit {
        @include submit_button;
        font-size: 1.3rem;
        margin-top: 2rem;
      }
    }

    .select_incident-type,
    .step-2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 98%;

      .top_question {
        width: 75%;

        .question {
          font-size: 3rem;
          color: #183994;
        }

        .tip {
          font-size: 1.5rem;
          color: grey;
        }
      }

      .options {
        width: 75%;
        height: 35%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .card {
          min-width: 20%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          border: none;
          outline: none;
          position: unset;
          .image_btn {
            @include format_btn;
            height: 80%;
            width: 80%;
            border-radius: 10px;
            padding: 2rem;
            border: 2px solid #d2e6f8;
            .icon {
              width: 100%;
              height: 100%;
            }

            &:hover {
              border: 2px solid #1f92f6;
            }
          }

          .active_btn {
            border: 3px solid #1f92f6;
          }

          .option_name {
            padding: 1rem 0;
            width: 80%;
            position: relative;
            display: flex;
            white-space: wrap;

            .name {
              font-size: 1.5rem;
              color: #21445a;
              font-weight: 500;
              display: flex;
              align-items: center;
            }

            .hold_last {
              i {
                margin-left: 0.5rem;
                color: #0076be;
                font-size: 2rem;
                cursor: pointer;

                &:hover {
                  color: black;
                }

                &:hover ~ .tooltips {
                  display: block;
                }
              }
              .tooltips {
                width: 35rem;
                background: rgb(77, 77, 77);
                box-shadow: 0 0 10px #eee;
                position: absolute;
                left: 0;
                padding: 1rem;
                font-size: 1.4rem;
                border-radius: 10px;
                display: none;
                color: rgb(212, 212, 212);
              }
            }
          }
        }
      }
    }

    .step-2 {
      .top_question {
        width: 100%;

        .question {
          font-size: 2.5rem;
          color: #183994;
        }

        .tip {
          font-size: 1.5rem;
          color: grey;
        }
      }
      .form_container {
        padding-left: 1rem;
        width: 40%;
        &:not(:last-of-type) {
          margin-right: 2rem;
        }

        .info_input {
          // margin-bottom: 1rem;
          label {
            display: block;
            font-size: 0.9vw;
          }

          input,
          select,
          textarea {
            @include registeration_form(100%);
            height: -moz-fit-content;
            height: fit-content;
            resize: none;
          }
        }
      }

      .info_input {
        // margin-bottom: 1rem;
        margin-bottom: 0;
        margin-right: 1rem;
        width: 15%;
        label {
          display: block;
          font-size: 0.9vw;
        }

        input,
        select,
        textarea {
          @include registeration_form(100%);
          height: -moz-fit-content;
          height: fit-content;
          resize: none;
        }
      }

      .btn {
        padding: 1rem 1.2rem;
        margin-top: 1rem;
        font-size: 1.4rem;
      }

      .btn_container {
        width: 100%;
      }

      .table-responsive {
        height: 70%;
        tr {
          th,
          td {
            font-size: 1.3rem;
          }
        }
      }

      .modal-body {
        tr {
          th,
          td {
            font-size: 1.3rem;

            table {
              th,
              td {
                width: 50%;
                border-bottom: none;
                padding: 0.5rem 0 !important;

                input {
                  @include input;
                  width: 12rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .btn_container {
    display: flex;
    width: 75%;
    justify-content: flex-start;
    align-items: center;
    .back {
      @include format_btn;
      font-size: 1.5rem;
      background: #eee;
      padding: 1rem 2rem;

      &:hover {
        background: grey;
      }
    }
    .submit_btn {
      @include submit_button;
      font-size: 1.5rem;
      margin-left: 1rem;
    }
  }

  .hsse_qms-facility {
    padding: 2rem;
    height: 100%;
    width: 100%;
    .from_to {
      width: 20%;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 2rem;

      .covering {
        font-size: 1.4rem;
        font-weight: 600;
        color: rgb(6, 111, 153);
        width: 100%;
      }
      .form_input {
        width: 45%;
        label {
          font-size: 1.2rem;
          font-weight: 600;
        }

        input {
          @include input;
          width: 100%;
        }
      }
    }

    .other_forms {
      height: 70vh;
      width: 100%;
      overflow-y: auto;

      .form_section {
        margin-bottom: 5rem;
        .form_title {
          font-size: 1.7rem;
          font-weight: 600;
          margin-bottom: 2rem;
          color: rgb(32, 0, 107);
        }

        .form_container {
          margin: 0 0 1rem 0rem;

          input,
          select {
            @include input;
            width: 20%;
            margin-right: 1rem;
            font-size: 1.3rem;
            padding: 0.8rem 1rem;
          }

          .inv {
            width: 10%;
          }
          .descr {
            width: 20%;
          }

          .amount {
            width: 10%;
          }

          .add {
            @include primary_button;
            font-size: 1.3rem;
            color: teal;
          }
        }

        .table {
          thead,
          tbody {
            tr {
              th,
              td {
                font-size: 1.3rem;
              }
            }
          }
        }

        .total_container {
          font-size: 1.4rem;
          font-weight: bold;
          justify-content: flex-end;
          background: rgb(193, 232, 248);
          padding: 1rem;
          span {
            margin: 0 2rem;
            font-weight: 500;
          }
        }
      }
    }
  }

  .field_report {
    padding: 2rem;
    height: 100%;
    width: 100%;
    .from_to {
      width: 20%;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 2rem;

      .covering {
        font-size: 1.4rem;
        font-weight: 600;
        color: rgb(6, 111, 153);
        width: 100%;
      }
      .form_input {
        width: 25%;
        label {
          font-size: 1.2rem;
          font-weight: 600;
        }

        input {
          @include input;
          width: 100%;
        }
      }
    }

    .other_forms {
      height: 70vh;
      width: 100%;
      overflow-y: auto;

      .form_section {
        margin-bottom: 5rem;
        .form_title {
          font-size: 1.7rem;
          font-weight: 600;
          margin-bottom: 2rem;
          color: rgb(32, 0, 107);
        }

        .form_container {
          margin: 0 0 1rem 0rem;

          input,
          select {
            @include input;
            width: 15%;
            margin-right: 1rem;
            font-size: 1.3rem;
            padding: 0.8rem 1rem;
          }

          .add {
            @include primary_button;
            font-size: 1.3rem;
            color: teal;
          }
        }

        .table {
          thead,
          tbody {
            tr {
              th,
              td {
                font-size: 1.3rem;
              }
            }
          }
        }

        .total_container {
          font-size: 1.4rem;
          font-weight: bold;
          justify-content: flex-end;
          background: rgb(193, 232, 248);
          padding: 1rem;
          span {
            margin: 0 2rem;
            font-weight: 500;
          }
        }
      }
    }
  }

  .it_section {
    .it-container {
      padding: 2rem;
      height: 100%;
      width: 100%;
      .from_to {
        width: 20%;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 2rem;

        .covering {
          font-size: 1.4rem;
          font-weight: 600;
          color: rgb(6, 111, 153);
          width: 100%;
        }
        .form_input {
          width: 25%;
          label {
            font-size: 1.2rem;
            font-weight: 600;
          }

          input {
            @include input;
            width: 100%;
          }
        }
      }

      .other_forms {
        height: 70vh;
        width: 100%;
        overflow-y: auto;

        .form_section {
          margin-bottom: 5rem;
          .form_title {
            font-size: 1.7rem;
            font-weight: 600;
            margin-bottom: 2rem;
            color: rgb(32, 0, 107);
          }

          .form_container {
            margin: 0 0 1rem 0rem;

            input,
            select {
              @include input;
              width: 15%;
              margin-right: 1rem;
              font-size: 1.3rem;
              padding: 0.8rem 1rem;
            }

            .add {
              @include primary_button;
              font-size: 1.3rem;
              color: teal;
            }
          }

          .table {
            thead,
            tbody {
              tr {
                th,
                td {
                  font-size: 1.3rem;
                }
              }
            }
          }

          .total_container {
            font-size: 1.4rem;
            font-weight: bold;
            justify-content: flex-end;
            background: rgb(193, 232, 248);
            padding: 1rem;
            span {
              margin: 0 2rem;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}


.slick_line{
  .form_input {
    width: 100%;
    label {
      font-size: 1.2rem;
      font-weight: 600;
    }

    input,textarea {
      @include input;
      width: 100%;
      font-size: 1.3rem;
    }
  }
  .first_point{
    padding:0 2rem;
    display:grid;
    grid-template-columns: .3fr 1fr;
    grid-column-gap: 2rem;
    .date{
      width:auto !important;
    }


    .entry_list{
      height:70vh;
      overflow-y:scroll ;
      .entry{
        .activity_title,.date{
          font-size: 1.5rem;
          margin-bottom: 0;
        }

        .date{
          margin-left: 1rem;
        }

        .activity_entered{
          font-size: 1.4rem;
        }
      }
    }
  }


  .btn{
    font-size: 1.4rem;
    margin-top: 2rem;
  }

  .btn_holder{
    text-align: center;
    padding:1rem 0;
  }
}
