.weekly_report-main {
  display: flex;
  align-items: center;
  justify-content: center;

  .weekly_report-table {
    width: 100%;
    padding: 2rem;
    .the_row {
      display: grid;
      grid-template-columns: 0.1fr 0.2fr 0.3fr 1fr 0.1fr;
      align-items: center;
      padding: 1rem;
      .item {
        font-size: 0.9vw;
        input {
          @include registeration_form(80%);
          height: -moz-fit-content;
          height: fit-content;
          font-size: 0.9vw;
          margin: 0;
        }

        .ti-more-alt {
          font-weight: bold;
          cursor: pointer;
        }
      }

      &:nth-child(odd) {
        background: #f1f1f1;
        box-shadow: 0 2px 1px rgb(216, 216, 216);
      }
    }
  }

  .new_report_modal {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.863);

    .hold_new-entry {
      background: white;
      box-shadow: 0 0 15px rgb(12, 12, 12);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 60%;

      .hold_modal-top {
        width: 100%;
        padding:1rem 2rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        background: #f1f1f1;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        .modal_title {
          font-size: 1.3vw;
          font-weight: 500;
        }

        .ti-close {
          font-size: 1.2vw;
          color: red;
          cursor: pointer;

          &:hover {
            transform: scale(1.5);
          }
        }
      }

      .staff_name{
        font-size: 1.2vw;
        font-weight: 600;
        text-align: left;
        width:50%;

        .name{
          font-weight: normal;
        }
      }

      .contain_small-input {
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: space-between;

        input {
          width: 40%;
        }
      }

      .info_input {
        width: 50%;
        margin-bottom: 1rem;

        label {
          display: block;
          font-size: 0.8vw;
          font-weight: 500;
          margin-bottom: 0.5rem;
        }

        input,
        textarea {
          @include input;
          font-family: sans-serif;
        }
      }
    }
  }

  .button_hold {
    text-align: center;
    padding: 2rem 0;

    .previous {
      @include primary_button;
      cursor: pointer;
      padding: 1rem;
    }
    .next {
      @include submit_button;
    }
  }
}

.top_hold {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-right: 2rem;
  padding-top: 1rem;

  .create {
    @include primary_button;
    padding: 1rem;
  }
}

.view_report-main {
  .filter_section {
    display: flex;
    align-items: center;
    padding: 0 2rem;
    margin-top: 2rem;

    .info_input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0.5rem;
      border-bottom: 1px solid #000;
      @include input;
      width: 30%;

      input {
        width: 95%;
        font-size: 0.9vw;
        border: none;
        outline: none;
      }

      i {
        font-size: 1vw;
      }
    }

    .other_filter {
      padding-left: 2rem;
      display: flex;
      width: 50%;
      .filter_by {
        @include primary_button;
        padding: 1rem;
        background: grey;
        color: white;
        cursor: pointer;
        height: fit-content;
        height: -moz-fit-content;
        margin-right: 3rem;

        &:hover {
          background: darken(grey, 10);
          color: white;
        }
      }

      .department_btn,
      .date {
        @include primary_button;
        padding: 1rem;
        font-size: 0.8vw;
        margin-right: 1rem;
        cursor: pointer;
      }

      .department_filter {
        .department_list {
          width: 20%;
          background: rgb(253, 253, 253);

          .department_name {
            font-size: 0.9vw;
            display: block;
            font-weight: normal;
            padding: 0.5rem;
            cursor: pointer;

            &:hover {
              background: rgb(221, 221, 221);
            }
            &:not(:last-child) {
              border-bottom: 1px solid rgb(221, 221, 221);
            }
          }
        }
      }

      .date_contain {
        background: rgb(253, 253, 253);
        @include shadow;
        position: absolute;
        z-index: 10;
        width: 25%;
        display: flex;
        justify-content: space-between;
        padding: 2rem;

        .info_input {
          width: 40%;
          margin-bottom: 1rem;
          box-shadow: none;
          background: none;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          label {
            display: block;
            font-size: 0.8vw;
            font-weight: 500;
            margin-bottom: 0.5rem;
          }

          input {
            @include input;
            font-family: sans-serif;
          }
        }
      }

      .filter {
        @include submit_button;
      }
    }
  }
  .weekly_report-table {
    width: 100%;
    padding: 2rem;
    .the_row {
      display: grid;
      grid-template-columns: 0.1fr 0.2fr 0.3fr 0.5fr 0.5fr 1fr 0.5fr;
      grid-column-gap: 1rem;
      align-items: center;
      padding: 1rem;
      .item {
        font-size: 0.9vw;

        .ti-more-alt {
          font-weight: bold;
          cursor: pointer;
        }
      }

      &:nth-child(odd) {
        background: #f1f1f1;
        box-shadow: 0 2px 1px rgb(216, 216, 216);
      }
    }
  }
}
