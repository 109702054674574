.navigation {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  background: $white;
  @include shadow;
  width: 100%;

  .logo {
    display: flex;
    align-items: center;
    h5 {
      font-size: 1.2vw;
      margin-right: 2rem;
    }

    .hand_burger {
      img {
        width: 1.4vw;
        cursor: pointer;
      }
    }
  }

  .right_elements {
    .user_greeting {
      display: flex;
      align-items: center;

      .ti-layout-grid3-alt {
        font-size: 1.2vw;
        margin-right: 2rem;
        color: rgb(80, 80, 80);
        cursor: pointer;

        &:hover {
          color: black;
        }
      }

      .nav_img-icon {
        margin-right: 0.5rem;
      }

      .greetings {
        display: inline-block;
        font-size: 1vw;
        color: $green;

        .name {
          color: black;
        }
      }
    }
  }
}
