.main_dashbord {
  height: 98vh;
  width: 100%;
  box-sizing: border-box;

  .information_cards-container {
    width: 100%;
    height: 15vh;
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    .card_informarion {
      width: 25rem;
      height: 100%;
      display: flex;
      align-items: center;

      .card_title {
        width: 45%;
        height: 100%;
        font-size: 1.4rem;
        color: rgb(226, 226, 226);
        padding: 3rem;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: unset;
        border-radius: 10px;
        box-shadow: 0 0 10px rgb(143, 143, 143);
        margin-right: 1rem;
        font-weight: 600;
      }

      .card_stats {
        color: #36284d;
        font-weight: 600;
        font-size: 2.5rem;
      }
    }

    .purple {
      background: #4200ab;
    }

    .teal {
      background: #00567d;
    }

    .navy {
      background: #08003e;
    }

    .orange_signal {
      background: rgb(231, 62, 0);
    }
  }

  .other_dashboard-contents {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 10rem;

    .calendar {
      width: 30%;

      img {
        width: 100%;
      }
    }

    .attendance_graph {
      width: 30%;

      img {
        width: 100%;
      }
    }

    .mini_card {
      width: 30%;
      display: flex;

      .the_card {
        height: 15rem;
        width: 12rem;

        box-shadow: 0 0 15px rgb(209, 209, 209);
        border-radius: 10px;

        .information {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          font-size: 1.5rem;
          color: rgb(39, 39, 39);
          font-weight: 600;
        }

        &:not(:last-child) {
          margin-right: 3rem;
        }
      }

      .peaches {
        background: #c89dba;
        .information {
          span {
            font-size: 4rem;
            color: rgb(116, 0, 44);
            font-weight: normal;
          }
        }
      }

      .green_card {
        background: #95c695;
        .information {
          span {
            color: #004100;
            font-size: 4rem;
            font-weight: normal;
          }
        }
      }

      .blue_card {
        background: #95a2c6;
        .information {
          span {
            color: #000141;
            font-size: 4rem;
            font-weight: normal;
          }
        }
      }
    }

    .dash_project-card {
      margin: 2rem 0;
      width: 65%;
      height: 30rem;
      display: grid;
      grid-template-columns: 0.25fr 1fr;
      background: white;
      box-shadow: 0 0 15px rgb(190, 190, 190);
      border-radius: 10px;

      .brief_project {
        padding: 2rem;
        .mini_card_title {
          font-size: 1.8rem;
          margin-bottom: 2rem;
        }

        .mini_project-card {
          background: #edf6ed;
          border-radius: 10px;
          height: 60%;
          p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 1.8rem;
            height: 100%;

            span {
              font-size: 5rem;
              color: #009e10;
            }
          }
        }
      }

      .briefing_table {
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding: 1rem;

        .briefing_row {
          display: grid;
          grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr 1fr;
          padding: 1rem;

          &:not(:last-child) {
            border-bottom: 1px solid rgb(212, 212, 212);
          }

          &:first-child {
            background: #eee;
            font-weight: 600;
            border-bottom: unset;

            .the_brief {
              color: black;
            }
          }

          .the_brief {
            font-size: 1.3rem;
            display: flex;
            align-items: center;
            color: rgb(68, 68, 68);

            .percentile {
              color: #005c09;
              font-weight: 600;
            }

            .progress_bar {
              background: #eee;
              height: 1rem;
              width: 90%;
              border-radius: 10px;
              position: relative;

              .color_bar {
                position: absolute;
                top: 0;
                left: 0;
                background: #009e10;
                width: 45%;
                height: 100%;
                border-radius: 10px;
              }
            }
          }
        }
      }
    }

    .meeting_card {
      background: white;
      box-shadow: 0 0 15px rgb(190, 190, 190);
      border-radius: 10px;
      width: 30%;
      height: 30rem;
      margin: 2rem 0;
      overflow: hidden;

      .card_heading {
        font-size: 1.8rem;
        padding: 1rem;
      }

      .card_table {
        height: 90%;
        overflow: hidden;
        .card_row {
          display: grid;
          grid-template-columns: 1.5fr 0.3fr 0.3fr;
          font-size: 1.3rem;
          padding: 1rem;
          .meeting_detail {
            font-weight: 600;
          }

          &:nth-child(odd) {
            background: #eee;
          }
        }
      }
    }

    .appraisal_card {
      margin: 2rem 0;
      width: 48%;
      height: 24rem;
      background: white;
      box-shadow: 0 0 15px rgb(190, 190, 190);
      border-radius: 10px;
      overflow: hidden;

      .appraisal_table {
        width: 100%;
        height: 90%;
        .appraisal_preview-row {
          display: grid;
          grid-template-columns: 0.2fr 1fr 1fr 1fr 0.5fr;
          font-size: 1.3rem;
          padding: 1rem;
          &:nth-child(odd) {
            background: #eee;
          }

          &:first-child {
            font-weight: 600;
          }
          .preview_data {
          }
        }
      }
    }

    .travel_card {
      margin: 2rem 0;
      width: 50%;
      height: 24rem;
      background: white;
      box-shadow: 0 0 15px rgb(190, 190, 190);
      border-radius: 10px;
      overflow: hidden;

      .card_topper {
        background: rgb(39, 39, 39);
        color: rgb(230, 228, 228);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        font-size: 1.3rem;
        font-weight: 600;
      }

      .travel_table {
        width: 100%;
        height: 90%;
        .travel_preview-row {
          display: grid;
          grid-template-columns: 1fr 0.6fr 0.6fr 0.6fr 0.6fr 0.6fr;
          font-size: 1.3rem;
          padding: 1rem;
          &:nth-child(odd) {
            background: #eee;
          }

          &:first-child {
            font-weight: 600;
          }
          .preview_data {
          }
        }
      }
    }

    .Incident_report-card {
      margin: 2rem 0;
      width: 48%;
      height: 24rem;
      background: white;
      box-shadow: 0 0 15px rgb(190, 190, 190);
      border-radius: 10px;
      overflow: hidden;

      .card_topper {
        background: rgb(39, 39, 39);
        color: rgb(230, 228, 228);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        font-size: 1.3rem;
        font-weight: 600;
      }

      .incident_rep_table {
        width: 100%;
        height: 90%;
        .incident_rep_preview-row {
          display: grid;
          grid-template-columns: 0.5fr 1fr 1fr 1fr;
          font-size: 1.3rem;
          padding: 1rem;
          &:nth-child(odd) {
            background: #eee;
          }

          &:first-child {
            font-weight: 600;
          }
          .preview_data {
          }
        }
      }
    }

    .bookings_preview-card {
      margin: 2rem 0;
      width: 50%;
      height: 24rem;
      background: white;
      box-shadow: 0 0 15px rgb(190, 190, 190);
      border-radius: 10px;
      overflow: hidden;

      .card_topper {
        background: rgb(39, 39, 39);
        color: rgb(230, 228, 228);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        font-size: 1.3rem;
        font-weight: 600;
      }

      .booking_table {
        width: 100%;
        height: 90%;
        .booking_preview-row {
          display: grid;
          grid-template-columns: 1fr 0.7fr 0.5fr 0.5fr 0.5fr;
          font-size: 1.3rem;
          padding: 1rem;
          &:nth-child(odd) {
            background: #eee;
          }

          &:first-child {
            font-weight: 600;
          }
          .preview_data {
          }
        }
      }
    }
  }

  .see {
    border: none;
    font-size: 1.2rem;
    font-weight: 600;
    color: orange;
    background: none;
    outline: none;
    text-align: center;
    // width: 100%;
  }

  .card_topper {
    background: rgb(39, 39, 39);
    color: rgb(230, 228, 228);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    font-size: 1.3rem;
    font-weight: 600;
  }
}
