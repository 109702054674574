.receive_package {
  display: grid;
  grid-template-columns: 0.3fr 1fr;

  .form_holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #eee;
    height: 83vh;
    padding: 3rem 1rem;
    box-sizing: border-box;
    .title {
      margin-top: 0;
      font-size: 1.2vw;
      margin-bottom: 0;
      margin-left: 0;
      padding-left: 0;
      text-align: left;
      padding-top: 0;
    }

    .hold_input {
      height: 80%;
      overflow-y: scroll;

      .info_input {
        margin-bottom: 0;
        label {
          display: block;
          font-size: 0.9vw;
        }

        input,
        select {
          @include registeration_form(94%);
          height: fit-content;
        }
      }
    }

    .btn_hold {
      text-align: center;

      .submit {
        @include submit_button;
      }
    }
  }

  .content_right {
    padding: 1rem 2rem;
    .top {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;

      .secondary-btn {
        @include primary_button;
        margin-bottom: 1rem;
        margin-left: 1rem;
      }
    }

    .site_table {
      .the_row {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        grid-column-gap: 1rem;
        padding: 1rem;

        &:nth-child(odd) {
          background: #f1f1f1;
          box-shadow: 0 2px 1px rgb(216, 216, 216);
        }

        &:first-child {
          font-weight: bold;
        }

        .item {
          font-size: 0.9vw;
        }
      }
    }
  }
}

// sending module===========================================sending module===================sending module
.send_package {
  display: grid;
  grid-template-columns: 0.3fr 1fr;

  .form_holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #eee;
    height: 83vh;
    padding: 3rem 1rem;
    box-sizing: border-box;
    .title {
      margin-top: 0;
      font-size: 1.2vw;
      margin-bottom: 0;
      margin-left: 0;
      padding-left: 0;
      text-align: left;
      padding-top: 0;
    }

    .hold_input {
      height: 80%;
      overflow-y: scroll;

      .info_input {
        margin-bottom: 0;
        label {
          display: block;
          font-size: 0.9vw;
        }

        input,
        select {
          @include registeration_form(94%);
          height: fit-content;
        }
      }
    }

    .btn_hold {
      text-align: center;

      .submit {
        @include submit_button;
      }
    }
  }

  .content_right {
    padding: 1rem 2rem;
    .top {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;

      .secondary-btn {
        @include primary_button;
        margin-bottom: 1rem;
        margin-left: 1rem;
      }
    }

    .site_table {
      height: 70vh;
      .the_row {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        grid-column-gap: 1rem;
        padding: 1rem;

        &:nth-child(odd) {
          background: #f1f1f1;
          box-shadow: 0 2px 1px rgb(216, 216, 216);
        }

        &:first-child {
          font-weight: bold;
        }

        .item {
          font-size: 0.9vw;
        }
      }
    }
  }
}

.hide_element {
  display: none !important;
}

.hide_grid {
  display: block;
}

.the_top {
  display: flex;
  align-items: center;
  background: #eee;
  padding: 1rem;
  margin-bottom: 2rem;

  .title {
    padding: 0;
  }
  .other_links {
    margin-left: 5rem;
    .links {
      @include primary_button;
      font-size: 0.8vw;
      cursor: pointer;
      margin-right: 1rem;

      &:hover {
        color: #ff9000;
      }
    }
  }
}

.btn_hold {
  text-align: center;

  .submit {
    @include submit_button;
  }
}

.team_list {
  position: relative;

  .see_more {
    background: none;
    border: none;
    outline: none;
    color: blue;
    cursor: pointer;
  }

  .the_list {
    position: absolute;
    top: 2rem;
    right: 0;
    background: white;
    font-size: 0.8vw;
    @include shadow;
    width: fit-content;
    width: -moz-fit-content;
    padding: 2rem;
    z-index: 1000;

    .team_member {
      list-style: none;
      &:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }
}

.search_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include input;
  width: 40%;

  .ti-search {
    font-size: 1vw;
    font-weight: bold;
  }
  input {
    border: none;
    outline: none;
    width: 93%;
    font-size: 1vw;
  }
}
