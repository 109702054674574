$shadow: rgb(206, 206, 206);

$dark_shadow: rgb(224, 224, 224);

$table_grey: #d9d9d9;

$white: #fff;
$black: #000;
$green: #03af03;
$button_green-1: #03ad03;
$button_green-2: #003300;
$orange: #ff9000;

$body_grey: #fafafa;

// boxes
$pink_one: #f5d5f4;
$pink_two: #f8d4d5;
$g_box: #c4ddc4;

$employee_profile-color: #00001d;
$employee_name: #0099ff;
