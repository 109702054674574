* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
 "Lucida Sans Unicode", Verdana, sans-serif; }

html {
  font-size: 62.5%; }

body {
  width: 100%;
  height: 100vh;

}



.index {
  width: 100%;
  height: 100vh;
  padding: 2rem 0rem 2rem 6rem;
  background: linear-gradient(to right, #374540, #374540), url('/assets/img/background.png');
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply; }
  @media (max-width: 600px) {
    .index {
      height:unset } }
  @media (max-width: 600px) {
    .index {
      padding: 2rem; } }
  .index .home_container {
    height: 100%;
    display: flex;
    align-items: center; }
    .index .home_container .article_container {
      height: 100%;
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      @media (max-width: 600px) {
        .index .home_container .article_container {
          width: 100%; } }
      .index .home_container .article_container .welcome {
        color: lightgray; }
        .index .home_container .article_container .welcome img {
          margin-bottom: 1rem;
          width: 5rem;
          height: 5rem;
          object-fit: contan; }
        .index .home_container .article_container .welcome .txt {
          font-size: 1.9rem;
          margin-bottom: 0.5rem;
          font-weight: normal;
          color: #c8dc91; }
        .index .home_container .article_container .welcome .pro_title {
          font-size: 2.8rem; }
      .index .home_container .article_container .msg {
        font-size: 1.4rem;
        line-height: 1.3;
        width: 80%;
        color: #f1f1f1; }
        @media (max-width: 600px) {
          .index .home_container .article_container .msg {
            width: 100%; } }
      .index .home_container .article_container .form_sec {
        margin-top: 2rem; }
        .index .home_container .article_container .form_sec .question {
          font-size: 1.4rem;
          font-weight: 500;
          color: #eee; }
        .index .home_container .article_container .form_sec .form_container {
          margin-top: 0.5rem; }
          @media (max-width: 600px) {
            .index .home_container .article_container .form_sec .form_container form {
              display: flex;
              flex-direction: column; } }
          .index .home_container .article_container .form_sec .form_container form input {
            background: none;
            border: 1px solid rgba(255, 255, 255, 0.534);
            padding: 1.5rem;
            border-radius: 5px;
            outline: none;
            color: #fefefe;
            font-size: 1.4rem;
          margin-right: 1rem;}
            @media (max-width: 600px) {
              .index .home_container .article_container .form_sec .form_container form input {
                margin-bottom: 1rem;
              margin-right: 0;} }
            .index .home_container .article_container .form_sec .form_container form input:active, .index .home_container .article_container .form_sec .form_container form input:focus {
              border: 2px solid rgba(255, 166, 0, 0.644); }
          .index .home_container .article_container .form_sec .form_container form button {
            font-size: 1.4rem;
            background: #f4b000;
            border: none;
            outline: none;
            padding: 1.5rem;
            border-radius: 5px; }
      .index .home_container .article_container .info {
        color: #c8dc91;
        font-size: 1.4rem;
      width:unset }
      .index .home_container .article_container .button_container .btn {
        font-size: 1.4rem;
        background: white;
        color: #202020;
        border: none;
        outline: none;
        padding: 1rem;
        border-radius: 5px;
        margin-top: 1rem;
        cursor: pointer;
        animation: pulse 2s infinite ease-in-out; }

        @media (max-width: 600px) {
          .index .home_container .article_container .button_container .btn {
            font-size: 1.2rem; } }
        .index .home_container .article_container .button_container .btn:hover {
          background: #6bb50c;
          color: white; }
        .index .home_container .article_container .button_container .btn:not(:last-of-type) {
          margin-right: 1rem; }
    .index .home_container .img {
      width: 40%;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center; }
      @media (max-width: 600px) {
        .index .home_container .img {
          display: none; } }
      .index .home_container .img img {
        width: 45rem;
        height: 45rem;
        object-fit: cover;
        object-position: center;
        border-radius: 500rem; }
    .index .home_container .quick_links {
      display: flex;
      padding-left: 0!important; }
      @media (max-width: 600px) {
        .index .home_container .quick_links {
          flex-direction: column; } }
      .index .home_container .quick_links li {
        font-size: 1.2rem;
        color: #efefef;
        list-style: none;
        font-weight: 500;
        text-wrap: nowrap; }
        @media (max-width: 600px) {
          .index .home_container .quick_links li{
            text-wrap: wrap;
          width:100} }
        .index .home_container .quick_links li:not(:last-of-type) {
          margin-right: 1rem; }
        .index .home_container .quick_links li a {
          color: #efefef;
          text-decoration: none;
          font-weight: 500;
          color: #f4b000; }

.page_header {
  width: 100%;
  height: 30%;
  background: linear-gradient(to right, #374540, #374540), url('/assets/img/background.png');
  background-size: cover;
  background-position: bottom center;
  background-blend-mode: multiply;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center; }
  @media (max-width: 600px) {
    .page_header {
      height: 20%; } }
  .page_header .page_sum {
    color: #d8d8d8;
    font-size: 2rem;
    text-transform: uppercase; }
    @media (max-width: 600px) {
      .page_header .page_sum {
        font-size: 1.5rem; } }
  .page_header .btn {
    font-size: 1.4rem; }

.top_left {
  padding: 0 5rem;
  margin: 2rem 0 1rem 0; }
  @media (max-width: 600px) {
    .top_left {
      padding: 0 1rem;
      margin-top: 1rem; } }
  .top_left .page_instruction {
    font-size: 2rem;
    margin-bottom: 0; }
  .top_left p {
    color: #cf8e00;
    font-size: 1.2rem; }

.accordion {
  padding: 0 5rem; }
  @media (max-width: 600px) {
    .accordion {
      padding: 0 1rem; } }
  .accordion .accordion-item .accordion-header button {
    font-size: 1.4rem;
    color: #e4e4e4;
    background: #4e545a; }
  .accordion .accordion-item .accordion-body {
    font-size: 1.4rem; }
    .accordion .accordion-item .accordion-body .position_list .position {
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      padding: 1rem; }
      @media (max-width: 600px) {
        .accordion .accordion-item .accordion-body .position_list .position {
          text-align: left;
          border-radius: 0; }
          .accordion .accordion-item .accordion-body .position_list .position:not(:last-of-type) {
            border-bottom: 1px solid #e9e9e9; } }
      .accordion .accordion-item .accordion-body .position_list .position:hover {
        background: #e0e0e0; }
      .accordion .accordion-item .accordion-body .position_list .position .count {
        width: 1rem;
        height: 1rem;
        background: green;
        display: inline-block;
        padding: 0.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.5rem;
        border-radius: 10rem;
        color: #ededed; }

.job_preview-sec {
  width: 100%;
  padding: 0 5rem;
  display: flex;
  gap: 3rem; }
  @media (max-width: 600px) {
    .job_preview-sec {
      display: block;
      gap: 0;
      padding: 0 1rem; } }
  .job_preview-sec .job_listing-container .job_listing {
    height: 52vh;
    overflow: scroll;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem; }
    .job_preview-sec .job_listing-container .job_listing .job_card {
      width: 40rem;
      height: 19rem;
      border: 1.5px solid #ccc;
      padding: 1rem;
      border-radius: 5px;
      cursor: pointer; }
      @media (max-width: 600px) {
        .job_preview-sec .job_listing-container .job_listing .job_card {
          width: 100%;
          height: unset; } }
      .job_preview-sec .job_listing-container .job_listing .job_card:hover {
        border: 1.5px solid #ffae00; }
      .job_preview-sec .job_listing-container .job_listing .job_card .job_title {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 0.2rem; }
      .job_preview-sec .job_listing-container .job_listing .job_card .dept {
        font-size: 1.2rem;
        color: grey;
        margin-bottom: 0.5rem; }
      .job_preview-sec .job_listing-container .job_listing .job_card .detail {
        font-size: 1.2rem; }
      .job_preview-sec .job_listing-container .job_listing .job_card .btn {
        font-size: 1.2rem;
        font-weight: 500; }
      .job_preview-sec .job_listing-container .job_listing .job_card .btn-primary {
        background: #374540; }
  .job_preview-sec .job_preview {
    padding: 1rem 0; }
    @media (max-width: 600px) {
      .job_preview-sec .job_preview .job_preview-card {
        padding: 1rem;
        background: white;
        border-radius: 5px; } }
    @media (max-width: 600px) {
      .job_preview-sec .job_preview {
        position: fixed;
        background: rgba(0, 0, 0, 0.89);
        top: 0;
        left: 0;
        height: 100vh;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center; } }
    .job_preview-sec .job_preview .job_title {
      font-size: 1.5rem;
      margin-bottom: 0.2rem; }
    .job_preview-sec .job_preview .dept {
      font-size: 1.4rem;
      color: grey; }
    .job_preview-sec .job_preview .about_job {
      margin-bottom: 3rem; }
      .job_preview-sec .job_preview .about_job .sec_title {
        font-size: 0.9rem;
        font-weight: 700; }
      .job_preview-sec .job_preview .about_job p {
        font-size: 1.4rem;
        margin-bottom: 0.5rem; }
    .job_preview-sec .job_preview .btn-primary {
      background: #374540;
      font-size: 1.4rem; }

.badge {
  background-color: #0d3c0d;
  border-radius: 50%;
  color: white;
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 2px;
  font-size: 1rem !important; }

.stepper {
  margin: 0;
  padding: 0;
  border: 1px solid black;
  display: inline-flex;
  flex-direction: row;
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%; }
  @media (max-width: 600px) {
    .stepper {
      display: none; } }
  .stepper__wrapper {
    display: flex;
    justify-content: center;
    width: 100%; }
  .stepper__item {
    width: 100%; }
  .stepper__item:first-child .stepper__link {
    padding-left: 20px; }
  .stepper__item:last-child .stepper__link {
    padding-right: 20px; }
  .stepper__link {
    background: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    text-decoration: none;
    padding: 10px 0 10px 40px;
    position: relative;
    gap: 0.5rem; }
    .stepper__link::after {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      /* Go big on the size, and let overflow hide */
      border-bottom: 50px solid transparent;
      border-left: 30px solid white;
      position: absolute;
      top: 50%;
      margin-top: -50px;
      left: 100%;
      z-index: 2; }
    .stepper__link::before {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 30px solid white;
      position: absolute;
      top: 50%;
      margin-top: -50px;
      margin-left: 1px;
      left: 100%;
      z-index: 1; }
  .stepper__icon {
    margin-bottom: 0; }

.stepper__link:hover {
  background-color: #ffcd5b;
  color: #000; }
  .stepper__link:hover::after {
    border-left-color: #ffcd5b; }

.stepper__link--active, .stepper__link--active:hover {
  background-color: #0d3c0d;
  color: #fff; }
  .stepper__link--active::after, .stepper__link--active:hover::after {
    border-left-color: #0d3c0d; }

.stepper__link--done {
  background-color: #05c305;
  color: #000; }
  .stepper__link--done::after {
    border-left-color: #05c305; }
  .stepper__link--done .fa-check {
    color: #05c305; }

.stepper__link--disabled, .stepper__link--disabled:hover {
  background-color: #ccc;
  color: #333; }
  .stepper__link--disabled::after, .stepper__link--disabled:hover::after {
    border-left-color: #ccc; }

.stepper_text {
  font-size: 1.4rem; }

.stepper_content-container {
  width: 100%; }
  .stepper_content-container .stepper_content {
    padding: 1rem 5rem; }
    @media (max-width: 600px) {
      .stepper_content-container .stepper_content {
        padding: 1rem; } }
    .stepper_content-container .stepper_content .step_title {
      font-size: 2rem;
      color: #242424; }
      .stepper_content-container .stepper_content .step_title span {
        font-size: 1.2rem;
        display: block;
        color: #ff9000;
        width: 50%; }
        @media (max-width: 600px) {
          .stepper_content-container .stepper_content .step_title span {
            width: 100%; } }
    .stepper_content-container .stepper_content .hold_form {
      display: flex;
      gap: 3rem;
      width: 100%;
      margin: 2rem 0; }
      @media (max-width: 600px) {
        .stepper_content-container .stepper_content .hold_form {
          display: block; } }
      .stepper_content-container .stepper_content .hold_form .input_passport {
        width: 15rem;
        height: 14rem;
        min-width: 10rem;
        background: #f3f3f3;
        position: relative;
        border: 1.5px dashed grey;
        border-radius: 5px; }
        @media (max-width: 600px) {
          .stepper_content-container .stepper_content .hold_form .input_passport {
            margin-bottom: 1rem; } }
        .stepper_content-container .stepper_content .hold_form .input_passport input {
          height: 100%;
          width: 100%;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0; }
        .stepper_content-container .stepper_content .hold_form .input_passport label {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%; }
          .stepper_content-container .stepper_content .hold_form .input_passport label i {
            font-size: 3rem;
            color: grey; }
          .stepper_content-container .stepper_content .hold_form .input_passport label span {
            font-size: 1.4rem;
            width: 70%;
            text-align: center; }
      .stepper_content-container .stepper_content .hold_form .input_container {
        width: 90%;
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap; }
        @media (max-width: 600px) {
          .stepper_content-container .stepper_content .hold_form .input_container {
            width: 100%;
            display: block; } }
        .stepper_content-container .stepper_content .hold_form .input_container .form-floating {
          width: 24%; }
          @media (max-width: 600px) {
            .stepper_content-container .stepper_content .hold_form .input_container .form-floating {
              width: 100%;
              margin-bottom: 0.5rem; } }
          .stepper_content-container .stepper_content .hold_form .input_container .form-floating .form-select {
            font-size: 1.4rem; }
          .stepper_content-container .stepper_content .hold_form .input_container .form-floating input {
            font-size: 1.4rem; }
          .stepper_content-container .stepper_content .hold_form .input_container .form-floating label {
            font-size: 1.4rem; }
        .stepper_content-container .stepper_content .hold_form .input_container .title {
          width: 24%;
        padding:0; }
          @media (max-width: 600px) {
            .stepper_content-container .stepper_content .hold_form .input_container .title {
              margin-bottom: 0.5rem;
              width: 100%; } }
  .stepper_content-container .btn_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 3rem; }
    .stepper_content-container .btn_container .btn {
      font-size: 1.4rem;
      padding: 1rem; }
    .stepper_content-container .btn_container .btn_primary {
      background: #374540;
      color: #ececec; }

.education {
  display: flex;
  justify-content: space-between;
  width: 40%; }
  @media (max-width: 600px) {
    .education {
      display: block; } }
  .education .add_document_btn {
    font-size: 1.4rem; }
    @media (max-width: 600px) {
      .education .add_document_btn {
        display: block !important; } }
  .education .document_list {
    width: 100%; }
    @media (max-width: 600px) {
      .education .document_list {
        width: 100%; } }
    @media (max-width: 600px) {
      .education .document_list .step_title {
        width: 100%; } }
    .education .document_list .list {
      width: 100%;
      margin-top: 1rem; }
      .education .document_list .list .doc {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.4rem;
        background: #eee;
        padding: 0.5rem 1rem; }
        .education .document_list .list .doc:not(:last-of-type) {
          margin-bottom: 0.3rem; }
        .education .document_list .list .doc button {
          font-size: 1.4rem; }
        .education .document_list .list .doc p {
          margin-bottom: 0; }
  .education .hold_form {
    margin: 0 !important;
    position: fixed;
    background: rgba(0, 0, 0, 0.826);
    top: 0;
    left: 0;
    width: 100%;
    min-width: 100%;
    z-index: 10;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none !important; }
    @media (max-width: 600px) {
      .education .hold_form {
        position: fixed;
        background: rgba(0, 0, 0, 0.826);
        top: 0;
        left: 0;
        width: 100%;
        min-width: 100%;
        z-index: 3;
        height: 100vh;
        display: flex !important;
        align-items: center;
        justify-content: center; } }
    .education .hold_form .input_container {
      flex-direction: column;
      width: 35%;
      max-width: 35%;
      background: white;
      padding: 2rem; }
      @media (max-width: 600px) {
        .education .hold_form .input_container {
          background: white;
          padding: 1rem;
          width: 90%;
          min-width: 90%; } }
      @media (max-width: 600px) {
        .education .hold_form .input_container .top {
          margin-bottom: 2rem; } }
      .education .hold_form .input_container .top .form_title {
        font-size: 1rem;
        margin-bottom: 0; }
      @media (max-width: 600px) {
        .education .hold_form .input_container .top .close {
          display: block !important; } }
    .education .hold_form .form-floating {
      width: 100% !important; }
    .education .hold_form .other_doc {
      width: 50%; }
    .education .hold_form .submit {
      font-size: 1.4rem;
      width: 100%;
      min-width: 100%;
      padding: 1rem; }

.document {
  position: relative;
  width: 100% !important; }
  @media (max-width: 600px) {
    .document {
      width: 100% !important; } }
  .document label {
    height: 100%;
    background: grey;
    margin-bottom: 0;
    position: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 25%; }
    @media (max-width: 600px) {
      .document label {
        width: 30%; } }
  .document input {
    height: 100%;
    width: 100%;
    background: none;
    padding-left: 2.2rem !important; }

.top_area {
  display: flex;
  gap: 2rem;
  width: 100%; }
  @media (max-width: 600px) {
    .top_area {
      display: block; } }
  .top_area img {
    width: 14rem;
    height: 15rem;
    object-fit: cover;
    object-position: top center; }
  .top_area .pri_detail {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    gap: 0rem 1.5rem; }
    @media (max-width: 600px) {
      .top_area .pri_detail {
        width: 100%;
        gap: unset;
        justify-content: space-between; } }
    .top_area .pri_detail .entry_container {
      width: 22%;
      max-width: 25%; }
      @media (max-width: 600px) {
        .top_area .pri_detail .entry_container {
          width: 48%;
          max-width: 49%; } }
      .top_area .pri_detail .entry_container .label {
        font-size: 1rem;
        color: grey; }
      .top_area .pri_detail .entry_container .entry {
        font-size: 1.4rem; }

.preview_container {
  width: 100%; }
  .preview_container .title {
    font-size: 1rem; }
    @media (max-width: 600px) {
      .preview_container .title {
        margin-bottom: 1rem; } }
  .preview_container .qualification_list {
    display: flex;
    gap: 1rem; }
    @media (max-width: 600px) {
      .preview_container .qualification_list {
        flex-wrap: wrap;
        margin-bottom: 1rem; } }
    .preview_container .qualification_list .qualification_card {
      background: #cccc;
      width: fit-content;
      padding: 0.5rem;
      border-radius: 5px; }
      .preview_container .qualification_list .qualification_card .name {
        font-size: 1.4rem;
        margin-bottom: 0; }

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1; }
  50% {
    transform: scale(1.08);
    opacity: 0.8; }
  100% {
    transform: scale(1);
    opacity: 1; } }


    
/* COPY FROM HERE =============COPY FROM HERE==================*/

.information_modal {
  background: rgba(0, 0, 0, 0.733);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.information_modal .info_card {
  background: white;
  width: 50%;
  height: fit-content;
  border-radius: 10px;
  padding: 6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.information_modal .info_card .greet {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 3rem;
  width: 100%;
}

.information_modal .info_card .application_popup-info {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #555555;
}

.information_modal .info_card .info a {
  text-decoration: none;
  font-weight: 700;
}

.information_modal .info_card .bottom_address {
  margin-top: 3rem;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: grey;
  margin-bottom: 3rem;
  width: 100%;
}

.information_modal .info_card .bottom_address .name {
  font-size: 1.4rem;
  color: initial;
}

.information_modal .info_card .close_modal {
  background: none;
  outline: none;
  border: none;
  background: rgba(255, 0, 0, 0.144);
  color: #990000;
  padding: 1rem 1.2rem;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
}

.form-question {
  margin-top: 4rem;
  padding: 2rem;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.form-question .question {
  font-size: 1.6rem;
  color: #474747;
  line-height: 1;
}

.form-question .input_container input[type="radio"] {
  appearance: none;
  cursor: pointer;
}

.form-question .input_container input[type="radio"]::before {
  background: #cacaca;
  font-size: 1rem;
  padding: 1.3rem;
  border-radius: 5px;
  margin-right: 0.5rem;
  box-sizing: border-box;
}

.form-question .input_container input[type="radio"]:checked:before {
  background: #e9c600;
}

.form-question .input_container #yes::before {
  content: "Yes";
}

.form-question .input_container #no::before {
  content: "No";
}