.the_top{
  display:flex;
  justify-content: space-between;
  align-items: center;
  background:#eee;
  margin-bottom: 1rem;

  .title{
    font-size: 1.2vw;
  }


  .other_links{
    width:fit-content;
    width:-moz-fit-content;

    .links{
      @include primary_button;
      padding: 1rem 1rem;
      font-size: .85vw;
      margin-right: 1rem;
    }
  }
}



.the_main--card{
  padding:2rem;
  .training{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
  }


  .last_area{
    display:grid;
    grid-template-columns: .4fr 1fr;
    grid-column-gap: 3rem;
    margin-top: 2rem;

  }
}


.name_overflow{
  position:relative;
  &:hover .list{
    display:block;
  }

  .see_more{
    background:none;
    outline:none;
    border:none;
    font-size: .8vw;
    color:rgb(0, 140, 255);
  }

  .list{
    position: absolute;
    z-index: 500;
    top: 0;
    left:0;
    background:white;
    @include shadow;
    display: none;

    .the_name{
      display: block;
    }
  }


}



.my_training-table{


  .the__training {
    display: grid;
    grid-template-columns: 0.2fr 1fr 1fr 1fr 1fr .7fr .7fr .7fr 1fr;
    padding: 1rem;
    font-size: 1.4rem;
}
}





.info {
  width: 50%;
  display: flex;
  // align-items:center;
  justify-content: center;
  padding: 0 2rem;


  .training_detail {
    text-align: center;
    width: 100%;
    margin-left: 4rem;
    span {
      display: block;
    }
    .available {
      font-size: 3vw;
    }

    .info_name {
      font-size: 1.5rem;
      font-weight: 500;
      display: inline-block;
      width: 100%;
      color: $white;
      @include dark_shadow;
      padding: 1rem 0;
      margin:1rem 0;
    }

    .green {
      background-color: $green;
    }

    .peach {
      background: rgb(223, 0, 74);
    }

    .orange {
      background: $orange;
    }

    .sub_info {
      font-size: 1.3rem;
      font-weight: normal;
      color: darken($table_grey, 30%);
    }
  }
}
