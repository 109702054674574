.main_applicant {
  height: 100vh;
  width: 100%;
  background: #fdfdfd;

  .applicant_header {
    width: 100%;
    height: 18%;
    background: #9dc2fa;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .nav {
      width: 100%;
      box-shadow: none;
      display: block;
      text-align: right;
      padding: 1rem 2rem;
      position: relative;

      .existing {
        outline: none;
        border: none;
        padding: 1rem 1.2rem;
        font-size: 1.4rem;
        border-radius: 5px;
        background: rgb(0, 75, 136);
        color: rgb(231, 231, 231);
      }

      .signin_form {
        position: absolute;
        background: #fefefe;
        box-shadow: 0 0 10px rgb(168, 202, 241);
        width: 40rem;
        height: 30rem;
        padding: 2rem;
        right: 2rem;
        top: 6rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        border-radius: 8px;
        z-index: 350000;

        .input_container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;

          label {
            font-size: 1.3rem;
            font-weight: 600;

            sup {
              color: red;
              font-weight: bold;
              font-size: 1rem;
            }
          }

          input,
          select {
            outline: none;
            background: none;
            width: 100%;
            padding: 1rem;
            font-size: 1.5rem;
            border-radius: 8px;
            border: 1px solid rgb(142, 156, 168);
          }
        }

        .alerty {
          position: relative;
          width: 100%;
          margin-bottom: 1rem;

          .alert {
            position: absolute;
            width: 100%;
            font-size: 1.4rem;
            top: -1.5rem;
          }
        }

        .submit {
          border: none;
          outline: none;
          background: rgb(76, 107, 148);
          font-size: 1.5rem;
          padding: 1rem 1.5rem;
          color: #fefefe;
          border-radius: 8px;
        }
      }
    }

    .search_input-container {
      width: 50rem;
      background: #f3f2fd;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      border-radius: 5px;
      align-items: center;
      border: 1px solid rgb(240, 240, 240);
      margin-bottom: 2rem;
      i {
        color: rgb(255, 16, 56);
      }
      input {
        width: 95%;
        font-size: 1.5rem;
        background: none;
        outline: none;
        border: none;
      }
    }
  }

  .vacancies {
    height: 82%;
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title_filter {
      height: 5%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .section_title {
        font-size: 2.3rem;
        font-weight: 600;
      }

      .filter_section {
        width: 71%;
        display: flex;
        align-items: center;
        .filter_btn {
          border: none;
          outline: none;
          background: none;
          font-size: 1.3rem;
          padding: 1rem 1.5rem;
          border-radius: 100px;
          background: rgb(0, 37, 185);
          color: rgb(245, 245, 245);
        }

        .filter_criteria-list {
          margin-left: 1rem;
          .criteria_list {
            display: flex;
            position: relative;
            .criteria_btn {
              border: none;
              outline: none;
              background: none;
              font-size: 1.5rem;
              padding: 0.5rem 0.7rem;
              border-radius: 100px;
              margin-right: 1rem;
              font-weight: 600;
              color:rgb(0, 37, 185);

              &:hover {
                background: #f3f3f3;
                color: rgb(0, 75, 136);
              }

              // &:hover ~ .criteria_card {
              //   display: block;
              // }
            }

            .active_criteria {
              background: rgb(0, 75, 136);
              color: rgb(245, 245, 245);
            }

            .criteria_card {
              position: absolute;
              width: 55rem;
              height: 70vh;
              overflow-y: scroll;
              background: white;
              left: 0;
              top: 4rem;
              padding: 1rem;
              border-radius: 8px;
              box-shadow: 0 0 15px 10px rgb(245, 245, 245);
              // display: none;
              transition: all 0.7s ease-in-out;
              .nested {
                display: flex;
                align-items: flex-start;
                width: 100%;

                .criteria_faculty {
                  border: none;
                  outline: none;
                  background: none;
                  color: rgb(26, 26, 26);
                  padding: 1rem 1.3rem;
                  font-size: 1.3rem;
                  border-radius: 5px;
                  width:50%;
                  font-weight: 600;
                  text-align: left;

                  &:hover {
                    background: #c5c4d1;
                  }

                  // &:hover ~ .dept_list {
                  //   display: flex !important;
                  // }
                }

                .dept_list {
                  margin-left: 2rem;
                  display: flex;
                  flex-direction: column;
                  // border-left: 1px solid #c2c2c2;
                  // display: none;
                  .criteria_dept {
                    border: none;
                    outline: none;
                    background: none;
                    color: rgb(26, 26, 26);
                    padding: 1rem 1.3rem;
                    font-size: 1.3rem;
                    border-radius: 5px;
                    text-align: left;
                    font-weight: 500;

                    &:hover {
                      background: #f3f2fd;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .job_listing {
      // height: 90%;
      height: 70vh;
      padding: 4rem 0;
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: repeat(4, 1fr);
      overflow-y: auto;
      .job_card {
        background: white;
        box-shadow: 0 0 15px 10px #ebebeb;
        padding: 1rem 2rem;
        min-width: 100%;
        max-width: 100%;
        height: 25rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border-radius: 8px;
        .job_title {
          display: flex;
          align-items: center;
          .initial {
            background: rgb(58, 58, 168);
            color: white;
            min-width: 4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
          }

          .title_dept {
            font-size: 1.5rem;
            font-weight: 700;
            margin-left: 1rem;

            span {
              font-weight: normal;
            }
          }
        }
        .brief {
          width: 100%;
          height: 55%;
          font-size: 1.4rem;
          overflow: hidden;
          line-height: 1.2;
        }

        .cta_btns {
          .btn {
            font-size: 1.3rem;
          }
          .apply-btn {
            background: rgb(58, 58, 168);
          }
        }
      }

      .view_job-popup {
        position: absolute;
        left: 0;
        top: 0;
        height: 100vh;
        width: 100%;
        background: rgba(0, 0, 0, 0.856);
        display: flex;
        justify-content: center;
        align-items: center;

        .preview_card {
          height: 80%;
          width: 75%;
          background: white;
          border-radius: 8px;

          .title_seg {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            padding: 2rem;

            .job_title {
              display: flex;
              align-items: center;
              .initial {
                background: rgb(58, 58, 168);
                color: white;
                min-width: 4rem;
                height: 4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100px;
              }

              .title_dept {
                font-size: 1.5rem;
                font-weight: 700;
                margin-left: 1rem;

                span {
                  font-weight: normal;
                }
              }
            }
          }

          .segment {
            padding: 0 2rem;
            margin: 2rem 0;

            .segment_title {
              font-size: 1.7rem;
              font-weight: 600;

              span {
                font-size: 1.4rem;
                font-weight: normal;
                color: black;
              }
            }

            .about_segment {
              font-size: 1.4rem;
            }

            .instruction,
            .due_date {
              font-size: 1.4rem;
            }
          }
        }
      }
    }

    .initial_view {
      height: 70vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .content-container {
        align-items: center;
        height: 50%;
        width: 80%;

        .display-img {
          width: 40rem;
          height: 30rem;
          object-fit: contain;
          margin-right: 2rem;
        }
        .words {
          .welcome {
            font-size: 3rem;
            margin-bottom: 2rem;
            font-weight: 600;
          }

          .instruct-applicants {
            font-size: 1.5rem;
          }

          .warning {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

.logo {
  position: absolute;
  z-index: 30000;
  width: 5rem;
  height: 5rem;
  object-fit: contain;
  top: 2rem;
  left: 2rem;
}

.powered {
  position: absolute;
  bottom: 1rem;
  right: 10rem;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 0;

  .link {
    text-decoration: none;
    color:green;

    img{
      width:7.5rem;
    }
  }
}

.apply {
  height: 100vh;
  width: 100%;
  background: #fdfdfd;
  padding: 2rem 12rem;

  .left_side {
    width: 30%;

    .selection {
      margin-bottom: 5rem;
      .position {
        font-size: 2rem;
        font-weight: 600;
      }

      .sub,
      .faculty,
      .dept {
        font-size: 1.5rem;
      }
    }

    .nav {
      width: fit-content;
      width: -moz-fit-content;
      background: rgb(251, 253, 255);
      padding: 1rem;
      box-shadow: 0 0 10px 10px #eee;
      border-radius: 8px;

      .nav-link {
        background: none;
        border: none;
        outline: none;
        font-size: 1.5rem;
        color: rgb(163, 163, 163);
      }

      .active {
        color: rgb(0, 32, 70);
        font-weight: 600;
      }

      .completed {
        color: rgb(3, 158, 112);
      }

      .signin_form {
        position: absolute;
        background: #fefefe;
        box-shadow: 0 0 10px rgb(168, 202, 241);
        width: 40rem;
        height: 30rem;
        padding: 2rem;
        right: 2rem;
        top: 6rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        border-radius: 8px;

        .input_container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;

          label {
            font-size: 1.3rem;
            font-weight: 600;

            sup {
              color: red;
              font-weight: bold;
              font-size: 1rem;
            }
          }

          input,
          select {
            outline: none;
            background: none;
            width: 100%;
            padding: 1rem;
            font-size: 1.5rem;
            border-radius: 8px;
            border: 1px solid rgb(142, 156, 168);
          }
        }
      }
    }

    .warning {
      width: 80%;
      color: red;
      font-size: 1.4rem;
      margin-top: 6rem;
      span {
        font-weight: bold;
        display: block;
      }
    }

    .enq {
      margin-top: 3rem;
      span {
        color: black;
      }
      font-size: 1.4rem;
      color: blue;
      font-weight: bold;
    }
  }

  .tab-content {
    width: 100%;
    height: 100%;

    .back {
      position: absolute;
      right: 4rem;
      background: rgba(58, 24, 24, 0.933);
      font-size: 1.3rem;
      border-radius: 20px;
      color: #eee;
      z-index: 20000;
    }

    .form-left {
      display: flex;
      height: 96vh;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      .step_title-counter {
        .step_counter,
        .instruction {
          font-size: 1.4rem;
        }

        .step {
          font-size: 2.5rem;
          color: navy;
        }
      }

      .form_container {
        height: 70vh;
        overflow-y: auto;

        .passport {
          width: 22rem;
          height: 25rem;
          position: absolute;
          right: -7rem;
          background: none;
          border: 1px rgb(250, 185, 129) dashed;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 1.4rem;
          border-radius: 8px;
          outline: none;
          background-color: rgb(235, 235, 235);
          font-weight: bold;

          i {
            color: rgb(245, 155, 76);
          }

          &:hover {
            background: #fefefe;
          }

          &::after {
            content: 'passport upload is mandatory, and size should not exceed 20kb';
            display: block;
            color: red;
            font-weight: normal;
            width: 80%;
            margin-top: 2rem;
          }
        }
        .input_container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 50rem;
          margin-top: .5rem;

          label {
            font-size: 1.4rem;
            font-weight: 600;

            sup {
              color: red;
              font-weight: bold;
              font-size: 1rem;
            }
          }

          input,
          select {
            outline: none;
            background: none;
            width: 100%;
            padding: 1rem;
            font-size: 1.5rem;
            border-radius: 8px;
            border: 1px solid rgb(142, 156, 168);
          }
        }

        .first_input {
          display: flex;
          justify-content: space-between;
          width: 50rem;

          .input_container {
            &:first-of-type {
              width: 30%;
            }

            &:last-of-type {
              width: 68%;
            }
          }
        }

        .dob {
          width: fit-content;
          width: -moz-fit-content;
        }
        .location_select {
          display: flex;
          justify-content: space-between;
          width: 50rem;
          .input_container {
            width: 49%;
          }
        }

        .gender {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 1.4rem;
          // margin-top: 1rem;
          .type {
            background: #e7edfc;
            margin-left: 1rem;
            font-size: 1.3rem;
            outline: none;
          }

          .active {
            background: rgb(61, 61, 136);
            color: #fefefe;
          }
        }
      }

      .button_uploads {
        display: flex;
        margin-top: 2rem;
        .btn {
          display: flex;
          flex-direction: column;
          height: 13rem;
          width: 13rem;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          font-size: 1.4rem;
        }

        .edu {
          border: 1px dashed rgb(209, 114, 31);
          margin-right: 3rem;
          i {
            color: rgb(209, 114, 31);
          }
        }

        .cv {
          border: 1px dashed rgb(64, 31, 209);
          i {
            color: rgb(64, 31, 209);
          }
        }
      }


      .alert-danger{
        width: fit-content;
        width: -moz-fit-content;
        font-size: 1.35rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 0 0 0 1rem!important;

        .close{
          font-size: 2.3rem;
        }
      }


      .edu_qualification-modal {
        background: rgba(0, 0, 0, 0.781);
        position: fixed;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0;
        z-index: 2000;
        display: flex;
        justify-content: center;
        align-items: center;

        .modal_card {
          background: rgb(253, 253, 253);
          width: 40%;
          height: 85%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          position: relative;

          .close_modal {
            position: absolute;
            right: 1rem;
            top: 1rem;

            i {
              color: red;
            }
          }

          .input_container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 80%;
            margin-bottom: 2rem;

            label {
              font-size: 1.4rem;
              font-weight: 600;

              sup {
                color: red;
                font-weight: bold;
                font-size: 1rem;
              }
            }

            input,
            select {
              outline: none;
              background: none;
              width: 100%;
              padding: 1rem;
              font-size: 1.5rem;
              border-radius: 8px;
              border: 1px solid rgb(142, 156, 168);
            }
          }

          .start_graduation {
            display: flex;
            justify-content: space-between;
            width: 80%;
            .input_container {
              width: 49%;
            }
          }

          .submit {
            background: rgb(76, 107, 148);
            font-size: 1.5rem;
            padding: 1rem 1.5rem;
            color: #fefefe;
          }
        }
      }
    }

    .qualification_list {
      .sec_title {
        font-size: 2rem;
        padding-bottom: 5px;
        border-bottom: 1px solid grey;
      }

      .card_list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;

        .qualifiation_card {
          background: rgb(247, 247, 247);
          padding: 1rem;
          min-width: 33%;
          max-width: 33%;
          height: 8rem;
          margin: 0 0 0.5rem 0;
          border-radius: 8px;
          position: relative;

          .close {
            background: none;
            outline: none;
            border: none;
            color: red;
            position: absolute;
            top: 1rem;
            right: 1rem;
          }
          .cert_type {
            font-size: 1.8rem;
            font-weight: 600;
          }

          .school,
          .duration {
            font-size: 1.5rem;
          }
        }
      }

      .cv {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        color: rgb(0, 102, 255);
        background: rgb(238, 238, 238);
        padding: 1rem;
        width: fit-content;
        width: -moz-fit-content;
        border-radius: 50px;
        display: inline-block;
        margin-right: 1rem;
        .close {
          background: none;
          outline: none;
          border: none;
          color: red;
          margin-left: 1rem;
        }
      }
    }

    .first_info-preview {
      width: 100%;
      display: grid;
      grid-template-columns: 0.3fr 1fr;
      grid-column-gap: 3rem;

      .passport {
        width: 23rem;
        height: 25rem;
        object-fit: cover;
        border-radius: 8px;
      }

      .personal_data {
        .info-holder {
          font-size: 1.4rem;
          font-weight: 600;

          .data {
            font-weight: normal;
            margin-bottom: 0.5rem;
          }
        }

        .contain {
          display: flex;
          justify-content: space-evenly;

          .info-holder {
            width: 33%;
            text-align: left;
          }

          &:last-of-type {
            .info-holder {
              width: 50%;
              text-align: left;
            }
          }
        }
      }
    }

    .accordion-item {
      .accordion-button {
        background: rgb(29, 36, 61);
        color: #fefefe;
        outline: none;
        font-size: 1.5rem;
      }
      .card_list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;

        .qualifiation_card {
          background: rgb(247, 247, 247);
          padding: 1rem;
          min-width: 33%;
          max-width: 33%;
          height: 7rem;
          margin: 0 0 0.5rem 0;
          border-radius: 8px;
          position: relative;

          .close {
            background: none;
            outline: none;
            border: none;
            color: red;
            position: absolute;
            top: 1rem;
            right: 1rem;
          }
          .cert_type {
            font-size: 1.5rem;
            font-weight: 700;
          }

          .school,
          .duration {
            font-size: 1.4rem;
          }
        }
      }

      .cv {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        color: rgb(0, 102, 255);
        background: rgb(238, 238, 238);
        padding: 1rem;
        width: fit-content;
        width: -moz-fit-content;
        border-radius: 50px;
        display: inline-block;
        margin-right: 1rem;
        .close {
          background: none;
          outline: none;
          border: none;
          color: red;
          margin-left: 1rem;
        }
      }
    }
  }
  .button-holder {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    .prev {
      font-size: 1.5rem;
      padding: 1rem 1.5rem;
    }
    .submit_btn {
      background: rgb(76, 107, 148);
      font-size: 1.5rem;
      padding: 1rem 1.5rem;
      color: #fefefe;
    }
  }

  //  <!-- copy this block -->
}
.feedback_modal {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.774);
  height: 100vh;
  width: 100%;
  z-index: 10000000;
  display: flex;
  justify-content: center;
  align-items: center;

  .feedback_card {
    width: 40%;
    height: fit-content;
    padding: 2rem;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    i {
      font-size: 10rem;
      color: teal;
    }

    .message {
      text-align: center;
      margin: 3rem 0;
      font-size: 1.5rem;
      width: 80%;

      .success,.warning {
        font-size: 3rem;
        font-weight: bold;
        margin-bottom: 3rem;
      }
    }

    .submit_btn {
      background: rgb(76, 107, 148);
      font-size: 1.5rem;
      padding: 1rem 1.5rem;
      color: #fefefe;
    }
  }
}

// RETURN VIEW STARTS HERE=================================
.return_view,.search_view {
  height: 100vh;
  width: 100%;
  background: #fdfdfd;
  padding: 2rem 12rem;
  .first_info-preview {
    width: 100%;
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    grid-column-gap: 3rem;
    position: relative;

    .passport {
      width: 23rem;
      height: 25rem;
      object-fit: cover;
      border-radius: 8px;
    }

    .edit {
      background: none;
      outline: none;
      border: none;
      padding: 1rem 1.5rem;
      background: rgb(0, 90, 71);
      font-size: 1.4rem;
      color: #fefafe;
      border-radius: 8px;
      margin-top: 2rem;
    }

    .personal_data {
      .info-holder {
        font-size: 1.4rem;
        font-weight: 600;

        .data {
          font-weight: normal;
          margin-bottom: 2rem;
        }
      }

      .contain {
        display: flex;
        justify-content: space-evenly;

        .info-holder {
          width: 33%;
          text-align: left;
        }

        &:last-of-type {
          .info-holder {
            width: 50%;
            text-align: left;
          }
        }
      }
    }

    .position_contents {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;

      .status {
        font-size: 1.4rem;
        font-weight: bold;
        padding: 1rem 1.5rem;
        border-radius: 50px;
        margin: 0;
      }

      .pending {
        background: #ffd000;
        color: rgb(66, 66, 66);
      }

      .success {
        background: rgb(4, 146, 63);
        color: white;
      }
      .signout {
        background: none;
        outline: none;
        border: none;
        font-size: 1.4rem;
        margin-left: 2rem;
        font-weight: 600;
      }
    }
  }

  .accordion-item {
    .accordion-button {
      background: rgb(29, 36, 61);
      color: #fefefe;
      outline: none;
      font-size: 1.5rem;
    }
    .card_list {
      display: flex;
      // justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;

      .qualifiation_card {
        background: rgb(247, 247, 247);
        padding: 1rem;
        height: 7rem;
        margin: 0 1rem 0.5rem 0;
        border-radius: 8px;
        position: relative;

        .close {
          background: none;
          outline: none;
          border: none;
          color: red;
          position: absolute;
          top: 1rem;
          right: 1rem;
        }
        .cert_type {
          font-size: 1.5rem;
          font-weight: 700;
        }

        .school,
        .duration {
          font-size: 1.4rem;
        }
      }
    }

    .cv {
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      color: rgb(0, 102, 255);
      background: rgb(238, 238, 238);
      padding: 1rem;
      width: fit-content;
      width: -moz-fit-content;
      border-radius: 50px;
      display: inline-block;
      margin-right: 1rem;
      .close {
        background: none;
        outline: none;
        border: none;
        color: red;
        margin-left: 1rem;
      }
    }
  }

}
.print{
  border:none;
  outline:none;
  background:rgb(46, 81, 139);
  color:white;
  padding:1rem 1.4rem;
  font-size: 1.4rem;
  border-radius: 8px;
  margin-top: 1rem;
  position: absolute;
  bottom:3rem;
  left:8rem;
}

.admin_dashboard {
  height: 100%;
  width: 100%;
  padding:0 12rem 3rem 12rem;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;


  .top_area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .hold_stats {
      display: flex;
    }

    .stat_card {
      display: flex;
      align-items: center;
      font-size: 2.8rem;
      margin-right: 5rem;
      .stat_title {
        font-size: 1.4rem;
        height: 8rem;
        width: 9rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border-radius: 17px;
        margin-right: 1rem;
        color: white;
      }

      .job_card {
        background: #806bf8;
      }

      .app_card {
        background: #9599d6;
      }
    }

    .create_job {
      background: teal;
      border: none;
      outline: none;
      padding: 1rem 1.5rem;
      font-size: 1.4rem;
      border-radius: 8px;
      color: white;

      i {
        margin-right: 1rem;
      }
    }


      .search_container {
        width: 40rem;
        // background: #f3f2fd;
        padding: 0.7rem 1rem;
        display: flex;
        justify-content: space-between;
        border-radius: 5px;
        align-items: center;
        border: 1px solid rgb(111, 83, 129);
        margin-left: 2rem;
        i {
          color: rgb(255, 16, 56);
          font-size: 1.6rem;
        }
        input {
          width: 95%;
          font-size: 1.5rem;
          background: none;
          outline: none;
          border: none;
        }

              .sign_out {
                background: orange;
                border: none;
                outline: none;
                padding: 0.5rem 1rem;
                font-size: 1.3rem;
                color: rgb(43, 43, 43);
                border-radius: 8px;
                height:100%;

                i {
                  color: #fefefe;
                }
              }
      }



  }

  .other_part {
    height: 70vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    .job_ad {
      width: 28%;
      height: 100%;
      background: white;
      box-shadow: 0 0 10px #eee;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-end;

      .card_title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border-bottom: 1px solid rgb(216, 216, 216);

        .the_title {
          font-size: 1.6rem;
          font-weight: normal;
        }
        .category {
          outline: none;
          background: none;
          width: 50%;
          padding: 0.5rem;
          font-size: 1.5rem;
          border-radius: 8px;
          border: 1px solid rgb(208, 218, 228);
          font-size: 1.4rem;
        }
      }

      .job_ad-table {
        height: 83%;
        width: 100%;
        overflow-y: hidden;

        .the_row {
          display: flex;
          justify-content: space-between;
          padding: 1rem;
          border-bottom: 1px solid rgb(216, 216, 216);

          &:first-of-type {
            font-weight: 600;
            color: rgb(58, 58, 58);
          }

          .role {
            width: 55%;
            font-size: 1.3rem;
          }

          .data {
            width: 20%;
            text-align: left;
            font-size: 1.3rem;
          }
        }
      }

      .see_all {
        background: none;
        outline: none;
        border: none;
        font-size: 1.3rem;
        font-weight: 600;
        margin-right: 2rem;
        color: blue;
      }
    }

    .candidates_list {
      padding: 0 2rem;
      width: 69%;
      height: 100%;

      .the_title {
        margin-bottom: 1rem;
        font-weight: 600;
      }

      .list {
        height: 90%;
        width: 100%;
        display: flex;
        justify-content: space-between;

        flex-wrap: wrap;
        .candidate_card {
          background: #fff;
          padding: 2rem;
          box-shadow: 0 0 10px #eee;
          width: 29rem;
          height: 25rem;
          border-radius: 10px;

          img {
            width: 7rem;
            height: 7rem;
            border-radius: 20px;
            object-fit: cover;
          }

          .name {
            font-size: 1.8rem;
            margin-top: 1rem;
          }

          .applied {
            font-size: 1.4rem;
          }

          .view_candidate {
            background: rgb(76, 107, 148);
            border: none;
            outline: none;
            font-size: 1.4rem;
            padding: 1rem;
            color: white;
          }

          .cv_view {
            background: none;
            border: none;
            outline: none;
            font-size: 1.4rem;
            padding: 1rem;
          }
        }
      }

      .see_all {
        background: rgb(7, 88, 88);
        border: none;
        outline: none;
        font-size: 1.4rem;
        padding: 1rem;
        color: white;
      }
    }

    .view_category-container {
      padding: 0 1rem;
      width: 69%;
      height: 100%;
      .dept_list {
        height: 93%;
        width: 100%;

        .dept {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          background: white;
          box-shadow: 0 0 10px #eee;
          height: 5rem;
          margin-bottom: 1rem;

          .dept_name {
            font-size: 1.6rem;
            font-weight: 600;
            margin-bottom: 0;
            padding: 0 2rem;
          }

          .number_of-candidates {
            margin-bottom: 0;
            font-size: 1.5rem;
            span {
              background: lightskyblue;
              padding: 0.5rem 1rem;
              font-size: 1.3rem;
              border-radius: 30px;
              margin-right: 1rem;
            }
          }

          .download {
            height: 100%;
            width: 15rem;
            background: rgb(0, 85, 85);
            outline: none;
            border: none;
            color: rgb(255, 255, 255);
            font-size: 1.3rem;
            image-rendering: {
              color: white;
            }
          }
        }
      }
      .see_all {
        background: rgb(7, 88, 88);
        border: none;
        outline: none;
        font-size: 1.4rem;
        padding: 1rem;
        color: white;
      }
    }
  }
}
.questionaire_pop {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.85);
  height: 100vh;
  width: 100%;
  z-index: 20000000;
  display: flex;
  justify-content: center;
  align-items: center;

  .timer{
    position: absolute;
    left: 8rem;
    top: 3rem;
    color: white;
    font-size: 4rem;

  }

  .questionaire_card {
    width: 50%;
    // height: 80%;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 2rem;

    .instruction {
      font-size: 1.4rem;
      width: 80%;
      span {
        font-weight: 600;
      }
    }

    .teaching_form-container,
    .nonteaching_form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .input_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 50rem;
        margin-top: 2rem;

        label {
          font-size: 1.4rem;
          font-weight: 600;

          sup {
            color: red;
            font-weight: bold;
            font-size: 1rem;
          }
        }

        input,
        select,textarea {
          outline: none;
          background: none;
          width: 100%;
          padding: 1rem;
          font-size: 1.5rem;
          border-radius: 8px;
          border: 1px solid rgb(142, 156, 168);
        }
      }
    }

    .submit_btn {
      background: rgb(76, 107, 148);
      font-size: 1.5rem;
      padding: 1rem 1.5rem;
      color: #fefefe;
      margin-top: 3rem;
    }
  }
}
