.main {
  .leave_management,
  .employee_management {
    .info {
      width: 100%;
      display: flex;
      // align-items:center;
      justify-content: space-between;
      padding: 0 2rem;

      .hold_detail {
        width: 30%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1rem;
      }
      .leave_detail {
        text-align: center;
        width: 100%;
        margin-left: 4rem;
        span {
          display: block;
        }
        .available {
          font-size: 3vw;
        }

        .info_name {
          font-size: 1.5rem;
          font-weight: 500;
          display: inline-block;
          width: 100%;
          color: $white;
          @include dark_shadow;
          padding: 1rem 0;
        }

        .green {
          background-color: $green;
        }

        .peach {
          background: rgb(223, 0, 74);
        }

        .orange {
          background: $orange;
        }

        .sub_info {
          font-size: 1.3rem;
          font-weight: normal;
          color: darken($table_grey, 30%);
        }
      }
    }
  }

  .employee_management {
    .staff_profile-card {
      padding: 0 2rem;
      margin: 3rem 0;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 1rem;
      width: 99%;
      .profile-card {
        background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.459), rgb(0, 0, 0)), url(/images/user_img/adult-contemporary-designer-suit-1405963.jpg);
        background-size: cover;
        background-position: top;
        @include shadow;
        height: 34vh;
        width: 100%;
        border-radius: 7px;
        position: relative;

        .the_options {
          display: flex;
          justify-content: flex-end;
          padding: 1rem;
          .ti-more-alt {
            font-size: 1vw;
            color: white;
            cursor: pointer;

            &:hover {
              color: orange;
            }
          }
        }

        .detail {
          position: absolute;
          bottom: 0;
          width: 100%;
          background: rgba(0, 0, 0, 0.685);
          padding-top: 1rem;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          .name {
            font-size: 1.2vw;
            color: orange;
            text-align: center;
          }

          .position {
            font-size: 1.3rem;
            color: lightgrey;
            text-align: center;
          }

          .buttons {
            display: flex;
            justify-content: space-around;
            margin: 1rem 0;

            .icon_btn {
              background: none;
              outline: none;
              border: none;
              cursor: pointer;

              i {
                color: lightgrey;
                font-size: 1vw;
                font-weight: bold;

                &:hover {
                  color: orange;
                }
              }
            }
          }
        }
      }
    }
  }
}

.employee_profile {
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  padding: 1rem;
  .primary_information-card {
    // background: $employee_profile-color;
    height: 90vh;
    width: 95%;
    // @include dark_shadow;
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    .top_curve,
    .box,
    .short_curve {
      position: absolute;
    }

    .top_curve {
      top: 0;
      width: 100%;
      z-index: 0;
    }

    .short_curve {
      bottom: 0;
      width: 40%;
      z-index: 0;
    }

    .box {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      z-index: 0;
    }

    .image {
      width: 100%;
      height: 30vh;
      background-size: cover;
      background-position: center;
      border-radius: 10px;
      background-image: url(/images/user_img/etty.jpg);
      // position: absolute;
      // top: 21%;
      // left: 50%;
      // transform: translate(-50%, -50%);
    }

    .candidate_details {
      position: absolute;
      top: 68%;
      left: 65%;
      transform: translate(-50%, -50%);
      width: 100%;

      .address,
      .date_of_birth,
      .category {
        margin-top: 2rem;
        .tag {
          display: block;
          color: $white;
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }

        .answer {
          display: block;
          color: rgb(207, 207, 207);
          font-size: 1.4rem;
          font-weight: normal;
        }
      }
    }
  }
}

.staff_profile {
  display: block;
  // padding: 1rem;

  .primary_information-card {
    // background: $employee_profile-color;
    height: 90vh;
    width: 95%;
    // @include dark_shadow;
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    .top_curve,
    .box,
    .short_curve {
      position: absolute;
    }

    .top_curve {
      top: 0;
      width: 100%;
      z-index: 0;
    }

    .short_curve {
      bottom: 0;
      width: 40%;
      z-index: 0;
    }

    .box {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      z-index: 0;
    }

    .candidate_details {
      position: absolute;
      top: 68%;
      left: 65%;
      transform: translate(-50%, -50%);
      width: 100%;

      .address,
      .date_of_birth,
      .category {
        margin-top: 2rem;
        .tag {
          display: block;
          color: $white;
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }

        .answer {
          display: block;
          color: rgb(207, 207, 207);
          font-size: 1.4rem;
          font-weight: normal;
        }
      }
    }
  }
}

// PERFORMANCE======================================================PERFORMANCE==========PERFORMANCE

.performance {
  margin: 3rem 0;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 3rem;
  width: 98%;

  .project_card {
    @include shadow;
    // height: 34vh;
    width: 90%;
    background: #fff;
    border-radius: 7px;
    padding: 1rem;
    cursor: pointer;
    // border-top: 5px solid $button_green-1;

    &:hover {
      background: $button_green-1;
      color: white;
    }

    &:hover .badge {
      color: black;
    }

    .first {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      // margin: 2rem 0;
      .header {
        font-size: 1.5vw;
        font-weight: normal;
        width: 90%;
        margin-bottom: 2rem;
      }

      .ti-more-alt {
        font-size: 1vw;
        cursor: pointer;
      }

      // .verify {
      //   left: 5%;
      // }

      .ti-comment-alt {
        position: absolute;
        left: 25%;
        top: 8%;
        font-size: 1.5rem;
        font-weight: 500;
        background: $white;
        @include shadow;
        padding: 0.5rem;
        border-radius: 50px;
      }
    }

    .progress {
      margin: 2rem 0;

      img {
        width: 100%;
      }
    }

    .more_info {
      margin-bottom: 1.5rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .the_info {
        font-size: 0.9vw;
        font-weight: bold;

        .activity {
          font-weight: normal;
        }
      }
    }

    .the__members {
      font-size: 0.8vw;
      background: #eee;
      padding: 0.5rem 1rem;
      border-radius: 50px;
    }

    .badge {
      padding-right: 1rem;
      padding-bottom: 1rem;
      text-align: right;
    }
  }
}
