.grid_container {
  display: grid;
  grid-template-columns: 0.3fr 1fr;

  .form_holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #eee;
    height: 92vh;
    padding: 1rem;
    box-sizing: border-box;
    .title {
      font-size: 1.2vw;
      margin-bottom: 2rem;
      margin-top: 2rem;
      margin-left: 0;
      padding-left: 0;
      text-align: left;
    }

    .hold_input {
      height: 85%;

      .info_input {
        // margin-bottom: 1rem;
        label {
          display: block;
          font-size: 0.9vw;
        }

        input,
        select {
          @include registeration_form(94%);
          height: -moz-fit-content;
          height: fit-content;
        }
      }

      .checker {
        margin-bottom: 2rem;
        padding: 0 3rem;
        text-align: right;
        font-size: 0.9vw;
      }

      .btn_hold {
        text-align: center;

        .submit {
          @include submit_button;
        }
      }
    }
  }

  .content_right {
    padding: 1rem 2rem;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 30%;

      .title {
        padding: 0;
        margin: 0;
      }
      .secondary-btn {
        @include primary_button;
        margin-bottom: 1rem;
      }
    }

    .objective_table {
      .the_row {
        display: grid;
        grid-template-columns: 0.2fr 1fr 1fr 1fr 0.8fr 1fr 2fr 0.1fr;
        padding: 1rem;

        &:first-child {
          .item {
            font-weight: 600;
            text-transform: capitalize;
          }
        }

        &:nth-child(odd) {
          background: #f1f1f1;
          box-shadow: 0 2px 1px rgb(216, 216, 216);
        }

        .item {
          font-size: 0.9vw;

          .ti-trash {
            font-size: 1.2vw;
            font-weight: bold;
            cursor: pointer;
            color: red;

            &:hover {
              color: orangered;
            }
          }
        }
      }
    }
  }
}


.submit{
  margin-top: 2rem;
  .submit_button{
    @include submit_button;
    padding:1rem;
    font-size: .8vw;
  }
}

.add_item-btn {
  @include primary_button;
  font-size: 0.8vw;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  margin: 0;
  padding: 0.5rem 1rem;
}

.grey_out {
  position: absolute;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.801);
  left: 0;
  top: 0;
  z-index: 15000;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;

  .add_item-sec {
    background: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 60%;
    border-radius: 10px;
    overflow: hidden;

    .top_sec {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      background: #eee;

      .add_more {
        @include primary_button;
        font-size: 0.9vw;
        margin-left: 2rem;
        height: -moz-fit-content;
        height: fit-content;
        cursor: pointer;
      }
    }

    .item_table {
      .item_row {
        display: grid;
        grid-template-columns: 1fr 0.3fr 0.5fr 0.5fr 1fr 0.1fr;
        padding: 1rem;

        .item {
          font-size: 0.9vw;

          .ti-close {
            color: red;
            cursor: pointer;
            font-weight: bold;
          }
        }

        &:nth-child(odd) {
          background: #eee;
        }
      }
    }
  }
}

.hide_element {
  display: none !important;
}

.hide_grid {
  display: block;
}

.qms_performance {
  padding: 0 2rem;

  .the_top-sec {
    display: flex;
    width: 100%;

    .top_title {
      font-size: 1.3vw;
      font-weight: 500;
      margin-right: 20rem;
    }

    .info_input {
      width: 30%;
      label {
        display: block;
        font-size: 0.9vw;
      }

      input,
      select {
        @include registeration_form(100%);
        height: -moz-fit-content;
        height: fit-content;
      }
    }
  }

  .hold_activity {
    display: flex;
    justify-content: center;
    height: 65vh;

    .activity_container {
      width: 80%;
      display: grid;
      grid-template-columns: 0.5fr 1fr;
      background: white;
      border-radius: 10px;
      box-shadow: 0 0px 6px $shadow;
      height:100%;
      box-sizing: border-box;

      .activity_list {
        padding: 3rem;
        background: #eee;
        margin-bottom: 0;
        height:100%;
        overflow-y: scroll;
        box-sizing: border-box;
        .activity {
          list-style: none;
          padding:1rem 0;
          font-size: 0.9vw;
          display: flex;
          justify-content: space-between;
          
          &:not(:last-child){
            border-bottom: 1px solid rgb(124, 124, 124);
          }

          &_btn {
            background: none;
            cursor: pointer;
            outline: none;
            border: none;
            margin-left: 2rem;

            &:hover,
            &:active,
            &:focus {
              @include primary_button;
              padding: 1rem;
            }
          }

          .active_btn{
            @include primary_button;
              padding: 1rem;
          }
        }
      }

      .corresponding_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
        .month {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 0.9vw;
          margin-bottom: 1.5rem;
          width: 80%;

          .score {
            width: 86%;
            display: inherit;
            align-items: center;
            &::before {
              content: '';
              height: 0.2rem;
              width: 50%;
              display: inline-block;
              background: rgb(228, 228, 228);
              margin-right: 2rem;
            }


            .ti-pencil{
              font-size: 1.1vw;
              font-weight: normal;
              color:green;
              cursor:pointer;

              &:hover{
                transform: scale(1.3);
              }
            }

            
          }

          input{
            @include input;
            width:10rem;
          }
        }

        .total {
          font-size: 1vw;
          margin-top: 0;
        }
      }
    }
  }

  .corresponding_content{
    height: 65vh;
  }

  .submit{
    margin-top: 2rem;
    .submit_button{
      @include submit_button;
      padding:1rem;
      font-size: .8vw;
    }
  }
}

.export_container{
  padding-left:10rem;

  .pdf,.excel{
    @include primary_button;
    padding:1rem;
    margin-left: 1rem;

  }
}
