*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  font-family: 'Hind Siliguri', sans-serif;
  background: $body_grey;
  width: 100vw;

  :link:visited {
  }
}

.reference_body {
  position: relative;
  overflow-x: hidden;
  height: 100%;
}

html {
  font-size: 62.5% !important;

}
