.side_nav {
  padding: 2rem;
  width: 100%;

  ul {
    li {
      list-style: none;
      margin: 1rem 0;
      font-size: 1.4rem;
      cursor: pointer;
      color: grey;

      &:hover,
      &:focus,
      &:active {
        color: $green;
      }

      span {
        margin-right: 2.5rem;
        img {
          width: 2.3rem;
        }
      }
    }
  }
}

.hold_center {
  width: 100vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .the_menu {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 2rem;
    padding: 1rem 2rem;
    width: 70%;
    height:80vh;
    overflow-y: scroll;

    .menu_item {
      box-sizing: border-box;
      background: white;
      box-shadow: 0 0px 6px $shadow;
      padding: 1rem;
      width: 100%;
      height: 23vh;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: box-shadow 0.7s ease;

      img {
        object-fit: contain;
      }

      &:hover {
        box-shadow: 0 0px 15px rgb(175, 175, 175);
      }
      .title {
        font-size: 0.9vw;
        padding: 0;
      }
    }
  }
}
