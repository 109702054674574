.col-4{
    display: grid;
    grid-template-columns: repeat(4, .5fr);
}


@mixin grid_4{
    display:grid;
    grid-template-columns: repeat(4, 2fr);
    grid-column-gap: 10rem;
}


@mixin grid-3{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
}

@mixin grid-6{
    display:grid;
    grid-template-columns: repeat(6, 1fr);
}

@mixin grid-2{
    display:grid;
    grid-template-columns: repeat(2, 1fr);
}