.main_info {
  .input_card {
    label {
      display: block;
      font-size: 1.5rem;
      font-weight: bold;
    }
    .description {
      @include registeration_form(90%);
      height: 10%;
    }
  }

  .section {
    width: 90%;
    @include shadow;
    background: $white;
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 8px;
    position: relative;

    .title {
      display: inline-block;
      font-size: 1.4rem;
      font-weight: 500;
      //   margin-right: 80%;
    }

    .ti-plus {
      font-weight: bold;
      color: $green;
      background: $white;
      @include shadow;
      padding: 1rem;
      border-radius: 30px;
      position: absolute;
      right: 1rem;
      bottom: 0.5rem;
    }
  }

  .interaction {
    label {
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
      margin-top: 3rem;
    }
    .interactions_input {
      @include registeration_form(90%);
      display: block;
      height: 4rem;

      &:last-child {
        margin-bottom: 3rem;
      }
    }

    .message {
      height: 20%;
    }
  }
}
