.performance_monitoring-module {
  margin: 2rem;

  .info_input {
    margin-top: 1rem;
    label {
      display: block;
      font-size: 0.9vw;
    }

    input,
    select {
      @include input;
      height: -moz-fit-content;
      height: fit-content;
      width: 40rem;
      font-size: 0.8vw;
    }
  }

  .corresponding_table {
    padding-top: 2rem;
    .row_container {
      display: grid;
      grid-template-columns: 0.2fr 1fr 1fr 1fr 0.5fr 1fr 1fr 1fr;
      padding: 1rem;
      border-bottom: 1px solid rgb(151, 151, 151);

      &:first-child {
        background-color: #eee;
        box-shadow: 0 0 10px rgb(175, 175, 175);
        font-weight: 600;
        border: unset;
      }

      input,
      select {
        @include input;
        height: -moz-fit-content;
        height: fit-content;
        width: 90%;
      }
      .the_score {
        font-size: 0.8vw;
      }
    }
  }

  .key_table {
    padding-top: 2rem;
    .key_row {
      display: grid;
      grid-template-columns: 0.2fr 1fr 1fr 1fr 1fr 1fr;
      padding: 1rem;
      border-bottom: 1px solid rgb(151, 151, 151);

      &:first-child {
        background-color: #eee;
        box-shadow: 0 0 10px rgb(175, 175, 175);
        font-weight: 600;
        border: unset;
      }

      .dat {
        font-size: 0.8vw;
      }
    }
  }

  .calculations {
    width: 40%;
    font-size: 0.8vw;
    p {
      font-weight: 800;
      margin-top: 2rem;
    }

    .note {
      color: red;
      font-weight: normal;
    }
  }

  .wrap_up {
    display: flex;

    .endorsements {
      display: flex;
      margin-right: 10rem;
      .info_input {
        margin-right: 1rem;
      }
    }
  }
}
