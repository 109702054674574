.the_page-title {
  width: 100%;
  background: #eee;
  padding: 2rem;
}

.tab_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .show_content {
    width: 37% !important;
    background: white !important;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 3px rgb(194, 194, 194) !important;
    padding: 0.7rem 1rem !important;
    margin-right: 0.7rem !important;
    border-radius: 5px !important;
  }
}

.travel_main {
  display: flex;
  flex-direction: column;
  align-items: center;

  .travel_content {
    width: 97%;

    .top_part {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .create {
        height: fit-content;
        height: -moz-fit-content;
        @include primary_button;
        font-size: 0.9vw;
      }
    }

    .travel_table {
      .table_row {
        display: grid;
        grid-template-columns: 0.2fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr 0.5fr 0.4fr 0.2fr .3fr;
        padding: 2rem;
        grid-column-gap: 1rem;

        &:nth-child(odd) {
          background: #eee;
          @include shadow;
        }

        &:first-child {
          font-weight: 600;
        }

        .staff_list {
          font-size: 0.8vw;
          position: relative;
          &:hover .other_list {
            display: block;
          }

          button {
            background: none;
            border: none;
            outline: none;
            font-size: 0.7vw;
            color: rgb(0, 81, 255);
            margin-left: 1rem;
          }

          .other_list {
            position: absolute;
            right: 15px;
            background: white;
            @include shadow;
            padding: 1rem;
            display: none;
            z-index: 100;
          }
        }

        .item {
          font-size: 0.95vw;

          .ti-trash {
            font-size: 1.1vw;
            color: #ff5100;
            font-weight: bold;
            cursor: pointer;

            &:hover {
              color: red;
            }
          }

          .hold_options {
            background: white;
            box-shadow: 0px 0px 10px rgb(209, 209, 209);
            width: fit-content;
            width: -moz-fit-content;
            position: absolute;
            left: 0;
            top: 1.5rem;
            z-index: 2000;

            button {
              background: none;
              border: none;
              outline: none;
              font-size: 0.9vw;
              cursor: pointer;
              padding: 1rem;
              width: 100%;

              &:hover {
                color: lighten(#000000, 20%);
                background: #eee;
              }
            }
          }
        }
      }
    }
  }

  .create_new {
    // margin-top: 4rem;
    width: 70%;
    background: white;
    @include shadow;

    .select_staff {
      background: #eee;
      border: none;
      width: 100%;
      color: rgb(3, 3, 3);
      padding: 1rem;
      font-size: 1vw;
    }

    .other_form {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      // grid-row-gap: 3rem;
      grid-column-gap: 2rem;
      grid-template-areas:
        'means book pdate'
        'check time off'
        'check timeoff landtime'
        'check bookdby airline'
        'check fstats pnr'
        'nth purpose super'
        'nth expect return'
        'nth days dest'
        'nth nopass status'
        'nth onn onn';
      padding: 2rem;
      .info_input {
        width: 100%;
        label {
          display: block;
          font-size: 0.9vw;
          font-weight: 600;
          position: relative;

          .msg {
            position: absolute;
            color: rgb(255, 102, 0);
            font-size: 0.75vw;
            right: 0;
            bottom: 0;
          }
        }

        input,
        select {
          @include input;
          width: 100%;
          font-size: 0.9vw;
        }
      }
    }

    .hold_btn {
      text-align: center;
      margin-bottom: 2rem;
      .submit_btn {
        @include submit_button;
        font-size: 0.9vw;
      }
    }
  }
}
