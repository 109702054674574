.blur_ball {
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;

  .top1 {
    position: absolute;
    top: 10%;
    left: 5%;
    width: 3%;
    filter: blur(13px);
  }

  .bottom1 {
    position: absolute;
    bottom: 0%;
    left: 55%;
    width: 8%;
    filter: blur(13px);
  }
}

.main_signin {
  width: 100%;
  // height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .sign_in {
    // display: none;
    .title {
      font-size: 2vw;
      margin-bottom: 9rem;
      // margin-left: 1rem;
      font-weight: 600;
      display: block;
      padding-left: 1rem;
    }

    .contain {
      width: fit-content;
      .the_input {
        margin-bottom: 2rem;
        label {
          display: block;
          margin-left: 1rem;
          font-size: 1vw;
          margin-bottom: 1rem;
          font-weight: 600;
        }

        .default_input {
          width: 25vw;
          background: white;
          box-shadow: 0px 3px 5px rgb(196, 196, 196);
          outline: none;
          border: none;
          font-size: 1vw;
          padding: 1.5rem 1rem;
          border-right: 10px;
        }

        .remedy {
          text-align: right;
          font-size: 0.9vw;
          margin: 1rem 0;
          cursor: pointer;
          font-weight: 600;
          color: blue;
        }
      }
    }
  }

  .forgot_password {
    // display: none;
    .title {
      font-size: 2vw;
      margin-bottom: 9rem;
      // margin-left: 1rem;
      font-weight: 600;
      display: block;
      padding-left: 1rem;
    }

    .contain {
      width: fit-content;
      text-align: left;
      .the_input {
        margin-bottom: 4rem;
        label {
          display: block;
          margin-left: 1rem;
          font-size: 1vw;
          margin-bottom: 1rem;
          font-weight: 600;
        }

        .default_input {
          width: 25vw;
          background: white;
          box-shadow: 0px 3px 5px rgb(196, 196, 196);
          outline: none;
          border: none;
          font-size: 1vw;
          padding: 1.5rem 1rem;
          border-right: 10px;
        }

        .remedy {
          text-align: right;
          font-size: 0.9vw;
          margin: 1rem 0;
          cursor: pointer;
          font-weight: 600;
          color: blue;
        }
      }
    }
  }
}

.default_primary-button {
  @include submit_button;
  width: fit-content;
  width: -moz-fit-content;
}
