.top_memo {
  display: flex;
  justify-content: center;
  align-items: center;
  .memo_preview {
    width: 15%;
    box-shadow: 0 0 8px #cecece;
    background: white;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    margin-right: 1rem;

    .notice {
      height: 20rem;
      width: 20rem;
      background: $employee_profile-color;
      position: absolute;
      top: -50%;
      left: -25%;
      z-index: 0;
      border-radius: 100px;
    }

    .termination {
      height: 20rem;
      width: 20rem;
      background: rgb(228, 46, 0);
      position: absolute;
      top: -50%;
      left: -25%;
      z-index: 0;
      border-radius: 100px;
    }

    .transfer {
      height: 20rem;
      width: 20rem;
      background: $button_green-2;
      position: absolute;
      top: -50%;
      left: -25%;
      z-index: 0;
      border-radius: 100px;
    }

    .query {
      height: 20rem;
      width: 20rem;
      background: $orange;
      position: absolute;
      top: -50%;
      left: -25%;
      z-index: 0;
      border-radius: 100px;
    }

    .card_title {
      color: rgb(255, 255, 255);
      font-size: 1vw;
      text-align: center;
      z-index: 2;
      width: 50%;
    }

    .total_num {
      text-align: right;
      font-size: 2.5vw;
    }
  }

  .button_hold {
    margin-left: 2rem;
    display: flex;

    .filter_com {
      position: relative;
      .filter_options {
        position: absolute;
        display: flex;
        flex-direction: column;
        background: white;
        box-shadow: 0 0 6px #cecece;
        width: 100%;
        // display: none;

        .filter_btn {
          background: none;
          border: none;
          outline: none;
          font-size: 0.8vw;
          padding: 1rem;

          &:not(:last-child) {
            border-bottom: 1px solid rgb(224, 224, 224);
          }

          &:hover {
            color: grey;
          }
        }
      }
    }

    .filter,
    .new {
      @include primary_button;
      font-size: 0.8vw;
      padding: 1rem;
      margin-right: 1rem;
      height: fit-content;
      height: -moz-fit-content;
    }
  }
}

.filter_list {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 1rem 0;
  .info_input {
    //   margin-bottom: 1rem;
    margin-left: 1rem;
    label {
      display: block;
      font-size: 0.9vw;
    }

    input,
    select {
      @include registeration_form(fit-content);
      height: -moz-fit-content;
      height: fit-content;
      font-size: 1vw;
      margin: 0;
    }

    select {
      width: 20rem;
    }
  }

  .search_input {
    @include registeration_form(50rem);
    height: fit-content;
    height: -moz-fit-content;
    display: flex;
    align-items: center;
    margin: 0;
    i {
      margin-right: 0.5rem;
    }

    input {
      background: none;
      outline: none;
      border: none;
      width: 98%;
    }
  }

  .filter {
    @include submit_button;
    font-size: 0.9vw;
    margin-left: 2rem;
    padding:1rem;
  }
}

.table_container {
  padding: 1rem;
  //   display: none;


  .memo_data {
    display: grid;
    grid-template-columns: 0.2fr 1fr 0.7fr 1fr 2fr 1fr 1fr 0.4fr 0.7fr;
    padding: 1rem;
    position: relative;

    &:nth-child(odd) {
      background: #cfcfcf;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    &:first-child {
      font-weight: 600;
    }

    .table_item {
      font-size: 0.9vw;
    }
  }
}

.new_memo {
  // display: none;
  .initial_selection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 0;
    // display: none;

    .info {
      font-size: 1vw;
      margin-bottom: 10rem;
    }
  }

  .the_top-first {
    display: flex;
    padding: 1rem 2rem;
    justify-content: space-between;
    align-items: center;

    .form_title {
      font-size: 1.2vw;
      font-weight: 500;
    }


    .hold_btn{
      .list{
        background:none;
        border:none;
        outline:none;
        font-size: .9vw;
        margin-right: 1rem;
        cursor:pointer;
        padding:1rem 1.5rem;
        &:hover {
          background:lightgray;
          box-shadow: 0 0 8px #cecece;
          color: orange;
          border-radius: 5px;
        }
      }
      .go_back {
        @include primary_button;
        font-size: 0.8vw;
        padding: 1rem;
        background: grey;
        color: white;
        height: fit-content;
        height: -moz-fit-content;

        &:hover {
          background: grey;
          color: orange;
        }
      }
    }

  }
  .info_input {
    //   margin-bottom: 1rem;
    margin-left: 1rem;
    label {
      display: block;
      font-size: 0.9vw;
    }

    input,
    select,
    textarea {
      @include registeration_form(fit-content);
      height: -moz-fit-content;
      height: fit-content;
      font-size: 1vw;
      margin: 0;
    }

    select {
      width: 30rem;
    }
  }
  .first_forms {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .Selection_container {
      width: 30rem;

      .persons_selected {
        width: 100%;
        padding: 1rem;
        span {
          font-size: 0.7vw;
          &:not(:last-child) {
            margin-right: 0.5rem;
          }

          .ti-close {
            color: red;
            font-weight: bold;
            font-size: 0.6vw;
            cursor: pointer;
          }
        }
      }
    }
  }

  .down_part {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .hold_two {
      input {
        font-size: 0.9vw;
      }

      span {
        font-size: 0.9vw;
      }
    }

    .submit {
      @include submit_button;
      margin-top: 2rem;
    }
  }
}

.user_view {
  .filter_com {
    position: relative;
    .filter_options {
      position: absolute;
      display: flex;
      flex-direction: column;
      background: white;
      box-shadow: 0 0 6px #cecece;
      width: 100%;
      // display: none;

      .filter_btn {
        background: none;
        border: none;
        outline: none;
        font-size: 0.8vw;
        padding: 1rem;

        &:not(:last-child) {
          border-bottom: 1px solid rgb(224, 224, 224);
        }

        &:hover {
          color: grey;
        }
      }
    }
  }

  .filter_by {
    @include primary_button;
    font-size: 0.8vw;
    padding: 1rem;
    margin-right: 1rem;
    height: fit-content;
    height: -moz-fit-content;
  }

  .select_pop {
    background: rgba(0, 0, 0, 0.788);
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .pop_card {
      background: white;
      box-shadow: 0 0 8px rgb(51, 51, 51);
      width: 70%;

      .message_brief {
        background: #eee;
        padding: 2rem;
        position: relative;

        .the_title {
          font-size: 1.5vw;
          margin-bottom: 3rem;
        }

        .other_info {
          display: flex;
          justify-content: space-between;

          span {
            font-size: 0.9vw;

            &:first-child {
              font-weight: bold;
            }
          }
        }

        .ti-close {
          position: absolute;
          top: 2rem;
          z-index: 100;
          right: 2rem;
          font-size: 1.2vw;
          font-weight: bold;
          cursor: pointer;
          color: red;

          &:hover {
            transform: scale(1.2);
          }
        }
      }

      .the_body {
        font-size: 0.9vw;
        padding: 2rem 4rem;
      }

      .button_holder {
        text-align: center;
        padding: 1rem;

        .print {
          @include primary_button;
          font-size: 0.8vw;

          .ti-printer {
            font-size: 1vw;
            font-weight: bold;
          }
        }
      }
    }
  }
}


.read{
  height:.7rem;
  width:.7rem;
  background:rgb(0, 156, 0);
  position:absolute;
  right:1.2rem;
  margin-top: 1.5rem;
  border-radius: 5000px;
}
