.main {
  .leave_management {
    .info {
      width: 70%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      .leave_detail {
        text-align: center;
        span {
          display: block;
        }
        .available {
          font-size: 4.5rem;
        }

        .info_name {
          font-size: 1.5rem;
          font-weight: 500;
          display: inline-block;
          width: 60%;
          color: $white;
          @include dark_shadow;
        }

        .green {
          background-color: $green;
        }

        .peach {
          background: rgb(223, 0, 74);
        }

        .orange {
          background: $orange;
        }

        .sub_info {
          font-size: 1.3rem;
          font-weight: normal;
          color: darken($table_grey, 30%);
        }
      }
    }

    .reference_table {
      .right_part {
        display: inline-block;
        text-align: right;
        width: 95%;
        .search {
          display: inline-block;
          margin-left: 4rem;
          @include search;
          width: 30%;
          height: 4rem;
          padding: 0 0.5rem;

          #nav_search {
            border: none;
            background: none;
            width: 90%;
            height: 100%;
            outline: none;
          }

          .search_icon {
            img {
              margin-bottom: -0.5rem;
              width: 2rem;
            }
          }
        }
      }
    }

    // my leave===============================================MY LEAVE========================MY LEAVE=====

    .reference_table {
      .my-leave {
        display: inline-block;
        text-align: right;
        width: 80%;
        .search {
          display: inline-block;
          margin-left: 4rem;
          @include search;
          width: 30%;
          height: 4rem;
          padding: 0 0.5rem;

          #nav_search {
            border: none;
            background: none;
            width: 90%;
            height: 100%;
            outline: none;
          }

          .search_icon {
            img {
              margin-bottom: -0.5rem;
              width: 2rem;
            }
          }
        }
      }
    }

    .leave-calendar {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 0 5rem;

      .events {
        padding-top: 5rem;
        padding-left: 4rem;

        .event_items {
          font-size: 1.4rem;
          color: darken($table_grey, 40%);

          .heading {
            font-weight: bold;
          }

          .date {
            margin-right: 3rem;
          }
        }
      }
    }
  }
}

.total {
  margin-top: 20%;

  .popup_label {
  }

  .popup_input {
    border: none;
    // @include input;
  }
}

.leave_popup-btn {
  .leave_popup-btns {
    .accept {
      @include submit_button;
      padding: 0.5rem 2.5rem;
    }

    .decline {
      @include secondary_button;
      padding: 0.5rem 2.5rem;

      &:hover,
      &:focus,
      &:active {
        border: 2px solid $green;
        color: $black;
        padding: 0.3rem 2.5rem;
      }
    }
  }
}

.leave__type,
.approval__person,
.approval__level {
  @include input;
  font-size: 1.4rem;

  select {
    width: 100%;
  }
}

.leave__approval-button {
  text-align: center;
  .done_btn {
    @include submit_button;
  }
}

.select__leave-type {
  margin: 0.5rem 0;
  label {
    font-size: 1.3rem;
    color: lightgrey;
  }
  select {
    @include input;
    box-shadow: none;
    width: 100%;
    font-size: 1.4rem;
  }
}

.start__date {
  margin: 0.5rem 0;
  position: relative;
  label {
    font-size: 1.3rem;
    color: lightgrey;
  }
  input {
    @include input;
    box-shadow: none;
    width: 85%;
    font-size: 1.4rem;
  }

  .selector {
    color: $white;
    &:hover {
      color: $green;
    }
  }
}

.end__date {
  margin: 0.5rem 0;
  position: relative;
  label {
    font-size: 1.3rem;
    color: lightgrey;
  }
  input {
    @include input;
    box-shadow: none;
    width: 85%;
    font-size: 1.4rem;
  }

  .selector {
    color: $white;
    &:hover {
      color: $green;
    }
  }
}

.leave__reasons {
  margin: 0.5rem 0;
  position: relative;
  label {
    font-size: 1.3rem;
    color: lightgrey;
  }
  textarea {
    @include input;
    box-shadow: none;
    width: 90%;
    font-size: 1.4rem;
  }
}

.leave_button-contain {
  text-align: center;
  .leave_dialogue-submit {
    @include submit_button;
    box-shadow: none;
    padding: 0rem 2rem;
    font-weight: normal;

    &:hover {
      box-shadow: none;
    }
  }
  .leave_dialogue-cancel {
    @include secondary_button;
    box-shadow: none;
    padding: 0rem 2rem;
    color: $white;
    font-weight: normal;

    &:hover {
      box-shadow: none;
      padding: 0rem 2rem;
    }
  }
}

.card__head {
  background-color: $green;
  height: 8vh;
  width: 100%;
  border-radius: 5px;
  padding: 1.5rem 1rem;
  color: $white;
  font-weight: normal;
}

.main_total {
  font-size: 1.6rem;
  font-weight: bold;
}

.new_pop {
  background: rgba(255, 255, 255, 0.418);
  .heading {
    font-size: 1.7rem;
    color: $black;
    margin-bottom: 7rem;
  }

  .persons_detail {
    .main__name {
      font-size: 3rem;
      color: $black;

      .the_green {
        color: rgb(61, 250, 3);
      }
    }

    .the__department {
      font-size: 1.6rem;
      color: $black;
    }
  }

  .the__popup-table {
    .my_leave-table {
      width: 100%;
      .thead,
      .tbody {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
      }

      .thead {
        margin-bottom: 2rem;
        font-weight: bold;
        padding: 1rem 2rem;
        background: rgb(70, 70, 70);
        font-size: 1.4rem;
      }

      .tbody {
        margin-bottom: 2rem;
        font-weight: bold;
        padding: 1rem 2rem;
        color: azure;
        font-size: 1.4rem;

        &:nth-child(odd) {
          background: rgb(70, 70, 70);
        }
      }
    }
  }
}

.the__print {
  text-align: right;
  width: 100%;

  button {
    border: none;
    background: none;
    outline: none;
    font-size: 1.4rem;
    color: $white;

    &:hover {
      color: $orange;
    }

    .ti-printer {
      margin-left: 1rem;
    }
  }
}

.fast_touch {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}


.type{
  display: grid;
  padding: 1rem;

  span {
    font-size: 1.4rem;

    .ti-trash{
      font-size: 2rem;
      color:#ff9000;
    }
  }
  .select__le-type{


    select{
      @include input;
      width:90%;
      font-size: 1.4rem;
    }
  }
}
