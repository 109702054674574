.grid_container {
  display: grid;
  grid-template-columns: 0.3fr 1fr;

  .form_holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #eee;
    // height: 75vh;
    padding: 1rem;
    box-sizing: border-box;
    .title {
      font-size: 1.2vw;
      margin-bottom: 2rem;
      margin-top: 2rem;
      margin-left: 0;
      padding-left: 0;
      text-align: left;
    }

    .hold_input {
      height: 85%;

      .info_input {
        // margin-bottom: 1rem;
        label {
          display: block;
          font-size: 0.9vw;
        }

        input,
        select {
          @include registeration_form(94%);
          height: -moz-fit-content;
          height: fit-content;
        }
      }

      .checker {
        margin-bottom: 2rem;
        padding: 0 3rem;
        text-align: right;
        font-size: 0.9vw;
      }

      .btn_hold {
        text-align: center;

        .submit {
          @include submit_button;
        }
      }
    }
  }

  .content_right {
    padding: 1rem 0;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 40%;

      .title {
        padding: 0;
        margin: 0;
      }
      .secondary-btn {
        @include primary_button;
        margin-bottom: 1rem;
        padding:1rem;
      }
    }

    .requisition_table {
      height: 69vh;
      overflow-y: scroll;
      .the_row {
        display: grid;
        grid-template-columns: .2fr .5fr .3fr .5fr 1fr .8fr .4fr  .4fr;
        padding: 1rem;

        &:first-child {
          .item {
            font-weight: 600;
            text-transform: capitalize;
          }
        }

        &:nth-child(odd) {
          background: #f1f1f1;
          box-shadow: 0 2px 1px rgb(216, 216, 216);
        }

        .item {
          font-size: 0.9vw;
        }
      }
    }
  }
}

.add_item-btn {
  @include primary_button;
  font-size: 0.8vw;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  margin: 0;
  padding: 0.5rem 1rem;
}

.grey_out {
  position: fixed;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.801);
  left: 0;
  top: 0;
  z-index: 15000;
  display: flex;
  justify-content: center;
  align-items: center;
  // display: none;

  .add_item-sec {
    background: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 60%;
    max-height: 80vh;
    overflow-y: scroll;
    border-radius: 10px;

    .top_sec {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      background: #eee;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      .add_more {
        @include primary_button;
        font-size: 0.9vw;
        margin-left: 2rem;
        height: -moz-fit-content;
        height: fit-content;
        cursor: pointer;
      }
    }

    .item_table {
      .item_row {
        display: grid;
        grid-template-columns: 1fr 0.3fr 0.5fr 0.5fr 0.5fr .3fr ;
        padding: 1rem;

        .item {
          font-size: 0.9vw;

          .ti-close {
            color: red;
            cursor: pointer;
            font-weight: bold;
          }
        }

        &:nth-child(odd) {
          background: #eee;
        }


      }
    }
  }
}

.hide_element {
  display: none !important;
}

.hide_grid {
  display: block;
}
