.visitors_title {
  font-size: 1.3vw;
  font-weight: 500;
  margin: 3rem 0;
  padding-left: 1rem;
}

.visitor_main {
  display: flex;
  align-items: center;
  justify-content: center;
  .vistors_detail-form {
    width: 30%;

    .info_input {
      //   margin-bottom: 1rem;
      margin-left: 1rem;
      label {
        display: block;
        font-size: 0.9vw;
      }

      input,
      select {
        @include registeration_form(100%);
        height: -moz-fit-content;
        height: fit-content;
        font-size: 1vw;
        margin: 0;
      }
    }

    .submit {
      @include submit_button;
      margin-top: 1rem;
    }
  }

  .visitor_table {
    width: 100%;
    padding: 2rem;
    .the_row {
      display: grid;
      grid-template-columns: 0.2fr 1fr 0.7fr 1fr 1fr 0.7fr 0.5fr .7fr .7fr 1fr 0.4fr 0.4fr 0.1fr;
      align-items: center;
      padding: 1rem;
      .item {
        font-size: 0.9vw;
        input {
          @include registeration_form(80%);
          height: -moz-fit-content;
          height: fit-content;
          font-size: 0.9vw;
          margin: 0;
        }

        .ti-more-alt {
          font-weight: bold;
          cursor: pointer;
        }
      }

      &:nth-child(odd) {
        background: #f1f1f1;
        box-shadow: 0 2px 1px rgb(216, 216, 216);
      }
    }
  }
}

.group_booking {
  width: 100%;
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  // grid-column-gap: 2rem;
  // padding: 1rem;
}

.name_factor {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 105%;

  .info_input {
    width: 75%;
  }

  .add_more {
    @include primary_button;
    cursor: pointer;
    padding: 1rem;
    height: fit-content;
    height: -moz-fit-content;
  }
}

.visitor_list {
  width: 20%;
  margin-left: 7rem;

  .the_heading {
    font-size: 1.2vw;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .name {
    font-size: 1vw;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.51rem;
    border-bottom: 1px solid #000;

    font-weight: 500;

    .ti-close {
      color: red;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        color: rgb(248, 89, 31);
      }
    }
  }
}

.department_list {
  position: absolute;
  background: white;
  width: 30%;
  box-shadow: 0 0 6px #ccc;
  padding: 1rem;

  .department_fetched {
    font-size: 1vw;
    padding: 1rem;

    &:not(:last-child) {
      border-bottom: 1px solid rgb(196, 196, 196);
    }
  }
}

.top_reg {
  display: flex;

  .page_title {
    margin-right: 2rem;
  }

  .tab_select {
    @include primary_button;
    padding: 1rem;
    height: fit-content;
  }
}

.new {
  @include primary_button;
  padding: 1rem;
  height: fit-content;
}

.search_visitors {
  width: 40%;
  border-bottom: 1px solid grey;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.5rem;
  position: relative;

  .search_input {
    width: 95%;
    border: none;
    outline: none;
    font-size: 0.9vw;
    font-weight: normal;
    background: none;
  }

  img {
    width: 4%;
  }

  .search_list {
    position: absolute;
    width: 100%;
    left: 0;
    top: 3rem;
    background: white;
    @include shadow;
    padding: 1rem;

    .visitors_name {
      display: block;
      font-size: 0.9vw;
    }
  }
}
