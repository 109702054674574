.search_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include input;
  width: 40%;

  .ti-search {
    font-size: 1vw;
    font-weight: bold;
  }
  input {
    border: none;
    outline: none;
    width: 93%;
    font-size: 1vw;
  }
}

.staff_list {
  padding: 1rem;
  .page_title {
    font-size: 1.2vw;
    font-weight: 600;
  }

  .new_staff-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    margin-top: 2rem;
    .new_staff-card {
      border: none;
      background: white;
      display: flex;
      justify-content: space-between;
      width: 100%;
      box-shadow: 0 0px 2px 3px rgba(238, 238, 238, 0.884);
      padding: 1rem;
      box-sizing: border-box;
      border-radius: 5px;

      .info {
        text-align: left;
        display: flex;
        flex-direction: column;
        padding: 0 !important;

        span {
          font-size: 0.9vw;
          display: block;

          &:first-child {
            font-size: 0.9vw;
            color: grey;
            font-weight: 600;
          }
        }
      }
    }

    .granted {
      border-left: 7px solid green;
    }

    .not_granted {
      border-left: 7px solid red;
    }
  }
}

.contain_grid {
  // display: none;
}

.module_container {
  padding: 1rem;
  background: #eee;
  height: 91.5vh;
  overflow-y: scroll;

  .hold_content {
    display: flex;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    .module_btn {
      @include primary_button;
      font-size: 0.9vw;
      margin-right: 1rem;
      padding: 0.7rem 1rem;
    }
  }
}

.right_side {
  // display: none;
}

.module_content {
  display: grid;
  grid-template-columns: 0.25fr 1fr;
  //   padding: 1rem;

  .sub_menu {
    border-right: 1px solid #eee;
    padding-right: 1rem;
    height: 89vh;
    .hold_content {
      display: flex;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      .module_btn {
        font-size: 0.9vw;
        margin-right: 1rem;
        background: none;
        border: none;
        outline: none;
        color: rgb(0, 75, 146);
        &:hover,
        &:active,
        &:focus {
          color: grey;
        }
      }
    }
  }

  .assign_section {
    // display: none;
    .position_right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;

      .menus {
        .tab_btn {
          background: white;
          border: none;
          outline: none;
          font-size: 0.9vw;
          box-shadow: 0 0px 2px 3px #eee;
          margin-right: 0.5rem;
          border-radius: 5px;
        }

        .active {
          background: rgb(80, 80, 80);
          color: orange;
        }
      }
    }

    .the_content {
      .tab-content {
        .content {
          .top_sec {
            padding: 1rem;
            display: flex;
            align-items: center;
            margin-top: 2rem;
            border-bottom: 1px solid rgb(168, 168, 168);
            .info_input {
              label {
                display: block;
                font-size: 0.9vw;
              }

              input,
              select {
                @include input;
                width: 30rem;
                font-size: 1vw;
                margin-right: 1.5rem;
              }
            }

            .add_new {
              background: white;
              border: none;
              outline: none;
              font-size: 0.9vw;
              box-shadow: 0 0px 2px 3px #eee;
              margin-right: 0.5rem;
              border-radius: 5px;
              padding: 1rem;
            }
          }
        }
      }
    }
  }
}

.adjust_width {
  width: 19% !important;
}

.contain_entry {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1rem 0;
  width: 100%;
  .activity_card {
    background: white;
    box-shadow: 0 0 3px #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 35%;
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    margin-right: 1rem;

    .the_activity_name,
    .the_access {
      color: black;
      font-size: 1vw;
    }

    .ti-close {
      color: red;
      font-weight: bold;
    }
  }
}

.hold_button {
  width: 100%;
  text-align: center;

  .submit {
    @include submit_button;
    font-size: 0.9vw;
  }
}
