.hse_module {
  .Incident_report {
    .title_top {
      .page_title {
        font-size: 1.2vw;
        font-weight: normal;
      }
    }

    .first_screen {
      padding: 2rem;
      width: 100%;
      box-sizing: border-box;

      .top_action {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 1rem;

        .make_selection {
          display: flex;
          align-items: center;
          font-size: 0.8vw;
          padding-left: 1rem;

          input {
            margin-right: 0.5rem;
          }
        }

        .from_to {
          display: flex;
          align-items: flex-end;
          width: 40%;
          .date {
            @include primary_button;
            padding: 1rem;
            cursor: pointer;
          }

          .info_input {
            margin-left: 1rem;
            margin-right: 2rem;
            label {
              font-size: 0.8vw;
            }
            input {
              @include registeration_form(100%);
              font-size: 0.9vw;
              margin: 0;
            }
          }
        }

        .new {
          @include primary_button;
          padding: 1rem;
          cursor: pointer;
        }
      }

      .record_view {
        .card_view {
          width: 100%;
          background: white;
          box-shadow: 0 0 10px $shadow;
          display: flex;
          justify-content: space-between;
          padding: 1rem;
          box-sizing: border-box;
          margin-bottom: 1rem;
          cursor: pointer;

          .info_container {
            font-size: 0.85vw;
            font-weight: 600;

            .sub_info {
              font-weight: normal;
              color: rgb(85, 85, 85);
            }
          }
        }
      }
    }

    .follow_up {
      font-size: 0.9vw;
      margin: 3rem 0;
    }
    .print_view {
      display: flex;
      flex-direction: column;
      align-items: center;
      .print_top {
        display: flex;
        flex-direction: column;
        align-items: center;
        .company_name {
          font-size: 1.5vw;
        }

        .module_name {
          font-size: 1.2vw;
        }

        .motto {
          font-size: 1vw;
        }
      }

      .primary_capture {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 2rem;
        width: 60%;

        p {
          font-size: 0.9vw;
          font-weight: 600;
          margin-right: 2rem;

          span {
            font-weight: normal;
          }
        }
      }

      .event_view {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;

        .main_tab-head {
          width: 90%;
          display: grid;
          grid-template-columns: 1fr 2fr 1fr;
          padding: 1rem;
          box-sizing: border-box;
          span {
            font-size: 0.9vw;
          }
        }
        .category {
          width: 90%;

          .tab_row,
          .category_head {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 2fr 1fr;
            padding: 1rem;
            box-sizing: border-box;

            span {
              font-size: 0.9vw;
            }
          }

          .category_head {
            background: rgb(104, 101, 101);
            color: white;
          }
        }

        .closing {
          padding: 2rem;
          span {
            font-size: 0.8vw;
            font-weight: 600;
          }

          .comment {
            font-size: 1vw;
            margin-bottom: 2rem;
          }
        }
      }
    }

    .hazard_form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .top_info {
        font-size: 1vw;
        font-weight: 600;
        text-align: center;
        margin-bottom: 2rem;

        .sub_info {
          display: block;
          font-size: 0.9vw;
          margin-top: 1rem;
          font-weight: 500;
        }
      }

      .info_input {
        width: 40%;
        label {
          font-size: 0.8vw;
          margin-bottom: 0.5rem;
        }
        .input_entry {
          @include registeration_form(100%);
          font-size: 0.9vw;
        }
      }

      .hazard_first_form {
        padding: 2rem 0;
        width: 70vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .contain {
          display: flex;
          justify-content: space-between;
          width: 40%;
        }

        .intervention {
          font-size: 0.9vw;
          font-weight: 500;

          span {
            margin-left: 1rem;
          }
        }

        .incident_category {
          padding: 5rem 0;
          .section_title {
            font-size: 1.1vw;
            font-weight: 500;
            margin-bottom: 1rem;
            text-align: center;
          }

          .selections {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-gap: 1rem 2rem;
            .category {
              font-size: 0.9vw;
              display: flex;
              align-items: center;
              font-weight: 500;

              input {
                margin-left: 0.5rem;
              }
            }
          }
        }
      }

      .hazard_second_form {
        margin: 2rem 0;
        width: 50vw;

        .contain_forms {
          background: white;
          box-shadow: 0 0 10px #ddd;

          .event_form {
            width: 100%;

            .top_collapse {
              background: rgb(37, 37, 37);
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 1rem 2rem;
              border-bottom: 1px solid grey;

              .event_title {
                font-size: 0.9vw;
                color: #ccc;
                font-weight: 500;
              }

              .toggle_collapse {
                background: none;
                border: none;
                outline: none;
                color: #ff9000;
                font-weight: bold;
                font-size: 1vw;
                cursor: pointer;
                height: 3rem;
                width: 3rem;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                  background: rgb(70, 70, 70);
                }
              }
            }
            .actual_content {
              .actual_event {
                display: flex;
                justify-content: space-between;
                padding: 1rem 2rem;

                &:nth-child(even) {
                  background: #eee;
                }

                .event_name {
                  font-size: 0.9vw;
                }

                .safety_check {
                  width: 20%;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .safe,
                  .unsafe {
                    font-size: 0.9vw;
                    input {
                      margin-left: 0.5rem;
                    }
                  }

                  .safe {
                    color: green;
                  }

                  .unsafe {
                    color: red;
                  }
                }
              }
            }
          }
        }
      }

      .comments {
        margin: 2rem 0;
        width: 50vw;

        textarea {
          height: unset !important;
          font-family: sans-serif;
          font-size: 0.9vw;
        }

        .follow_up-question {
          font-size: 0.9vw;
          font-weight: 500;
          .response {
            margin-left: 1rem;
          }
        }
      }

      .button_hold {
        text-align: center;
        padding: 2rem 0;

        .previous {
          @include primary_button;
          cursor: pointer;
          padding: 1rem;
        }
        .next {
          @include submit_button;
        }
      }
    }
  }

  .work_permit {
    .first_screen {
      padding: 2rem;
      width: 100%;
      box-sizing: border-box;

      .top_action {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 1rem;

        .make_selection {
          display: flex;
          align-items: center;
          font-size: 0.8vw;
          padding-left: 1rem;

          input {
            margin-right: 0.5rem;
          }
        }

        .from_to {
          display: flex;
          align-items: flex-end;
          width: 40%;
          .date {
            @include primary_button;
            padding: 1rem;
            cursor: pointer;
          }

          .info_input {
            margin-left: 1rem;
            margin-right: 2rem;
            label {
              font-size: 0.8vw;
            }
            input {
              @include registeration_form(100%);
              font-size: 0.9vw;
              margin: 0;
            }
          }
        }

        .new {
          @include primary_button;
          padding: 1rem;
          cursor: pointer;
        }
      }

      .record_view {
        .card_view {
          width: 100%;
          background: white;
          box-shadow: 0 0 10px $shadow;
          display: flex;
          justify-content: space-between;
          padding: 1rem;
          box-sizing: border-box;
          margin-bottom: 1rem;
          cursor: pointer;

          .info_container {
            font-size: 0.85vw;
            font-weight: 600;

            .sub_info {
              font-weight: normal;
              color: rgb(85, 85, 85);
            }
          }
        }
      }
    }

    .title_top {
      .page_title {
        font-size: 1.2vw;
        font-weight: normal;
      }
    }

    .print_view {
      display: flex;
      flex-direction: column;
      align-items: center;
      .print_top {
        display: flex;
        flex-direction: column;
        align-items: center;
        .company_name {
          font-size: 1.5vw;
        }

        .module_name {
          font-size: 1.2vw;
        }

        .motto {
          font-size: 1vw;
        }
      }

      .primary_capture {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 2rem;
        width: 60%;

        p {
          font-size: 0.9vw;
          font-weight: 600;
          margin-right: 2rem;

          span {
            font-weight: normal;
          }
        }
      }

      .event_view {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;

        .main_tab-head {
          width: 90%;
          display: grid;
          grid-template-columns: 1fr 2fr 1fr;
          padding: 1rem;
          box-sizing: border-box;
          span {
            font-size: 0.9vw;
          }
        }
        .category {
          width: 90%;

          .tab_row,
          .category_head {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 2fr 1fr;
            padding: 1rem;
            box-sizing: border-box;

            span {
              font-size: 0.9vw;
            }
          }

          .category_head {
            background: rgb(104, 101, 101);
            color: white;
          }
        }

        .closing {
          padding: 2rem;
          span {
            font-size: 0.8vw;
            font-weight: 600;
          }

          .comment {
            font-size: 1vw;
            margin-bottom: 2rem;
          }
        }
      }
    }

    .work_permit-forms {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .top_info {
        font-size: 1vw;
        font-weight: 600;
        text-align: center;
        margin-bottom: 2rem;

        .sub_info {
          display: block;
          font-size: 0.9vw;
          margin-top: 1rem;
          font-weight: 500;
        }
      }

      .extra {
        font-size: 0.8vw;
      }

      .info_input {
        width: 40%;
        label {
          font-size: 0.8vw;
          margin-bottom: 0.5rem;
        }
        .input_entry {
          @include registeration_form(100%);
          font-size: 0.9vw;
          height: fit-content;
          height: -moz-fit-content;
        }
      }

      .hazard_first_form {
        padding: 2rem 0;
        width: 70vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .contain {
          display: flex;
          justify-content: space-between;
          width: 40%;
        }

        .intervention {
          font-size: 0.9vw;
          font-weight: 500;

          span {
            margin-left: 1rem;
          }
        }

        .incident_category {
          padding: 5rem 0;
          .section_title {
            font-size: 1.1vw;
            font-weight: 500;
            margin-bottom: 1rem;
            text-align: center;
          }

          .selections {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-gap: 1rem 2rem;
            .category {
              font-size: 0.9vw;
              display: flex;
              align-items: center;
              font-weight: 500;

              input {
                margin-left: 0.5rem;
              }
            }
          }
        }
      }

      .hazard_second_form {
        margin: 2rem 0;
        width: 50vw;

        .contain_forms {
          background: white;
          box-shadow: 0 0 10px #ddd;

          .event_form {
            width: 100%;

            .top_collapse {
              background: rgb(37, 37, 37);
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 1rem 2rem;
              border-bottom: 1px solid grey;

              .event_title {
                font-size: 0.9vw;
                color: #ccc;
                font-weight: 500;
              }

              .toggle_collapse {
                background: none;
                border: none;
                outline: none;
                color: #ff9000;
                font-weight: bold;
                font-size: 1vw;
                cursor: pointer;
                height: 3rem;
                width: 3rem;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                  background: rgb(70, 70, 70);
                }
              }
            }
            .actual_content {
              .actual_event {
                display: flex;
                justify-content: space-between;
                padding: 1rem 2rem;

                &:nth-child(even) {
                  background: #eee;
                }

                .event_name {
                  font-size: 0.9vw;
                }

                .safety_check {
                  width: 20%;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .safe,
                  .unsafe {
                    font-size: 0.9vw;
                    input {
                      margin-left: 0.5rem;
                    }
                  }

                  .safe {
                    color: green;
                  }

                  .unsafe {
                    color: red;
                  }
                }
              }
            }
          }
        }
      }

      .comments {
        margin: 2rem 0;
        width: 50vw;

        textarea {
          height: unset !important;
          font-family: sans-serif;
          font-size: 0.9vw;
        }

        .follow_up-question {
          font-size: 0.9vw;
          font-weight: 500;
          .response {
            margin-left: 1rem;
          }
        }
      }

      .button_hold {
        text-align: center;
        padding: 2rem 0;

        .previous {
          @include primary_button;
          cursor: pointer;
          padding: 1rem;
        }
        .next {
          @include submit_button;
        }
      }
    }
  }
}

.approval {
  width: 70%;
  .pop_title {
    font-size: 1vw;
    margin: 2rem 0;
  }

  .attestation {
    font-size: 0.9vw;
    width: 100%;
    margin-bottom: 5rem;
  }
  .signatories {
    display: flex;
    justify-content: space-between;
    width: 100%;

    span {
      font-size: 0.9vw;
      width: 30%;

      &::after {
        content: '';
        border-bottom: 1px solid #000;
        display: inline-block;
        width: 80%;
      }
    }
  }
}

.work_permit-print {
  padding: 2rem;

  .pop_title {
    font-size: 1vw;
    margin-bottom: 2rem;
  }

  .sec {
    width: 100%;
    margin-bottom: 2rem;

    .the_heading {
      font-size: 0.9vw;
    }

    span {
      font-size: 0.9vw;
      margin-left: 2rem;

      input {
        margin-right: 0.5rem;
      }
    }
  }

  .the_grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .sec {
      width: 100%;
      margin-bottom: 2rem;
      display: flex;
      align-items: center;

      .the_heading {
        font-size: 0.9vw;
      }

      span {
        font-size: 0.9vw;
        margin-left: 2rem;

        input {
          margin-right: 0.5rem;
        }
      }
    }

    .point_two {
      .location_line {
        font-size: 0.9vw;
        width: 30%;

        &::after {
          content: '';
          border-bottom: 1px solid #000;
          display: inline-block;
          width: 92%;
        }
      }

      .question {
        font-size: 0.9vw;
        margin: 2rem 0;
      }

      .hr {
        border-bottom: 1px solid #000;
        width: 100%;
        margin-top: 5rem;
      }
    }
  }

  .declaration {
    width: 100%;
    .pop_title {
      font-size: 1vw;
      margin: 2rem 0;
    }

    .attestation {
      font-size: 0.9vw;
      width: 100%;
      margin-bottom: 5rem;
    }
    .signatories {
      display: flex;
      justify-content: unset;
      width: 100%;
      flex-wrap: wrap;

      span {
        font-size: 0.9vw;
        width: 32%;
        margin-right: 2rem;
        margin-bottom: 3rem;

        &::after {
          content: '';
          border-bottom: 1px solid #000;
          display: inline-block;
          width: 70%;
          margin-left: 1rem;
        }
      }
    }
  }

  .validity {
    .pop_title {
      font-size: 1vw;
    }

    .validity_form {
      width: 100%;
      display: flex;
      span {
        width: 50%;
        font-size: 0.9vw;
        input {
          background: none;
          border: none;
          outline: none;
          border-bottom: 1px solid #000;
          font-size: 0.9vw;
          text-transform: uppercase;
          width: 40%;
        }
      }
    }

    .warning {
      font-size: 0.9vw;
      margin-top: 2rem;
    }
  }

  .completion_work {
    margin-top: 2rem;
    .pop_title {
      font-size: 1vw;
    }

    .confirmation {
      font-size: 0.9vw;
      width: 100%;
      margin-bottom: 5rem;
    }

    .signed {
      display: flex;
      // justify-content: center;
      align-items: flex-end;

      span {
        font-size: 0.9vw;
        width: 30%;

        &::after {
          content: '';
          border-bottom: 1px solid #000;
          display: inline-block;
          width: 80%;
        }
      }
    }
  }
}


.make_select{
  @include registeration_form(30%);
          font-size: 0.9vw;
          height: fit-content;
          height: -moz-fit-content;
          margin-right: 3rem;
          margin-bottom: 0;
          margin-top: 0;
}

.preview_print {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.877);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .print_card-content {
    padding: 2rem;
    height: 100%;
    width: 1240px;
    overflow-y: scroll;
    box-sizing: border-box;
    background: white;

    .permit_number {
      text-align: right;
      font-size: 16px;
      font-weight: 600;

      .actual_permit-no {
        font-weight: normal;
      }
    }

    .part_section {
      margin-bottom: 2rem;
      .print_title {
        font-size: 20px;
      }

      .contain_section {
        display: flex;
        font-size: 16px;

        .work_location,
        .contact {
          font-weight: 600;
          .line {
            font-weight: normal;
            margin-right: 4rem;
          }
        }
      }

      .work_summary {
        font-weight: 600;
        font-size: 16px;
        .line {
          font-weight: normal;
        }
      }
    }
  }
}

.responding_view {

  .close{
    position: fixed;
    right:3rem;
    top:3rem;
    z-index: 100000;
    background:none;
    outline:none;
    border:none;

    .ti-close{
      font-size: 2rem;
      font-weight: bold;
      color:orange;
    }
  }
  
  .sender_entries {
    padding: 2rem;
    .permit_number {
      text-align: right;
      font-size: 16px;
      font-weight: 600;

      .actual_permit-no {
        font-weight: normal;
      }
    }

    .part_section {
      margin-bottom: 2rem;
      .print_title {
        font-size: 20px;
      }

      .contain_section {
        display: flex;
        font-size: 16px;

        .work_location,
        .contact {
          font-weight: 600;
          .line {
            font-weight: normal;
            margin-right: 4rem;
          }
        }
      }

      .work_summary {
        font-weight: 600;
        font-size: 16px;
        .line {
          font-weight: normal;
        }
      }

     
    }

    
  }

  .hold_btn{
    text-align: center;
    
    .print_report{
      @include submit_button;
      font-size: .8vw;
    }
  }

  .response_container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .button_hold {
    text-align: center;
    padding-top: 4rem;
    .previous {
      @include primary_button;
      cursor: pointer;
      padding: 1rem;
    }

    .submit {
      @include submit_button;
    }
  }

  .validity {
    .hold_date {
      display: flex;

      .from_container {
        display: flex;
        align-items: center;
        font-size: 0.9vw;
        margin-right: 5rem;
        border-radius: 5px;
        padding: 1rem;
        @include shadow;
        background: white;

        .info_input {
          margin-left: 2rem;
        }
      }
    }
  }

  .info_input {
    margin-top: 1rem;
    label {
      display: block;
      font-size: 0.9vw;
    }

    input,
    select,
    textarea {
      @include input;
      height: -moz-fit-content;
      height: fit-content;
      width: 40rem;
    }
  }
}

.green_badge {
  border-left: 3px solid green;
}

.orange_badge {
  border-left: 3px solid orangered;
}

.blue_badge {
  border-left: 3px solid blue;
}
