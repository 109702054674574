/* You can add global styles to this file, and also import other style files */
@import './sass/main.scss';

@import '~@angular/material/prebuilt-themes/indigo-pink.css';
// @import '~font-awesome/css/font-awesome.min.css';
@import './sass/vendors/bootstrap.min.css';

@import './assets/fonts/themify-icons.css';
@import './assets/css/icofont.min.css';
@import './assets/css/applicant_style.css';
@import 'https://fonts.gstatic.com';
@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

@font-face {
  font-family: 'cinderela';
  src: local('Oxygen'),
    url(./assets/fonts/cinderela-font/CinderelaPersonalUseRegular-RDvM.ttf)
      format('truetype');
}

// html,
// body {
//   height: 100%;
//   // overflow: hidden;
//   overflow-x: hidden;
//   color: #343a40;
//   font-size: 62.5%;
// }
// .example-spacer {
//   flex: 1 1 auto;
// }
// body {
//   margin: 0;
//   font-family: Roboto, 'Helvetica Neue', sans-serif;
//   // overflow: hidden;
// }
// .card-diagonal-rounded {
//   box-shadow: 0 20px 10px 0px rgba(0, 0, 0, 0.5);
//   background-color: whitesmoke;
//   border-radius: 0px 15px 0 15px;
// }
// .card {
//   box-shadow: 0 20px 10px 0px rgba(247, 243, 243, 0.904);
//   background-color: white;
//   // border-radius: 0px 15px 0 15px;
// }
// .mat-toolbar-row,
// .mat-toolbar-single-row {
//   padding: 0 !important;
//   margin: 0 !important;
// }

// .mat-drawer:not(.mat-drawer-side) {
//   box-shadow: none !important;
// }

// .side-nav {
//   background-color: transparent !important;
// }

// .mat-drawer-content {
//   overflow-x: hidden !important;
//   // overflow-y: hidden !important;
// }

// .side-nav-content {
//   overflow-x: hidden !important;
//   // overflow-y: hidden !important;
// }
// ::-webkit-scrollbar {
//   width: 10px !important;
//   overflow-y: scroll !important;
//   overflow-x: hidden;
//   background: rgb(214, 214, 214) !important;
// }

// ::-webkit-scrollbar-thumb {
//   background: rgb(136, 136, 136) !important;
//   border-radius: 10px !important;
// }
// .pink_box {
//   margin-right: 30vh;
//   margin-top: 10vh;
// }

// .custom-dialog-container .mat-dialog-container {
//   background: transparent !important;
// }

// .mat-dialog-container {
//   box-shadow: none !important;
//   background-image: initial;
//   background-position-x: initial;
//   background-position-y: initial;
//   background-size: initial;
//   background-repeat-x: initial;
//   background-repeat-y: initial;
//   background-attachment: initial;
//   background-origin: initial;
//   background-clip: initial;
//   // background-color: transparent !important;
// }

// .cdk-overlay-dark-backdrop {
//   background: rgba(0, 0, 0, 0.8);
//   opacity: 0.9;
// }

// .mat_dialog {
//   box-shadow: none !important;
//   background-image: initial;
//   background-position-x: initial;
//   background-position-y: initial;
//   background-size: initial;
//   background-repeat-x: initial;
//   background-repeat-y: initial;
//   background-attachment: initial;
//   background-origin: initial;
//   background-clip: initial;
//   background-color: #d1d2d3 !important;
// }

// .mat-drawer-inner-container {
//   width: 95% !important;
// }

// app-addextraleave .mat-form-field-wrapper {
//   padding-bottom: 1.25em;
//   background-color: white;
//   border-radius: 5px;
//   width: 40vw !important;
// }

// mrsoftebs-slickline-movement-return .mat-radio-label-content {
//   font-size: 10px;
// }

// mrsoftebs-slickline-movement .mat-radio-label-content {
//   font-size: 10px;
// }

.spin {
  position: absolute;
  width: 100%;
  top: 10%;
  background-color: rgba(255, 255, 255, 0.3); /* White background with 30% opacity */
  backdrop-filter: blur(10px); /* Apply a 10px blur to the background */
  color: black; /* Text color for visibility */
  text-align: center; /* Center the text */
  padding: 20px; /* Add some padding */
  box-sizing: border-box; /* Include padding in the element's total width and height */
}