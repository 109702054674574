.policy {
  display: grid;
  grid-template-columns: 0.3fr 1fr;

  .search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include shadow;
    background: $white;
    border-radius: 7px;
    width: 40rem;
    padding: 1rem;
    margin-bottom: 1rem;
    margin-left: 4rem;

    .search_input {
      background: none;
      border: none;
      outline: none;
      font-size: 1.4rem;
      width: 95%;
    }

    i {
      font-size: 1.6rem;
      font-weight: bold;
    }
  }

  .form_holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #eee;
    height: 83vh;
    padding: 3rem 1rem;
    box-sizing: border-box;
    .title {
      margin-top: 0;
      font-size: 1.2vw;
      margin-bottom: 0;
      margin-left: 0;
      padding-left: 0;
      text-align: left;
      padding-top: 0;
    }

    .hold_input {
      // height: 80%;
      // overflow-y: scroll;

      .info_input {
        margin-bottom: 0;
        label {
          display: block;
          font-size: 0.9vw;
        }

        input {
          @include registeration_form(94%);
          height: unset;
        }
      }
    }

    .btn_hold {
      text-align: center;

      .submit {
        @include submit_button;
      }
    }
  }

  .content_right {
    padding: 1rem 2rem;
    .top {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      width: 100%;

      .secondary-btn {
        @include primary_button;
        margin-bottom: 1rem;
        margin-left: 1rem;
        // padding: 0.5rem 1rem;
      }

      .filter {
        display: flex;
        align-items: flex-end;
        width: 30%;
        justify-content: space-between;
        margin-left: 3rem;
        position: relative;

        .the_list {
          position: absolute;
          top: 6rem;
          left: 0;
          background: white;
          font-size: 0.8vw;
          @include shadow;
          width: fit-content;
          width: -moz-fit-content;
          padding: 2rem;
          z-index: 1000;

          .team_member {
            list-style: none;
            &:not(:first-child) {
              margin-top: 1rem;
            }
          }
        }

        .info_input {
          margin-bottom: 0;
          margin-left: 1rem;

          label {
            display: block;
            font-size: 0.9vw;
          }

          input {
            @include registeration_form(94%);
            height: unset;
            margin-top: 0;
          }
        }
      }
    }

    .site_table {
      .the_row {
        display: grid;
        grid-template-columns: 1fr 0.5fr 0.5fr 0.3fr;
        grid-column-gap: 1rem;
        padding: 1rem;

        &:nth-child(odd) {
          background: #f1f1f1;
          box-shadow: 0 2px 1px rgb(216, 216, 216);
        }

        &:first-child {
          font-weight: 600;
        }

        .item {
          font-size: 1.2rem;
        }

        .view_doc {
          background: none;
          outline: none;
          border: none;
          font-size: 1.2rem;
          cursor: pointer;
          color: blue;
        }
      }
    }
  }
}

.hide_element {
  display: none !important;
}

.hide_grid {
  display: block;
}

.the_top {
  display: flex;
  align-items: center;
  background: #eee;
  padding: 1rem;
  margin-bottom: 2rem;

  .title {
    padding: 0;
  }
  .other_links {
    margin-left: 5rem;
    .links {
      @include primary_button;
      font-size: 0.8vw;
      cursor: pointer;
      margin-right: 1rem;

      &:hover {
        color: #ff9000;
      }
    }
  }
}

.team_list {
  position: relative;

  .see_more {
    background: none;
    border: none;
    outline: none;
    color: blue;
    cursor: pointer;
  }

  .the_list {
    position: absolute;
    top: 2rem;
    right: 0;
    background: white;
    font-size: 0.8vw;
    @include shadow;
    width: fit-content;
    width: -moz-fit-content;
    padding: 2rem;
    z-index: 1000;

    .team_member {
      list-style: none;
      &:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }
}
