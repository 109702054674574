.reference_table {
  .table {
    margin: 3rem 0;
    width: 96%;

    .thead,
    .td {
      display: grid;
      grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr;
      padding: 1rem;

      span {
        font-size: 1.4rem;
      }
    }

    .thead {
      margin-bottom: 2rem;
      font-weight: bold;
      background: $table_grey;
      @include dark_shadow;
    }

    .td {
      &:nth-child(even) {
        background: $table_grey;
        @include dark_shadow;
        color: rgb(90, 90, 90);
      }

      span {
        input {
          @include input;
        }
      }
    }
  }
}

.leave_management {
  .reference_table {
    margin-top: 1rem;
    .table {
      margin: 3rem 0;
      width: 96%;

      .thead,
      .td {
        display: grid;
        grid-template-columns: 0.3fr 0.5fr 1.5fr 1fr 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr 0.6fr;
        padding: 1rem;

        span {
          font-size: 1.4rem;
          display: block;
        }
      }

      .thead {
        margin-bottom: 2rem;
        font-weight: bold;
        background: $table_grey;
        @include dark_shadow;
      }

      .td {
        &:nth-child(even) {
          background: $table_grey;
          @include dark_shadow;
          color: rgb(90, 90, 90);
        }

        span {
          input {
            @include input;
          }
        }

        .user_img {
          height: 100%;
          width: 75%;
          border-radius: 50px;
          background: url(/images/user_img/etty.jpg);
          background-size: cover;
          background-position: center;
        }

        .progress {
          background: $green;
          height: 100%;
          width: 75%;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
        }

        .check {
          text-align: right;
        }
      }
    }
  }
}

// my leave==================================================MY LEAVE-====================my leave==============

.leave_management {
  .reference_table-first {
    margin-top: 1rem;
    .my_leave-table {
      margin: 3rem 0;
      width: 96%;

      .thead,
      .td {
        display: grid;
        grid-template-columns: 0.3fr 1fr 1fr 1fr 1fr 0.5fr;
        padding: 1rem;

        span {
          font-size: 1.4rem;
          display: block;
        }
      }

      .thead {
        margin-bottom: 2rem;
        font-weight: bold;
        background: $table_grey;
        @include dark_shadow;
      }

      .td {
        &:nth-child(even) {
          background: $table_grey;
          @include dark_shadow;
          color: rgb(90, 90, 90);
        }

        span {
          input {
            @include input;
          }
        }

        .user_img {
          height: 100%;
          width: 75%;
          border-radius: 50px;
          background: url(/images/user_img/etty.jpg);
          background-size: cover;
          background-position: center;
        }

        .progress {
          background: $green;
          height: 100%;
          width: 75%;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
        }

        .check {
          text-align: right;
        }
      }
    }
  }
}

.leave_management {
  .reference_table {
    margin-top: 1rem;
    .employee_dept {
      margin: 3rem 0;
      width: 96%;

      .thead,
      .td {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding: 1rem;

        span {
          font-size: 1.4rem;
          display: block;
        }
      }

      .thead {
        margin-bottom: 2rem;
        font-weight: bold;
        background: $table_grey;
        @include dark_shadow;
      }

      .td {
        &:nth-child(even) {
          background: $table_grey;
          @include dark_shadow;
          color: rgb(90, 90, 90);
        }
      }
    }
  }
}

.leave_management {
  .reference_table {
    margin-top: 1rem;
    .payroll-table {
      margin: 3rem 0;
      width: 96%;

      .the_payroll {
        display: grid;
        grid-template-columns: 0.3fr 1fr 1.5fr 1fr 1fr 1fr 0.6fr !important;
        padding: 1rem;

        span {
          font-size: 1.4rem;
          display: block;
        }
      }

      .thead {
        margin-bottom: 2rem;
        font-weight: bold;
        background: $table_grey;
        @include dark_shadow;
      }

      .td {
        &:nth-child(even) {
          background: $table_grey;
          @include dark_shadow;
          color: rgb(90, 90, 90);
        }

        .user_img {
          height: 100%;
          width: 30%;
          border-radius: 50px;
          background: url(/images/user_img/etty.jpg);
          background-size: cover;
          background-position: center;
        }
      }
    }
  }
}

.main_info {
  .reference_table {
    margin-top: 1rem;
    .payroll-table {
      margin: 3rem 0;
      width: 96%;
      .history-table {
        display: grid;
        grid-template-columns: 0.5fr 0.8fr 0.6fr 0.6fr 0.8fr 0.5fr 0.4fr !important;
        grid-column-gap: 2px;
        padding: 1rem;

        span {
          font-size: 1.4rem;
          display: block;
        }
      }

      .thead {
        margin-bottom: 2rem;
        font-weight: bold;
        background: $table_grey;
        @include dark_shadow;
      }

      .td {
        &:nth-child(even) {
          background: $table_grey;
          @include dark_shadow;
          color: rgb(90, 90, 90);
        }
      }
    }
  }
}

.reference_table {
  .table {
    .leave__approval {
      display: grid;
      grid-template-columns: 1fr 1.5fr 2fr 1.5fr 1fr !important;
      padding: 1rem;

      span {
        font-size: 1.4rem;
        display: block;
      }
    }

    .leave__approval {
      margin-bottom: 2rem;
      font-weight: bold;
      background: $table_grey;
      @include dark_shadow;
    }

    .leave__approval {
      &:nth-child(even) {
        background: $table_grey;
        @include dark_shadow;
        color: rgb(90, 90, 90);
      }

      .user_img {
        height: 100%;
        width: 30%;
        border-radius: 50px;
        background: url(/images/user_img/etty.jpg);
        background-size: cover;
        background-position: center;
      }
    }
  }
}

.payroll-table {
  margin: 3rem 0;
  width: 96%;

  .the_payroll {
    display: grid;
    grid-template-columns: 0.3fr 1.5fr 1fr 1fr 1fr 1fr 0.6fr;
    padding: 1rem;

    span {
      font-size: 1.4rem;
      display: block;
    }
  }
}

.payroll-table {
  margin: 3rem 0;
  width: 96%;
  .history-table {
    display: grid;
    grid-template-columns: 0.5fr 0.8fr 0.6fr 0.6fr 0.8fr 0.5fr 0.5fr 0.4fr !important;
    grid-column-gap: 2px;
    padding: 1rem;

    span {
      font-size: 1.4rem;
      display: block;
    }
  }

  .thead {
    margin-bottom: 2rem;
    font-weight: bold;
    background: $table_grey;
    @include dark_shadow;
  }

  .td {
    &:nth-child(even) {
      background: $table_grey;
      @include dark_shadow;
      color: rgb(90, 90, 90);
    }
  }
}

.my_leave-plan {
  width: 100%;
  overflow-y: scroll;
  .thead,
  .tbody {
    width: 250%;
    .head {
      display: inline-block;
      font-size: 1.3rem;
      &:not(:first-child) {
        width: 6%;
        padding: 0 2rem;
      }

      &:first-child {
        padding: 0 3rem;
      }
    }
  }

  .thead {
    margin-bottom: 2rem;
    font-weight: bold;
    background: $table_grey;
    @include dark_shadow;
    padding: 1rem 0;
  }
}

.the__training {
  display: grid;
  grid-template-columns: 0.3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 1rem;
  font-size: 1.4rem;

  &:nth-child(odd) {
    background: $table_grey;
  }

  &:first-child {
    font-weight: bold;
  }
}
.reference_closeoutstafftraining {
  display: grid;
  grid-template-columns: 0.3fr 0.8fr 0.8fr 1fr 1fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 0.6fr !important;
  padding: 1rem;
  font-size: 1.4rem;

  &:nth-child(odd) {
    background: $table_grey;
  }

  &:first-child {
    font-weight: bold;
  }
}

.mat-expansion-panel-body {
  overflow: scroll;
}

.the__personal-details {
  width: 200%;

  .thead {
    display: grid;
    grid-template-columns: 0.3fr 0.3fr 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr 0.8fr 0.8fr 0.8fr 1fr 0.5fr;
    padding: 1rem;
    background: grey;
  }

  .tbody {
    display: grid;
    grid-template-columns: 0.3fr 0.3fr 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr 0.8fr 0.8fr 0.8fr 1fr 0.5fr;
    padding: 1rem;

    &:nth-child(odd) {
      background: grey;
    }
  }
}

.the_job--details {
  .grade {
    display: grid;
    grid-template-columns: 0.3fr 0.3fr 1fr 0.8fr 1fr 0.5fr 0.7fr 0.5fr 0.5fr 0.4fr 0.4fr 0.4fr;
    padding: 1rem;

    &:nth-child(odd) {
      background: grey;
    }
  }
}
