.page_title {
  padding-left: 2rem;
  padding-top: 1rem;
}

.appraisal_main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .appraisal_form {
    width: 30%;
    .info_input {
      //   margin-bottom: 1rem;
      margin-left: 1rem;
      label {
        display: block;
        font-size: 0.9vw;
      }

      input,
      select {
        @include registeration_form(100%);
        height: -moz-fit-content;
        height: fit-content;
        font-size: 1vw;
        margin: 0;
      }
    }

    .submit {
      @include submit_button;
      margin-top: 1rem;
    }
  }

  .appraisal_part {
    width: 100%;
    height: 100%;
    display: grid;
    padding: 1rem;
    grid-template-columns: 0.25fr 1fr;
    grid-column-gap: 1rem;

    .staff_data {
      .floating_tag {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 1rem;
        padding-top: 1rem;
        font-size: 1vw;

        .tagger {
          font-size: 0.9vw;
          visibility: hidden;
        }

        &:hover > .tagger {
          visibility: visible;
        }

        .ti-close {
          color: red;
          font-weight: bold;
          cursor: pointer;

          &:hover {
            color: lighten(red, 20%);
          }
        }
      }
      .data {
        font-size: 0.9vw;
        font-weight: bold;
        display: grid;
        grid-template-columns: 0.8fr 1fr;
        // width: 90%;
        span {
          font-weight: 600;
          text-align: left;
        }
      }

      .information_form-one {
        font-size: 0.8vw;
        margin-top: 10rem;
        width: 90%;
        font-weight: 600;

        span {
          display: block;
          margin-top: 4rem;
        }
      }
    }

    .first_section {
      height: 87vh;

      .head_row {
        background: #eee;
        padding: 1rem;
        display: grid;
        grid-template-columns: 1.5fr 0.4fr 0.45fr 0.3fr 0.4fr 0.5fr;
        box-shadow: 0 0 6px rgb(184, 184, 184);

        .head_label {
          font-size: 0.9vw;
          font-weight: 600;
        }
      }

      .appraisal_table {
        // height: 100%;

        .table_row {
          padding: 1rem;
          display: grid;
          grid-template-columns: 1.5fr 0.4fr 0.45fr 0.3fr 0.4fr 0.5fr;
          font-size: 0.9vw;

          &:nth-child(even) {
            background: #eee;
            box-shadow: 0 0 6px rgb(184, 184, 184);
          }

          input {
            @include registeration_form(100%);
            height: -moz-fit-content;
            height: fit-content;
            font-size: 1vw;
            margin: 0;
          }

          .criteria_col {
            display: grid;
            grid-template-columns: 0.7fr 1fr;
          }
          .head_label {
            font-size: 0.9vw;
            font-weight: 600;
          }
        }
      }
      .section_title {
        margin-top: 3rem;
        margin-bottom: 1rem;
        font-size: 1.3vw;
        font-weight: 600;
      }
      .appraisee_section {
        padding-left: 1rem;

        .apprsisee_questions {
          .info_input {
            //   margin-bottom: 1rem;
            margin-left: 1rem;
            label {
              display: block;
              font-size: 0.9vw;
            }

            input {
              @include registeration_form(98%);
              height: -moz-fit-content;
              height: fit-content;
              font-size: 1vw;
              margin: 0;
            }
          }
        }
      }

      .overall_rating {
        padding: 1rem;
        .section_title {
          margin-bottom: 0;
        }

        .prior_info {
          font-size: 0.9vw;
          span {
            font-weight: 600;
            font-style: italic;
            &:first-child {
              color: red;
            }
          }
        }

        .the_ratings {
          // text-align: center;
          span {
            font-size: 0.9vw;
            font-weight: 600;

            &:not(:last-child) {
              margin-right: 3rem;
            }
            input {
              margin-right: 0.5rem;
            }
          }
        }
      }

      .staff_endorsement {
        padding: 1rem;
        .decision {
          display: flex;
          align-items: center;
          font-size: 0.9vw;
          font-weight: 600;

          span {
            margin-right: 1rem;
            display: flex;
            align-items: center;
            font-size: 1vw;

            &:first-child {
              margin-left: 1rem;
            }
            input {
              margin-right: 0.5rem;
            }
          }
        }

        .info_input {
          margin-top: 1rem;
          margin-left: 1rem;
          label {
            display: block;
            font-size: 0.9vw;
          }

          textarea {
            @include registeration_form(98%);
            height: -moz-fit-content;
            height: fit-content;
            font-size: 1vw;
            margin: 0;
          }
        }
      }

      .final_comment {
        padding: 1rem;

        p {
          font-size: 0.9vw;
          margin-bottom: 2rem;
        }

        .info_input {
          margin-top: 1rem;
          margin-left: 1rem;
          label {
            display: block;
            font-size: 0.9vw;
            font-weight: 600;
          }

          textarea {
            @include registeration_form(98%);
            height: -moz-fit-content;
            height: fit-content;
            font-size: 1vw;
            margin: 0;
          }
        }
      }
    }

    .hold_btn {
      text-align: center;
      padding: 1rem 0;
      .submit {
        @include submit_button;
        font-size: 0.8vw;
      }
    }
  }
}

.appraisee_section-view {
  .apprsisee_questions {
    .info_input {
      label {
        font-size: 0.9vw;
        font-weight: 600;
        color: grey;
      }

      p {
        font-size: 1vw;
      }
    }
  }
}

.last_comment-view {
  .the_position {
    font-size: 0.8vw;
    color: grey;
    font-weight: normal;
  }

  .comment {
    font-size: 1.2vw;
    font-weight: 600;
  }
}


