.nav_header {
  width: 100vw;
  height: 100vh;
  position: relative;

  .navigations {
    padding: 1rem 8rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .navigation_logo {
      font-size: 1.7rem;
      font-weight: bold;
      color: rgb(9, 129, 9);
      display: inline-block;
      cursor: pointer;
    }

    .nav_list {
      .nav_links {
        list-style-type: none;
        .nav_link {
          display: inline-block;
          font-size: 1.4rem;
          font-weight: 500;
          cursor: pointer;

          &:not(:last-child) {
            margin-right: 2rem;
          }
        }

        .links {
          padding-left: 4rem;
        }
      }
    }
  }

  .caption {
    padding-left: 8rem;
    padding-top: 8rem;
    .main_caption {
      font-size: 5rem;
      font-weight: bold;
      span {
        display: block;
      }
    }

    .secondary_caption {
      margin-top: 3rem;
      font-size: 1.8rem;
      color: $green;
      font-weight: normal;
    }
  }

  .captions {
    padding-left: 8rem;
    padding-top: 8rem;
    .main_caption {
      font-size: 5rem;
      font-weight: bold;
      span {
        display: block;
      }
    }

    .secondary_caption {
      margin-top: 6rem;
      margin-bottom: 3rem;
      font-size: 1.8rem;
      color: $green;
      font-weight: 500;
    }
  }

  .hero_img {
    background: linear-gradient(to right bottom, $green, $green), url(/images/white-laptop-1181406.jpg);
    width: 60%;
    height: 100vh;
    clip-path: polygon(100% 0, 20% 48%, 100% 100%);
    background-position: center;
    background-blend-mode: overlay;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: -10%;
    right: 0;
    z-index: -1;
  }

  .epims_hero_img {
    background: linear-gradient(to right bottom, $green, $green), url(/images/photo-of-oil-rig-platform-1716008.jpg);
    width: 43%;
    height: 90vh;
    border-radius: 1000px;
    background-position: center;
    background-blend-mode: overlay;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 8%;
    right: 8%;
    z-index: -1;
  }

  .small_balls {
    width: 15%;
    position: absolute;
    z-index: -10;
  }

  .right {
    top: 7%;
    right: 40%;
  }

  .left {
    bottom: 0;
    right: 3%;
  }

  .spans {
    background: url(/images/top_curve.svg);
    height: 30vh;
    width: 100%;
    position: absolute;
    bottom: 10%;
    left: 0;
    background-position: center;
    background-size: cover;
    z-index: -2;
  }
}

.home_header {
  height: 100vh;
}

.home_header .slide {
  height: 100%;
}

.carousel-inner {
  height: 100vh;
}

.carousel-item {
  height: 100%;

  position: relative;
}

.carousel-item::after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
  height: 995vh;
  width: 100%;
  z-index: 5;
  content: '';
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 40%;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff !important;
  text-align: center;
  z-index: 300;

  h5 {
    font-size: 5vw;
    color: white !important;
    font-weight: 500;
  }

  p {
    font-size: 1.2vw;
    font-style: italic;
    text-align: center !important;
    color: white !important;
  }
}

.carousel-control-prev,
.carousel-control-next {
  border: none;
  background: none;
  outline: none;
  z-index: 1000;
}

.carousel_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.navbar {
  .navbar-nav {
    .nav-item {
      .nav-link {
        font-size: 1vw;
      }
    }
  }
}
