.id_card-module {
  

  .module_title {
    font-size: 1.3vw;
    padding: 1rem 2rem;
  }
  .id_card-capture {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 2rem;


    .top_part{
      width:100%;
      display:flex;
      justify-content: space-between;
      align-items: center;
      padding:1rem 2rem;

      .back {
        @include primary_button;
        font-size: 0.8vw;
        padding: 0.5rem 1rem;
        background: rgb(44, 44, 44);
        color: rgb(194, 194, 194);
      }
    }

    

    .passport_container {
      position: relative;
      height: fit-content;
      height: -moz-fit-content;

      .passport {
        width: 300px;
        height: 320px;
        object-fit: cover;
        object-position: top;
        cursor: pointer;
      }

      .give_info {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, 50%);
        font-size: 1vw;
        font-weight: 600;
        background: none;
        outline: none;
        border: none;
        color: black;

        i {
          margin-right: 0.5rem;
        }

        &:hover {
          color: grey;
        }
      }
    }

    .form_container {
      margin-left: 4rem;
      .info_input {
        margin-bottom: 0;
        label {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          font-size: 0.9vw;

          span {
            font-size: 0.6vw;
            text-transform: uppercase;
            color: red;
          }
        }

        input,
        select {
          @include registeration_form(40rem);
          height: unset;
        }
      }

      .submit {
        @include submit_button;
        margin-bottom: 2rem;
        font-size: 0.8vw;
      }
    }
  }
}

.filter-search {
  display: flex;
  align-items: center;
  padding-top: 2rem;

  .search_container {
    width: 25vw;
    position: relative;

    &:not(:last-child) {
      margin-right: 2rem;
      margin-left: 20rem;
    }

    .department_list {
      width: 100%;
      display: flex;
      align-items: flex-start;
      border-radius: 10px;
      box-shadow: 0 0 20px rgb(156, 156, 156);
      box-sizing: border-box;
      left: 0;
      top: 5rem;
      z-index: 1000;
      .ti-close {
        font-size: 0.9vw;
        width: 2%;
        font-weight: bold;
        margin-right: 0.5rem;
        color: red;
        cursor: pointer;

        &:hover {
          transform: scale(1.2);
        }
      }

      .dept {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 98%;

        .department-name {
          font-size: 0.8vw;
          font-weight: 600;
          color: rgb(143, 143, 143);
          cursor: pointer;
          margin: 0.5rem;

          &:not(:last-child) {
            &::after {
              content: '/';
              margin-left: 1rem;
              color: black;
            }
          }

          &:hover {
            color: rgb(51, 51, 51);
          }
        }
      }
    }
  }
}

.id_card-management {
  height: 72vh;
  overflow-y: scroll;
  .staff_profile-card {
    padding: 0 2rem;
    margin: 3rem 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1rem;
    width: 100%;
    box-sizing: border-box;
    .profile-card {
      background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.459), rgb(0, 0, 0)), url(/images/user_img/adult-contemporary-designer-suit-1405963.jpg);
      background-size: cover;
      background-position: top;
      @include shadow;
      height: 34vh;
      width: 100%;
      border-radius: 7px;
      position: relative;

      .the_options {
        display: flex;
        justify-content: flex-end;
        padding: 1rem;
        .ti-more-alt {
          font-size: 1vw;
          color: black;
          cursor: pointer;

          &:hover {
            color: orange;
          }
        }
      }

      .detail {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.685);
        padding-top: 1rem;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        .name {
          font-size: 1.2vw;
          color: orange;
          text-align: center;
        }

        .position {
          font-size: 1.3rem;
          color: lightgrey;
          text-align: center;
        }

        .buttons {
          display: flex;
          justify-content: space-around;
          margin: 1rem 0;

          .icon_btn {
            background: none;
            outline: none;
            border: none;
            cursor: pointer;

            i {
              color: lightgrey;
              font-size: 1vw;
              font-weight: bold;

              &:hover {
                color: orange;
              }
            }
          }
        }
      }
    }
  }
}

.hold_btn {
  text-align: center;

  .submit {
    @include submit_button;
    font-size: 0.8vw;
  }
}

// HMO MODULE=========================HMO MODULE==========================HMO MODULE===================HMO MODULE

.hmo_module {
  padding: 2rem;
  height: 100%;
  width: 1240px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .top_part{
    width:100%;
    display:flex;
    justify-content: space-between;
    padding:1rem;
  
    .back{
      @include primary_button;
      padding:.5rem 1rem;
      background:grey;
      color:white;
      font-size: .8vw;
    }
  }

  .passport_holder {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 3rem;
    height: 20rem;
    width: 100%;

    .passport_container {
      height: 100%;
      .hmo_passport {
        height: 70%;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }

      .name {
        font-size: 0.8vw;
      }
    }
  }

  .medical_form {
    width: 100%;
    margin-top: 4rem;
    .top_form {
      width: 100%;
      .first_form {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .input {
          width: 33%;
          display: flex;

          label {
            font-size: 0.9vw;
            font-weight: 600;
          }

          input {
            font-size: 1vw;
            padding: 0 1rem;
            border: none;
            background: none;
            border-bottom: 1px solid #000;
            width: 65%;
          }
        }
      }

      .ticking_section {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        .tick {
          text-align: center;
          border: 1px dashed grey;
          padding: 1rem 4rem;
          &:not(:last-child) {
            margin-right: 6rem;
          }

          .info {
            font-size: 0.9vw;
            font-weight: 700;
          }

          span {
            font-size: 0.9vw;
            &:not(:last-child) {
              margin-right: 2rem;
            }
          }
        }
      }
    }

    .person_capture {
      width: 100%;

      .parent {
        width: 100%;
        margin-top: 2rem;
        .combine {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }

        .parent_address {
          width: 100%;
          margin-bottom: 1rem;
          margin-top: 2rem;

          label {
            font-size: 0.9vw;
            width: -moz-fit-content;
            width: fit-content;
            font-weight: 600;
          }

          input {
            font-size: 1vw;
            padding: 0 1rem;
            width: 84.8%;
            border: none;
            background: none;
            border-bottom: 1px solid #000;
          }
        }
      }

      .dependants {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 2rem 0;
      }

      .person {
        display: flex;
        flex-direction: column;

        .the_head {
          font-size: 1vw;
          color: rgb(255, 38, 0);
          font-weight: 600;
          margin-bottom: 2rem;
        }

        span {
          width: 100%;
          margin-bottom: 1rem;

          label {
            font-size: 0.9vw;
            width: 20%;
            font-weight: 600;
          }

          input {
            font-size: 1vw;
            padding: 0 1rem;
            width: 70%;
            border: none;
            background: none;
            border-bottom: 1px solid #000;
          }
        }

        .split {
          display: grid;
          grid-template-columns: 1fr 1fr;

          .marital {
            width: 100%;

            label {
              font-size: 0.9vw;
              width: 40%;
            }

            input {
              font-size: 1vw;
              padding: 0 1rem;
              width: 50%;
            }
          }

          .gender {
            width: 100%;

            label {
              font-size: 0.9vw;
              margin-right: 1rem;
            }

            input {
              font-size: 1vw;
              padding: 0 1rem;
              margin-right: 0.9vw;
            }
          }
        }
      }
    }

    .hm_down {
      .affirmation {
        font-size: 0.9vw;
      }

      .signature {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        span {
          width: 100%;
          margin-bottom: 1rem;

          label {
            font-size: 0.9vw;
            width: -moz-fit-content;
            width: fit-content;
            font-weight: 600;
          }

          input {
            font-size: 1vw;
            padding: 0 1rem;
            width: 51%;
            border: none;
            background: none;
            border-bottom: 1px solid #000;
          }
        }
      }
    }
  }
}
