.staff_onboard {
  text-align: center;
  .title {
    @include heading;
  }

  .basic_info,
  .contact_info {
    text-align: left;
    padding-left: 20rem;
    .heading {
      font-size: 2rem;
      font-weight: 500;
      margin: 2rem 0;
      border-bottom: 2px solid $green;
      display: inline-block;
    }
  }
}
