.main_dashboard {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.5rem 1rem;

  .dash_card {
    background: rgb(252, 252, 252);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 19%;
    height: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 5px;
    margin-top: 1rem;

    .card_title {
      padding-top: 1rem;
      color: black;
    }

    .other_param {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .info_link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgb(219, 219, 219);
        font-size: 0.9vw;
        font-weight: 600;
        width: 49.7%;
        padding: 0.3rem 0;
        cursor: pointer;

        &:hover {
          background: rgb(0, 17, 71);
          color: white;
        }

        span {
          font-size: 1.3vw;
        }
      }
    }
  }

  .filter_sec {
    display: flex;
    align-items: flex-end;

    .info_input {
      //   margin-bottom: 1rem;
      margin-left: 1rem;
      margin-bottom: 0;
      label {
        display: block;
        font-size: 0.9vw;
      }

      input,
      select {
        @include registeration_form(fit-content);
        height: -moz-fit-content;
        height: fit-content;
        font-size: 1vw;
        margin: 0;
      }

      select {
        width: 20rem;
      }
    }

    .search_input {
      @include registeration_form(30rem);
      height: fit-content;
      height: -moz-fit-content;
      display: flex;
      align-items: center;
      margin: 0;
      i {
        margin-right: 0.5rem;
      }

      input {
        background: none;
        outline: none;
        border: none;
      }
    }

    .filter {
      @include primary_button;
      font-size: 0.9vw;
      margin-left: 2rem;
    }
  }
}

.table_container {
  padding: 1rem;

  .table_data {
    display: grid;
    grid-template-columns: .2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 1rem;

    &:nth-child(odd) {
      background: #999999;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      color:white;
    }

    &:first-child {
      font-weight: 600;
      color: white;
      background: #494949;
    }

    .table_item {
      font-size: 0.9vw;
    }
  }

  .no_check {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 1rem;

    &:nth-child(odd) {
      background: #999999;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      color:white;
    }

    &:first-child {
      font-weight: 600;
      color: white;
      background: #494949;
    }

    .table_item {
      font-size: 0.9vw;
    }
  }
}


.info_input {
  //   margin-bottom: 1rem;
  margin-left: 1rem;
  margin-bottom: 0;
  label {
    display: block;
    font-size: 0.9vw;
  }

  input,
  select,textarea {
    @include registeration_form(fit-content);
    height: -moz-fit-content;
    height: fit-content;
    font-size: 1vw;
    margin: 0;
  }

  select {
    width: 20rem;
  }
}


.row_popup{
  position:fixed;
  background: rgba(0, 0, 0, 0.623);
  height:100vh;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top:0;
  left:0;

  .row_selection-card{
    background:white;
    box-shadow: 0 0 5px grey;
    width:90%;
    height:90%;


    .row_info{
      background:#ccc;
      display:grid;
      grid-template-columns: repeat(3, 1fr );
      padding:1rem;
      position: relative;

      .ti-close{
        position: absolute;
        right:1rem;
        top:1rem;
        font-size: 1.2vw;
        cursor:pointer;
        color:red;

        &:hover{
          transform: scale(1.5);
        }
      }

      .the_info{
        display: flex;
        flex-direction: column;

        .table_item{
          font-size: .8vw;
          font-weight: normal;
        }

        .answer{
          font-size: 1vw;
          font-weight: 500;
        }
      }
    }

    .the_title{
      text-align: center;
      padding:1rem;
    }
    .other_information{
      display:grid;
      grid-template-columns: repeat(3, 1fr );
      padding:1rem;

      .relative{
        display: flex;
        flex-direction: column;

        .heading-span{
          font-size: .8vw;
          font-weight: normal;
        }

        .data{
          font-size: 1vw;
          font-weight: 500;
        }
      }
    }


    .chats{
      height:unset;
      .preview {
        width: 100%;
        height: 25vh;
        overflow-y: scroll;

        .old{
          height: unset;
          overflow: unset;
          padding:0 1rem;



          .message_bubble .message {
            font-size: 1.2rem;
            background: white;
            box-shadow: 0 0 15px #d4d4d4;
            height: fit-content;
            height: -moz-fit-content;
            padding: 1rem;
            width: fit-content;
            border-radius: 10px;
        }

        .receiver{
          justify-content: flex-start !important;
          p{
            margin:0;
          }
        }

        .sender .message {
          background: #464646;
          color: white;
          margin-right: 1rem;

          p{
            margin-bottom: 0;
          }
      }
        }



    }
    }


  }
}

.hold_btn{
  display:flex;
  align-items: center;
  justify-content: center;

  textarea{
    width:30rem;
    height:4rem;
  }

  .approve_button{
    @include submit_button;
    padding:1rem;
    margin:0 1rem;
  }

  .reject_button{
    @include primary_button;
    padding: 1rem;
  }
}

