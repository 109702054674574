.new__setting {
  @include input;
  height: fit-content;
  margin-bottom: 3rem;

  input {
    border: none;
    font-size: 1.3rem;
    outline: none;
    height: auto;
  }
}

.the__contract {
  margin-bottom: 3rem;

  .thead {
    display: grid;
    grid-template-columns: 0.2fr 0.3fr 1fr 0.3fr 0.3fr 0.5fr 0.3fr 0.3fr 0.3fr 0.3fr 0.2fr 0.1fr 0.3fr 0.3fr;
    padding: 1rem;
    background: grey;
  }

  .tbody {
    display: grid;
    grid-template-columns: 0.2fr 0.3fr 1fr 0.3fr 0.3fr 0.5fr 0.3fr 0.3fr 0.3fr 0.3fr 0.2fr 0.1fr 0.3fr 0.3fr;
    padding: 1rem;

    &:nth-child(odd) {
      background: grey;
    }
  }
}

.the__contractor {
  margin-bottom: 3rem;

  .thead {
    display: grid;
    grid-template-columns: 0.3fr 1fr 1fr 1fr 1fr 1fr;
    padding: 1rem;
    background: grey;
  }

  .tbody {
    display: grid;
    grid-template-columns: 0.3fr 1fr 1fr 1fr 1fr 1fr;
    padding: 1rem;

    &:nth-child(odd) {
      background: grey;
    }
  }
}
