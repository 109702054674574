.cert_permit-module {
  width: 100%;
  height: 100%;
  .preview_enteries {
    width: 100%;
    .top_previews {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 2rem 0;

      .preview_card {
        width: 24rem;
        height: 11rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background: white;
        @include shadow;
        border-radius: 10px;
        overflow: hidden;

        &:not(:last-child) {
          margin-right: 2rem;
        }

        .label {
          height: 50%;
          display: flex;
          align-items: center;
          font-size: 1.5vw;
          font-weight: 500;
          color: rgb(39, 39, 39);
        }

        .buttons {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 50%;

          .content-display {
            width: 49.5%;
            border: none;
            outline: none;
            font-size: 1vw;
            cursor: pointer;
            color: rgb(36, 36, 36);

            span {
              color: white;
              font-size: 1.2vw;
            }
          }

          .yellow {
            background: rgb(255, 174, 0);

            &:hover {
              background: darken(rgb(255, 174, 0), 3%);
            }
          }

          .red {
            background: rgb(255, 77, 12);

            &:hover {
              background: darken(orangered, 3%);
            }
          }

          .green {
            background: teal;

            &:hover {
              background: darken(teal, 3%);
            }
          }
        }
      }
    }

    .records {
      padding: 0 3rem;
      position: relative;

      .record_top {
        display: flex;
        justify-content: center;

        .total_record {
          font-size: 1.1vw;

          .number_recorded {
            font-weight: 500;
          }
        }

        .search_container {
          margin: 0 20rem;
          width: 25vw;
          position: relative;

          .department_list {
            width: 100%;
            display: flex;
            align-items: flex-start;
            border-radius: 10px;
            box-shadow: 0 0 20px rgb(204, 204, 204);
            box-sizing: border-box;
            left: 0;
            top: 5rem;
            z-index: 1000;
            .ti-close {
              font-size: 0.9vw;
              width: 2%;
              font-weight: bold;
              margin-right: 0.5rem;
              color: red;
              cursor: pointer;

              &:hover {
                transform: scale(1.2);
              }
            }

            .dept {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              width: 98%;

              .departments {
                font-size: 0.8vw;
                font-weight: 600;
                color: rgb(143, 143, 143);
                cursor: pointer;
                margin: 0.5rem;

                &:not(:last-child) {
                  &::after {
                    content: '/';
                    margin-left: 1rem;
                    color: black;
                  }
                }

                &:hover {
                  color: rgb(51, 51, 51);
                }
              }
            }
          }
        }

        .add {
          @include primary_button;
          font-size: 0.8vw;
          padding: 1rem;
          cursor: pointer;
        }
      }

      .record_table {
        padding: 2rem 0;
        .record_row {
          display: grid;
          grid-template-columns: 0.3fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr .3fr;
          border-bottom: 1px solid rgb(202, 202, 202);
          padding: 1rem;

          &:first-child {
            background: #eee;
            box-shadow: 0 0 5px rgb(202, 202, 202);
          }

          .data_item {
            font-size: 0.9vw;
          }
        }
      }

      .new_capture {
        position: fixed;
        background: rgba(0, 0, 0, 0.925);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .new_cert-form {
          height: fit-content;
          height: -moz-fit-content;
          background: white;
          width: 50%;
          box-shadow: 0 0 10px rgb(0, 0, 0);
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 7px;
          padding:1rem;

          .close {
            width: 100%;
            height: fit-content;
            height: -moz-fit-content;
            text-align: right;
            padding: 1rem 2rem;
            box-sizing: border-box;

            .ti-close {
              font-size: 1vw;
              color: red;
              cursor: pointer;
            }
          }

          .search_container {
            margin: 0 20rem;
            width: 40rem;
            position: relative;

            .department_list {
              width: 100%;
              display: flex;
              align-items: flex-start;
              border-radius: 10px;
              box-shadow: 0 0 20px rgb(204, 204, 204);
              box-sizing: border-box;
              left: 0;
              top: 5rem;
              z-index: 1000;
              .ti-close {
                font-size: 0.9vw;
                width: 2%;
                font-weight: bold;
                margin-right: 0.5rem;
                color: red;
                cursor: pointer;

                &:hover {
                  transform: scale(1.2);
                }
              }

              .dept {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: 98%;

                .departments {
                  font-size: 0.8vw;
                  font-weight: 500;
                  color: rgb(143, 143, 143);
                  cursor: pointer;
                  margin: 0.5rem;

                  &:not(:last-child) {
                    &::after {
                      content: '/';
                      margin-left: 1rem;
                      color: black;
                    }
                  }

                  &:hover {
                    color: rgb(51, 51, 51);
                  }
                }
              }
            }
          }

          .notification_sec {
            width: 40rem;
            display: flex;
            justify-content: space-between;

            .info_input {
              margin-top: 1rem;

              label {
                display: block;
                font-size: 0.9vw;
              }

              input,
              select {
                @include input;
                height: -moz-fit-content;
                height: fit-content;
                width: 15rem;
              }
            }
          }

          .info_input {
            margin-top: 1rem;
            label {
              display: block;
              font-size: 0.9vw;
            }

            input,
            select {
              @include input;
              height: -moz-fit-content;
              height: fit-content;
              width: 40rem;
            }
          }

          .hold_submit {
            margin-top: 2rem;
            .submit {
              @include submit_button;
              padding: 1rem;
              font-size: 0.8vw;
            }
          }
        }
      }
    }
  }
}
.displayNewPopUp{
  display:none !important;
}