.main {
  .employee_management {
    .heading {
      font-size: 1.8rem;
      font-weight: normal;
      // margin-top: 2rem;
    }

    .top {
      // width: 100%;
      width: 40%;

      .right_part {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .hold_btn {
          width: 30%;

          .top_button {
            font-size: 0.8vw;
          }
        }
        .search {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 2rem;
          @include search;
          width: 70%;
          height: 4rem;
          padding: 0 0.5rem;

          #nav_search {
            border: none;
            background: none;
            width: 80%;
            height: 100%;
            outline: none;
          }

          .search_icon {
            img {
              margin-bottom: -0.5rem;
              width: 2rem;
            }
          }
        }
      }

      .search {
        display: inline-block;
      }
    }
  }
}

.staff_profile {
  .the_top {
    display: flex;
    align-items: center;
    background: #eee;
    padding: 1rem;
    margin-bottom: 2rem;

    .title {
      display:unset;
      padding: 0;
    }
    .other_links {
      margin-left: 5rem;
      .links {
        @include primary_button;
        font-size: 0.8vw;
        padding:1rem;
        cursor: pointer;
        margin-right: 1rem;

        &:hover {
          color: #ff9000;
        }
      }
    }
  }

  .first_capture {
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    grid-column-gap: 2rem;
    padding: 0 2rem;

    .image {
      width: 100%;
      height: 30vh;
      background-size: cover;
      background-position: center;
      border-radius: 10px;
      background-image: url(/images/user_img/etty.jpg);
      // position: absolute;
      // top: 21%;
      // left: 50%;
      // transform: translate(-50%, -50%);
    }

    .top_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 1rem;
      margin-bottom: 2rem;
      .title {
        display:unset;
        font-size: 1.4vw;
        padding: 0;
      }

      .generate {
        @include primary_button;
        font-size: 0.8vw;
        margin-left: 1rem;
        cursor: pointer;
      }
    }

    .staff_info {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 2rem;

      .info_input,
      .first_point {
        margin: 1rem 0;
        label {
          display: block;
          font-size: 1.3rem;
          font-weight: 500;
        }

        input,
        select {
          @include input;
          width: 90%;
          font-size: 1.3rem;
        }

        select {
          @include input;
          width: 90%;
          font-size: 1.3rem;
        }
      }
    }
  }

  .adjust_elements {
    padding: 2rem;
    margin-bottom: 4rem;
  }
  .second_capture {
    background: $white;
    @include shadow;
    width: 100%;
    // padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 10px;
  }
  .nav-pills {
    display: flex;
    justify-content: center;
    background: black;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 1rem 0;
    .nav-item {
      .nav-link {
        color: $white;
        font-size: 1.4rem;
        position: relative;
        // font-weight: bold;

        &::after {
          content: '';
          display: block;
          height: 5px;
          width: 5px;
          border-radius: 30px;
          background-color: $green;
          position: absolute;
          left: 50%;
          visibility: hidden;
        }
        &:hover,
        &:active,
        &:focus {
          background: none !important;
          color: teal;

          &::after {
            visibility: visible;
          }
        }
      }
    }
  }

  .info_card {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 2rem;

      .title {
        display:unset;
        font-size: 1.2vw;
        color: $green;
      }

      .add_edu {
        @include primary_button;
        // width: 30%;
        font-size: 0.8vw;
        font-weight: 500;
        cursor: pointer;
      }
    }

    .main_info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 2rem;
      padding: 0 2rem;
      .input_container {
        margin: 1rem 0;
        label {
          display: block;
          font-size: 1.3rem;
          font-weight: 500;
        }

        input,
        select {
          @include input;
          width: 98%;
          font-size: 1.3rem;
        }
      }

      p {
        span {
          display: block;
        }
        .degree {
          font-size: 1.5rem;
          color: $green;
          font-weight: 500;
        }

        .school {
          font-weight: 500;
          font-size: 1.5rem;
        }

        .duration {
          color: grey;
          font-weight: normal;
          font-size: 1.4rem;
        }
      }

      .emergency {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .contact {
          .first_grid,
          .second_grid {
            .contact_name {
              font-size: 1.5rem;
              color: $green;
              font-weight: 500;
            }

            .icons {
              .ti-pencil {
                font-size: 1.8rem;
                color: $green;
                margin-bottom: -2rem;
                padding: 1rem;
                cursor: pointer;
              }
            }
          }
        }

        .contact_information {
          font-size: 1.2rem;
        }
      }
    }

    .relation {
      display: block;

      .emergency {
        .contact {
          .first_grid {
            label {
              font-size: 1.3rem;
              font-weight: 500;
            }
            input,
            select {
              @include input;
              font-size: 1.3rem;
            }

            .others {
              width: 90%;
            }
          }
        }
      }
    }

    .personal_data {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      .label {
        font-size: 1.5rem;
        font-weight: 500;
      }

      .answer {
        font-size: 1.4rem;
        font-weight: normal;
        color: grey;
      }
    }
  }

  .final {
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    // margin-top: 3rem;
    .submit_button {
      @include submit_button;
      font-size: 0.8vw;
    }
  }
}

.work {
  .experience {
    grid-column-gap: 2rem;
    grid-row-gap: 4rem;
    .contact {
      .first_grid,
      .second_grid {
        display: block !important;
      }

      .others {
        label {
          width: 80%;
          font-size: 1.4rem;
        }

        input,
        select {
          @include input;
        }

        select {
          width: 100%;
          font-size: 1.3rem;
        }
      }
    }
  }
}
