.reference_table, .reference_table-first {
  .top {
    &_button {
      @include primary_button;
      cursor: pointer;
    }

    .ti-plus {
      color: $green;
      font-weight: bold;
      padding-left: 2rem;
    }
    .ti-filter {
      color: $orange;
      font-weight: bold;
      padding-left: 2rem;
    }
  }

  .table {
    .submit {
      text-align: center;
      margin: 3rem 0;

      .submit_button {
        @include submit_button;
      }
    }
  }
}

.final{
  text-align:center;
  .submit_button{
    @include submit_button;
    font-size:1.3rem;
  }
}

.basic_info,
.contact_info {
  #basic_info,
  #contact {
    .buttons {
      width: 50%;
      text-align: center;
      .stop {
        @include secondary_button;
      }

      .go {
        @include submit_button;
      }
    }
  }
}

.employee_management {
  .top {
    &_button {
      @include primary_button;
      cursor: pointer;
    }

    .ti-filter {
      color: $orange;
      font-weight: bold;
      padding-left: 2rem;
    }
  }
}

.employee_profile {
  .icons {
    .chat,
    .edit {
      display: inline-block;
      position: absolute;
      z-index: 1;
      button {
        font-size: 1.3rem;
        background: none;
        border: none;
        color: $white;
      }
    }

    .edit {
      right: 2rem;
      top: 1rem;
    }

    .chat {
      left: 2rem;
      top: 1rem;
    }
  }
}

.employee_profile {
  .final {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
    .submit_button {
      @include submit_button;
      font-size: 1.3rem;
    }
  }
}


.the__action_btn {
  padding-left: 8rem;
  padding-top: 3rem;

  .the_first-btn {
    @include secondary_button;
    font-size: 1.5rem;
    font-weight: normal;
  }

  .the_second-btn {
    @include submit_button;
    font-size: 1.5rem;
    font-weight: normal;
  }
}
.the__input-button{
  text-align: center;
  width: 100%;
  .leave_dialogue-submit{
    @include submit_button;
    padding:.5rem 1.5rem !important;
  }
}

.contract_white{
  @include primary_button;
  padding:.8rem 1.5rem;
  margin-right: 2rem;
}


.contract_btn-container{
  width:100%;
  text-align: center;

  .contract_button{
    @include submit_button;
    padding:1rem 2rem;
  }
}
