.modal-bg {
  background: rgba(0, 0, 0, 0.849);
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  
  .the_card {
    width: 80%;
    background: white;
    box-shadow: 0 0 20px black;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .card_top {
      padding: 1rem;
      background: #eee;
      text-align: right;
      width: 100%;
      .close_modal {
        background: none;
        outline: none;
        border: none;
        color: red;

        &:hover {
          transform: scale(1.5);
        }
        i {
          font-size: 1.2vw;
        }
      }
    }

    .meeting_form {
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-around;
      width: 100%;
      // align-items: flex-end;
      padding: 1rem 3rem;

      .first_row{
        display:flex; justify-content: space-around;
      }

      .second_row{
        width:100%;
        display:grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
      }

      .third_row{
        width:100%;
        display:flex;
        
      }

      .info_input {
        
        margin-bottom: 1rem;
        
        label {
          display: block;
          font-size: 0.9vw;
          color:black !important;
        }
    
        input,
        select,
        textarea {
          @include registeration_form(fit-content);
          height: -moz-fit-content;
          height: fit-content;
          font-size: 1vw;
          margin: 0;
        }
    
        
      }

      // input {
      //   width: 100%;
      //   font-size: 1vw;
      //   border: none;
      //   outline: none;
      //   margin-bottom: 1rem;
      //   @include input;
      // }

      
    }

    .modal_menu {
      text-align: left;

      .other_menu {
        @include primary_button;
        font-size: 0.8vw;
        padding: 1rem;
        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }

    .other_modal-info {
      width: 100%;

      .actions{
        display: flex;
        align-items: center;
        padding-left: 3rem;
      }
      .meeting_time {
        display: flex;
        padding-left: 2rem;
        .ti-time {
          font-size: 1.2vw;
          font-weight: bold;
          margin-right: .5rem;
        }

        .time {
          font-size: 0.9vw;
          font-weight: bold;
        }
      }

      .add_guests,
      .location {
        font-size: 0.9vw;
        font-weight: 600;
        padding-left: 2rem;
        border: none;
        outline: none;
        background: none;
        margin-bottom: 0;
        color: rgb(1, 67, 129);
        display: flex;
        align-items: center;

        &:hover {
          color: lighten(rgb(1, 67, 129), 40);
        }
        i {
          font-size: 1vw;
          margin-right: .5rem;
          font-weight: bold;
        }
      }

      .button_hold {
        padding: 2rem;
        margin-top: 1rem;
        text-align: center;
        width: 100%;

        .save {
          @include submit_button;
          font-size: 0.8vw;
        }
      }
    }
  }
}


.btn_set{
  .toggle_btn{
    @include primary_button;
    padding:1rem;
    margin-left: 1rem;
  }
}

.create_meeting{
  @include primary_button;
    padding:1rem;
    margin-left: 1.5rem;
}

