.grid_container {
  display: grid;
  grid-template-columns: 0.3fr 1fr;

  .form_holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #eee;
    height: 92vh;
    padding: 1rem;
    box-sizing: border-box;
    .title {
      font-size: 1.2vw;
      margin-bottom: 2rem;
      margin-top: 2rem;
      margin-left: 0;
      padding-left: 0;
      text-align: left;
    }

    .hold_input {
      height: 86%;

      .info_input {
        margin-bottom: 1rem;
        label {
          display: block;
          color: black !important;
          font-size: 0.8vw;
        }

        input,select {
          @include registeration_form(94%);
          height:unset;
          margin:0;
        }
      }

      .checker {
        margin-bottom: 2rem;
        padding: 0 3rem;
        text-align: right;
        font-size: 0.9vw;
      }

      
    }

    .btn_hold {
      text-align: center;

      .submit {
        @include submit_button;
        padding: 1rem;
        font-size: .8vw;
      }
    }
  }

  .content_right {
    padding: 1rem 2rem;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .title {
        padding: 0;
        margin: 0;
      }
      .secondary-btn {
        @include primary_button;
        margin-bottom: 1rem;
      }
    }

    .tender_table {
      .the_row {
        display: grid;
        grid-template-columns: 0.2fr 0.5fr 1fr 0.5fr 0.7fr 1fr 0.5fr 0.1fr;
        align-items: center;
        padding: 1rem;

        &:nth-child(odd) {
          background: #f1f1f1;
          box-shadow: 0 2px 1px rgb(216, 216, 216);
        }

        .item {
          font-size: 0.9vw;
        }

        i {
          font-size: 1.2vw;
          color: red;
          cursor: pointer;
        }
      }
    }
  }
}

.hide_element {
  display: none !important;
}

.hide_grid {
  display: block;
}
