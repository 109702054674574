.inventory_module {
  display: grid;
  grid-template-columns: 0.3fr 1fr;

  .form_holder {
    display: flex;
    flex-direction: column;
    justify-content: unset !important;
    background: #eee;
    height: 83vh;
    padding: 3rem 1rem;
    box-sizing: border-box;
    .title {
      margin-top: 0;
      font-size: 1.2vw;
      margin-bottom: 0;
      margin-left: 0;
      padding-left: 0;
      text-align: left;
      padding-top: 0;
    }

    .hold_input {
      // height: 80%;
      // overflow-y: scroll;

      .info_input {
        margin-bottom: 0;
        label {
          display: block;
          font-size: 0.9vw;
        }

        input {
          @include registeration_form(94%);
        }
      }
    }

    .btn_hold {
      text-align: center;

      .submit {
        @include submit_button;
      }
    }
  }

  .content_right {
    padding: 1rem 2rem;

    .top_information-sec {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      .memo_preview {
        width: 25rem;
        height: 8rem;
        box-shadow: 0 0 8px #cecece;
        background: white;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        margin-right: 1rem;

        .notice {
          height: 20rem;
          width: 20rem;
          background: $employee_profile-color;
          position: absolute;
          top: -50%;
          left: -25%;
          z-index: 0;
          border-radius: 100px;
        }

        .card_title {
          color: rgb(255, 255, 255);
          font-size: 1vw;
          text-align: center;
          z-index: 2;
          width: 50%;
        }

        .total_num {
          text-align: right;
          font-size: 2.5vw;
          font-weight: normal;
        }
      }

      .button_hold {
        margin-left: 2rem;
        display: flex;
        position: relative;

        .from_to{
          display:flex;
          justify-content: space-around;
          position: absolute;
          width:fit-content;
          width:-moz-fit-content;
          left:0;
          top:4rem;
          z-index: 1000;
          background:white;
          box-shadow: 0 0 8px #eee;
          padding:1rem;

          .info_input{
            margin-bottom: 0;
            label{
              display:block;
              font-size: .8vw;
            }

            input{
              @include input;
            }
          }
        }


        .filter_com {
          position: relative;
          .filter_options {
            position: absolute;
            display: flex;
            flex-direction: column;
            background: white;
            box-shadow: 0 0 6px #cecece;
            width: 100%;
            // display: none;

            .filter_btn {
              background: none;
              border: none;
              outline: none;
              font-size: 0.8vw;
              padding: 1rem;

              &:not(:last-child) {
                border-bottom: 1px solid rgb(224, 224, 224);
              }

              &:hover {
                color: grey;
              }
            }
          }
        }

        .filter,
        .new {
          @include primary_button;
          font-size: 0.8vw;
          padding: 1rem;
          margin-right: 1rem;
          height: fit-content;
          height: -moz-fit-content;
          cursor: pointer;
        }
      }
    }

    .inventory_table {
      .the_row {
        display: grid;
        // grid-template-columns:.2fr 1fr 1fr 1fr 1fr 1fr 0.1fr;
        grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
        padding: 1rem;

        &:nth-child(odd) {
          background: #f1f1f1;
          box-shadow: 0 2px 1px rgb(216, 216, 216);
        }

        &:first-child {
          font-weight: bold;
        }

        .item {
          font-size: 0.9vw;
        }

        .ti-trash {
          font-size: 1.2vw;
          color: red;
          cursor: pointer;

          &:hover {
            transform: scale(1.3);
          }
        }
      }
    }
  }
}

.return {
  @include primary_button;
  padding: 0.5rem;
  cursor: pointer;
  height:fit-content;
  height:-moz-fit-content;
}

.out {
  color: rgb(255, 81, 0);
}

.sum_total {
  color: rgb(0, 128, 49);
  margin-top: 0;
}

.return_form {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.685);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;

  .form_container {
    background: white;
    overflow: hidden;
    width: 40%;
    // height: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: unset;
    box-shadow: 0 0px 10px rgb(32, 32, 32);
    border-radius: 10px;

    .top_containment {
      background: rgb(238, 238, 238);
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 2rem;
      box-sizing: border-box;

      .popup_title {
        font-size: 1.3vw;
        font-weight: normal;
      }

      .ti-close {
        color: red;
        font-weight: bold;
        font-size: 1.3vw;
        cursor: pointer;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .info_input {
      margin-bottom: 0;
      label {
        display: block;
        font-size: 0.9vw;
      }

      input,
      select {
        @include registeration_form(40rem);
        height: unset;
      }
    }

    .submit {
      @include submit_button;
      margin-bottom: 2rem;
    }
  }
}

.hide_element {
  display: none !important;
}

.hide_grid {
  display: block;
}

.the_top {
  display: flex;
  align-items: center;
  background: #eee;
  padding: 1rem;
  margin-bottom: 2rem;

  .title {
    padding: 0;
  }
  .other_links {
    margin-left: 5rem;
    .links {
      @include primary_button;
      font-size: 0.8vw;
      cursor: pointer;
      margin-right: 1rem;

      &:hover {
        color: #ff9000;
      }
    }
  }
}

.search_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include input;
  width: 30%;


  .ti-search {
    font-size: 1vw;
    font-weight: bold;
  }
  input {
    border: none;
    outline: none;
    width: 93%;
    font-size: 1vw;
  }
}

.size_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include input;
  width: 10%;


  .ti-search {
    font-size: 1vw;
    font-weight: bold;
  }
  input {
    border: none;
    outline: none;
    width: 93%;
    font-size: 1vw;
  }
}



.return_table {
  .the_row {
    display: grid;
    grid-template-columns: 2fr  0.05fr;
    padding: 1rem;

    .tool_detail{
      display:grid;
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    }

    .tool_summary{
      .the_tool-info{
        display: grid;
        grid-template-columns: repeat(2, 1fr );
      }
    }

    &:nth-child(odd) {
      background: #f1f1f1;
      box-shadow: 0 2px 1px rgb(216, 216, 216);
    }

    &:first-child {
      font-weight: bold;
    }

    .item {
      font-size: 0.9vw;

      ul{
        padding-left:0;
        li{
          list-style: none;

        }
      }

    }

    .return_status{
      .tool_status{
        list-style: none;
      }
    }

    .ti-trash {
      font-size: 1.2vw;
      color: red;
      cursor: pointer;

      &:hover {
        transform: scale(1.3);
      }
    }
  }
}


.record_list{
  position: absolute;
  width:100%;
  background:white;
  box-shadow: 0 0 10px #eee;
  top:3.5rem;
  left:0;
  padding: 2rem;

  .record_container{
    list-style: none;
    cursor: pointer;
    margin-bottom: 1rem;

    .borrowed_tool, .borrower{
      font-size: .9vw;
    }
  }
}
