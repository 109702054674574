.report_list-container{
  padding:2rem;
  display:grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  .report_card{
    background:white;
    box-shadow: 0 0 10px #eee;
    padding:2rem;
    width:100%;

    .det{
      font-size: 1.4rem;
      font-weight: 600;

      &:first-of-type{
        color:rgb(31, 1, 165);
        font-size: 1.8rem;
      }

      span{
        font-weight: normal;
      }
    }
  }
}

.final_report-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 2rem;

  .header_section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .header_title {
      font-size: 2rem;
      font-weight: 600;

      text-align: center;
      margin-bottom: 2rem;
    }

  }

  .first_form {
    // height: 98%;
    padding-top: 2rem;
    width: 60%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .header_section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width:100%;
      .header_title {
        font-size: 2rem;
        font-weight: 600;

        text-align: center;
        margin-bottom: 2rem;
      }

    }

    .form_title {
      font-weight: 500;
      margin-bottom: 3rem;
      width: 50%;
    }

    .input_contained {
      display: flex;
      flex-direction: column;
      width: 50%;
      margin-bottom: 1rem;
      label {
        font-size: 1.3rem;
        font-weight: 500;
      }

      input,
      select {
        outline: none;
        background: none;
        border: none;
        width: 100%;
        padding: 1rem;
        font-size: 1.5rem;
        border-radius: 8px;
        box-shadow: 0 0 10px #eee;
      }
    }
  }


.report_vet{
  height:100%;
  .header_detail {
    width: 80%;
    flex-wrap: wrap;
  }

  .det {
    font-size: 1.4rem;
    font-weight: 600;
    width: 33%;
    span {
      font-weight: 500;
    }
  }
}
  .report_findings {
    padding-top: 2rem;
    width: 100%;
    max-height: 95%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;



    .previous_response-card {
      width: 42%;
      padding: 1rem;
      font-size: 1.4rem;
      margin-bottom: 1rem;

      &:first-of-type {
        background: rgb(244, 255, 235);
      }

      &:nth-of-type(2) {
        background: rgb(235, 246, 255);
      }

      .sum {
        border-bottom: 1px solid #eee;
        color: rgb(17, 121, 3);
        span {
          font-weight: 600;
          margin-right: 2rem;
        }
      }
      .words {
        display: flex;

        span {
          &:first-of-type {
            width: 5%;
          }

          &:last-of-type {
            width: 15%;
            font-weight: 600;
          }
        }
      }
    }

    .vet_btn,
    .add {
      font-size: 1.4rem;
      padding: 1rem 2rem;
    }

    .modal-body {
      .vet_form {
        width: 45%;

        .input_contained {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 1rem;
          label {
            font-size: 1.3rem;
            font-weight: 500;
          }

          input,
          select,
          textarea {
            outline: none;
            background: none;
            border: none;
            width: 100%;
            padding: 1rem;
            font-size: 1.4rem;
            border-radius: 8px;
            box-shadow: 0 0 10px #eee;
          }
        }

        .vet_officers {
          width: 100% !important;
          box-shadow: 0 0 10px #eee;
          background: white;
          padding: 0 1rem;
          position: relative;
          margin-bottom: 10rem;
          .officer {
            background: rgb(223, 223, 223);
            border: none;
            outline: none;
            font-size: 1.2rem;
            color: rgb(41, 41, 41);
            padding: 0.5rem;
            border-radius: 10rem;

            i {
              font-size: 0.8rem;
              color: red;
              font-weight: bold;
            }
          }
          input {
            outline: none;
            background: none;
            border: none;
            padding: 1rem;
            font-size: 1.4rem;
            width: 40%;
          }

          .search_suggest {
            background: white;
            box-shadow: 0 0 10px #eee;
            width: 50%;
            position: absolute;
            height: 30rem;
            padding: 1rem 0;

            .officer_name {
              width: 100%;
              font-size: 1.4rem;
              text-align: left;
              border: none;
              outline: none;
              padding: 0.5rem 1rem;
              background: none;
              font-weight: 600;

              span {
                font-weight: 500;
                color: grey;
              }

              &:hover {
                background: rgb(236, 236, 236);
              }
            }
          }
        }

        .last_content {
          .input_contained {
            &:last-of-type {
              margin-left: 2rem;
              input {
                width: 50rem;
              }
            }
          }
        }
      }

      .add {
        margin-top: 4rem;
      }
      .content_list {
        width: 50%;

        .content_entered {
          margin: 1rem 0;
          padding: 1rem 0;
          border-bottom: 1px solid #eee;
          .words {
            display: flex;
            justify-content: space-between;
            font-size: 1.4rem;
            width: 100%;

            span {
              &:first-of-type{
                width:3%;
              }
              &:last-of-type {
                font-weight: 600;
                width:10%;
              }
            }
          }
        }
      }
    }

    .modal-footer{
      button{
        font-size: 1.4rem;
        padding: 1rem 2rem;
      }
    }
  }
  .submit {
    @include submit_button;
    font-size: 1.5rem;
    margin-top: 2rem;
  }
}
