// shadow=========================================================shadow===============================

@mixin shadow {
  box-shadow: 0 0px 3px lighten($shadow, 5%);
}

@mixin dark_shadow {
  box-shadow: 0 3px 3px darken($shadow, 2%);
}
@mixin format_btn {
  outline: none;
  border: none;
  border-radius: 8px;
  background: none;
}

// forms=====================================================FORMS==============================================

@mixin search {
  border: none;
  @include shadow;
  background: $white;
  border-radius: 7px;
}

@mixin input {
  border: none;
  @include shadow;
  background: $white;
  border-radius: 7px;
  height: fit-content;
  height: -moz-fit-content;
  outline: none;
  padding: 1rem;

  &::placeholder {
    font-size: 1.5rem;
  }
}

@mixin registeration_form($width) {
  border: none;
  @include shadow;
  background: $white;
  border-radius: 7px;
  height: 2rem;
  outline: none;
  padding: 1rem;
  display: block;
  width: $width;
  margin: 1rem 0;
  font-size: 1.4rem;
}

// typography===========================================================Typography==========================

@mixin heading {
  font-size: 2rem;
  text-align: left;
  margin: 1rem 0;
  font-weight: 500;
}

// buttons============================================================BUTTONS=================================

@mixin primary_button {
  background: none;
  border: none;
  background: $white;
  color: $black;
  box-shadow: 0 3px 6px $shadow;
  padding: 1.2rem 2.5rem;
  outline: none;
  border-radius: 5px;
  font-weight: 500;

  &:hover,
  &:focus,
  &:active {
    background: $white;
    color: lighten(#000000, 50%);
    box-shadow: 0 3px 3px $shadow;
  }
}

@mixin submit_button {
  background: none;
  border: none;
  background-image: linear-gradient(to right, $button_green-2, $button_green-1);
  color: $white;
  box-shadow: 0 3px 6px $shadow;
  padding: 1rem 2rem;
  outline: none;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  margin-left: 0.5rem;

  &:hover,
  &:focus,
  &:active {
    color: $black;
    box-shadow: 0 3px 3px $shadow;
  }
}

@mixin secondary_button {
  background: none;
  border: none;
  color: $button_green-2;
  padding: 1rem 2rem;
  outline: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 0.5rem;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $green;
    color: $black;
    padding: 1rem 2rem;
  }
}

//media queries

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 600px) {
      @content;
    }
  }

  @if $breakpoint == tab-port {
    @media (max-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint == tab-land-one {
    @media (max-width: 1400px) {
      @content;
    }
  }

  @if $breakpoint == tab-land {
    @media (max-width: 1500px) {
      @content;
    }
  }

  @if $breakpoint == large-screens {
    @media (min-width: 1500px) {
      @content;
    }
  }
}
@mixin format_btn {
  outline: none;
  border: none;
  border-radius: 8px;
  background: none;
}
