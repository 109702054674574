.basic_info,
.contact_info {
  #basic_info,
  #contact {
    div {
      label {
        display: block;
        font-size: 1.4rem;
      }

      input,
      select {
        @include registeration_form(50%);
      }

      select {
        height: 4rem;
      }
    }
  }
}


.the__selector{
  @include input;
  font-size: 1.3rem;
}