.title {
  font-size: 1.2vw;
  font-weight: normal;
  padding-top: 2rem;
  padding-left: 2rem;
}

.view_ad {
  display: grid;
  grid-template-columns: 0.3fr 1fr;

  .job_list {
    max-height: 85vh;
    overflow-y: scroll;
    padding: 1rem;

    .sub_title {
      font-size: 1.2vw;
      font-weight: normal;
      margin-bottom: 2rem;
      padding-left: 2rem;
    }

    .job_advert {
      border-bottom: 1px solid rgb(117, 117, 117);
      padding: 1rem 2rem;

      .the_title {
        font-size: 1vw;
      }

      .more_info {
        font-size: 1vw;
        // color: grey;
      }

      .apply_btn {
        @include primary_button;
        font-size: 0.8vw;
        margin-top: 1rem;
        cursor: pointer;
      }
    }
  }

  .expand_info {
    display: flex;
    justify-content: center;
    width: 100%;

    .contain_info {
      width: 80%;

      .the_title {
        font-size: 1.2vw;
      }

      .more_info {
        font-size: 1.1vw;

        span {
          margin-right: 1rem;
        }
      }

      .description {
        font-size: 1vw;
        margin: 2rem 0;
        color: grey;
      }

      .apply_btn {
        @include submit_button;
      }
    }
  }
}

.recruitment {
  display: flex;
  justify-content: center;

  .centeralize_content {
    width: 40%;

    .passport {
      height: 20vh;
      width: 25%;
      border: 2px solid #eee;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1vw;
      margin-bottom: 2rem;
      cursor: pointer;
    }

    .info_input {
      label {
        display: block;
        font-size: 0.8vw;
        font-weight: normal;
      }

      .task_input {
        @include registeration_form(98%);
      }
    }
  }
}

.submit {
  text-align: center;
  .sign {
    @include submit_button;
    font-size: 0.8vw;
  }
}

.links_btn {
  display: flex;
  align-items: center;
  background: rgb(190, 190, 190);
  .hold_btn {
    padding-left: 7rem;
    .links {
      // @include primary_button;
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      margin-right: 1rem;
      background: white;
      @include shadow;
      padding: 1rem 1rem;
      border-radius: 10px;
      font-size: 0.8vw;

      &:hover,
      &:active,
      &:focus {
        color: orange;
      }
    }
  }
}

.create_jobs {
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  .form {
    width: 50%;
    .sub_title {
      font-size: 1.2vw;
      margin-bottom: 3rem;
    }
    .info_input {
      label {
        display: block;
        font-size: 0.9vw;
      }

      .form_input {
        @include registeration_form(98%);
      }

      textarea {
        @include registeration_form(98%);
        height: auto;
      }
    }

    .btn_holder {
      text-align: center;
      margin-top: 3rem;

      .submit {
        @include submit_button;
      }
    }
  }
}

.job_application {
  margin-top: 1rem;

  .hold_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    margin-bottom: 1rem;
    .sub_title {
      font-size: 1.2vw;
      // margin-bottom: 3rem;
    }

    .search {
      margin: 0;
      height: unset;
      display: flex;
      justify-content: space-between;
    }
  }

  .applicant_table {
    .row {
      display: grid;
      grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 0.1fr;
      font-size: 1vw;
      padding: 0.5rem 2rem;

      &:first-child {
        font-weight: bold;
      }

      &:nth-child(odd) {
        background: #eee;
        @include shadow;
      }
    }
  }
}
