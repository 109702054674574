.index_main {
  max-width: 100vw;

  .feature_cards {
    width: 100%;
    text-align: center;
    margin: 3rem 0;
    height: 20vh;

    .cards {
      display: inline-block;
      width: 25%;
      text-align: center;
      padding: 2rem 1rem;
      background: $body_grey;
      @include shadow;
      border-radius: 7px;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      img {
        position: absolute;
        transform: rotate(45deg);
        width: 20rem;
        left: 0;
      }

      &:hover {
        background: $green;
        transform: translateY(-10%);

        .sub {
          color: $white;
        }
      }

      &:nth-child(2) {
        margin: 0 3rem;
      }

      .heading {
        font-size: 1.8rem;
        margin-bottom: 2rem;
        color: $employee_profile-color;
      }

      .sub {
        font-size: 1.5rem;
        color: grey;
        text-align: left;
        margin-left: 1rem;
      }
    }
  }

  .briefing {
    margin-top: 10rem;

    .case {
      padding: 3rem 8rem;

      &:nth-child(odd) {
        background: darken($body_grey, 3%);
      }

      .payroll__img {
        width: 100%;
        @include shadow;
      }

      .main__briefing {
        padding: 3rem;

        .the_title {
          font-size: 3rem;
          color: $black;
        }

        .sub {
          margin-top: 3rem;
          margin-bottom: 1rem;
          font-size: 1.6rem;
          color: $green;
          font-weight: 500;
        }

        ul {
          li {
            font-size: 1.5rem;
            margin: 2rem 0;
            color: grey;
          }
        }
      }
    }
  }
}

.big-green_box {
  height: 75vh;
  width: 40%;
  border-radius: 10px;
  background-image: linear-gradient(to right bottom, $g_box, $shadow);
  position: fixed;
  top: 200px;
  left: 0%;
  z-index: -1;
  transform: rotate(135deg);
}

.the___image {
  margin-bottom: 20rem;
  position: relative;

  img {
    width: 90% !important;
  }
}

.carousel-caption {
  margin-top: 100px;

  h5 {
    color: black;
    font-size: 5rem;
    font-weight: bold;
  }

  p {
    font-size: 1.6rem;
    color: rgb(112, 112, 112);
    text-align: left;
  }
}


.navbar-collapse{
  display: flex!important;
    justify-content: flex-end;
    flex-basis: auto;
}