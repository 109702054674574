.section_head {
  background: rgb(243, 243, 243);

  .hold_up {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(179, 179, 179);
    padding: 2rem 0;
    .heading {
      display: flex;
      align-items: center;

      .project_name {
        font-size: 2vw;
      }

      .ti-trash {
        font-weight: 500;
        margin-left: 1rem;
        cursor: pointer;
        font-size: 1.4vw;
        color: red;
      }
    }

    .btns {
      padding: 0 2rem;

      .top_btn {
        @include primary_button;
        cursor: pointer;
        &:last-child {
          margin-left: 1rem;
        }
      }
    }
  }

  .task_briefing {
    display: grid;
    grid-template-columns: 1fr 1fr 0.6fr 0.5fr 1fr;
    padding: 3rem 2rem;

    h5 {
      span {
        display: block;
        &:first-child {
          font-weight: 600;
          font-size: 0.8vw;
        }

        &:last-child {
          font-weight: normal;
          font-size: 1.1vw;
          color: rgb(68, 68, 68);
        }
      }
    }
  }
}

.task_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 2rem 1rem;
  // display: none;
  .task_card {
    @include shadow;
    // height: 34vh;
    width: 90%;
    background: #fff;
    border-radius: 7px;
    padding: 1rem;
    cursor: pointer;
    // border-top: 5px solid $button_green-1;

    &:hover {
      background: $button_green-1;
      color: white;
    }

    &:hover .badge {
      color: black;
    }

    &:hover .description {
      color: black;
    }

    .first {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      // margin: 2rem 0;
      .header {
        font-size: 1.4vw;
        font-weight: normal;
        width: 90%;
        margin-bottom: 1rem;
      }

      .ti-more-alt {
        font-size: 1vw;
        cursor: pointer;
      }
    }

    .description {
      color: orange;
      font-size: 0.8vw;
    }

    .more_info {
      // margin-bottom: 1.5rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin: 1rem 0;

      .the_info {
        font-size: 0.9vw;
        font-weight: bold;

        .activity {
          font-weight: normal;
        }
      }
    }

    .the__members {
      font-size: 0.8vw;
      background: #eee;
      padding: 0.5rem 1rem;
      border-radius: 50px;
      color:black;
    }

    .badge {
      padding-right: 1rem;
      padding-bottom: 1rem;
      text-align: right;
    }
  }
}

// create project=========================================CREATE project=================CREATE project=====

.create_project {
  padding: 2rem;
  .title {
    font-size: 1.2vw;
    padding: 0;
    margin-bottom: 2rem;
    font-weight: normal;
  }
  .form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .info_input {
      margin-right: 2rem;
      label {
        display: block;
        font-size: 0.8vw;
        font-weight: 600;
      }

      .task_input {
        @include registeration_form(98%);
        height: unset;
      }
    }
  }

  .responsible {
    .instruction {
      display: flex;
      justify-content: right;
      align-items: center;
      padding: 0 5rem;

      .information {
        font-size: 1vw;
      }

      .instruction_btn {
        @include primary_button;
        margin-left: 1rem;
        cursor: pointer;
      }
    }

    .team_selection {
      margin-top: 2rem;
      .specify {
        display: flex;
        align-items: center;
        background: rgb(245, 245, 245);
        padding: 3rem;
        

        .department {
          @include registeration_form(21vw);
          height: unset;
          margin: 0 1rem;
        }

        .invite {
          @include primary_button;
          cursor: pointer;
          padding: 1rem;
        }
      }

      .members {
        .information {
          font-size: 1vw;
          color: rgb(255, 94, 0);
        }

        .list {
          display: grid;
          grid-template-columns: repeat(8, 1fr);
        }
        .person {
          display: flex;
          font-size: 1vw;

          .name {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
  .final {
    text-align: center;
    .submit_button {
      @include submit_button;
    }
  }
}

// create task=========================================CREATE task=================CREATE task=====

.new_task-section {
  padding: 2rem;
  .title {
    font-size: 1.2vw;
    margin-bottom: 2rem;
  }
  .form {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;

    .info_input {
      margin-right: 2rem;
      label {
        display: block;
        font-size: 0.8vw;
        font-weight: 600;
      }

      .task_input {
        @include registeration_form(21vw);
        height: unset;
      }
    }
  }

  .responsible {
    .instruction {
      display: flex;
      justify-content: right;
      align-items: center;
      padding: 0 5rem;

      .information {
        font-size: 1vw;
      }

      .instruction_btn {
        @include primary_button;
        margin-left: 1rem;
        cursor: pointer;
      }
    }

    .team_selection {
      margin-top: 2rem;
      .specify {
        display: flex;
        align-items: center;
        background: rgb(245, 245, 245);
        padding: 3rem;
        .department_name {
          margin-right: 15rem;
          font-size: 1.2vw;
          font-weight: normal;
        }

        .department {
          @include registeration_form(21vw);
          height: unset;
          margin: 0 1rem;
        }
      }

      .members {
        .information {
          font-size: 1vw;
          color: rgb(255, 94, 0);
        }

        .list {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
        }
        .person {
          display: flex;
          font-size: 1vw;

          .name {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
  .final {
    text-align: center;
    .submit_button {
      @include submit_button;
      padding: .5rem 1rem;
    }
  }
}

// .project {
//   .up_part {
//     .heading {
//       font-size: 1.8rem;
//       display: inline-block;
//       width: 90%;
//     }

//     .back_button {
//       background: none;
//       border: none;
//       cursor: pointer;

//       &:hover {
//         color: $green;
//       }
//     }
//   }

//   .project_owner {
//     .the__selection {
//       margin: 3rem 0;
//       .select__member {
//         width: 90%;
//         @include input;
//         height: 2rem;
//         display: flex;
//         justify-content: center;

//         #nav_search {
//           width: 95%;
//           height: 100%;
//           border: none;
//           outline: none;
//           margin-bottom: 2rem;
//         }
//       }
//     }

//     .the_select {
//       height: auto;
//       display: grid;
//       border-bottom: 1px solid rgb(230, 230, 230);
//       padding: 0 2rem;
//       grid-template-columns: repeat(2, 1fr);
//       .the_container {
//         margin-bottom: 1rem;
//         display: flex;
//         .owners_image {
//           background: url(/images/user_img/adult-contemporary-designer-suit-1405963.jpg);
//           height: 6vh;
//           width: 7%;
//           background-position: top;
//           background-size: cover;
//           border-radius: 50px;
//           display: inline-block;
//         }

//         .owner_name {
//           font-size: 1.5rem;
//           margin-left: 1rem;
//           margin-top: 1rem;

//           span {
//             display: block;
//           }
//         }
//       }
//       .information {
//         font-size: 1.4rem;
//         color: $green;
//       }
//     }
//   }
// }

.the__project-table {
  margin-top: 0rem;
  .top {
    .project_filtering {
      width: 100%;
      text-align: center;

      .the__message {
        font-size: 1.4rem;
        color: $orange;
      }
    }
  }
}

.departmental_search-results {
  width: 100%;
  .department {
    display: inline-block;
    margin: 0 2rem;
    .department_name {
      font-size: 1.6rem;
      margin: 2rem 0;
    }
    span {
      display: block;
      font-size: 1.4rem;
      color: lighten($black, 10%);
    }
  }
}

.the_button {
  width: 100%;
  text-align: center;
  .next {
    @include submit_button;
    cursor: pointer;
    outline: none;
    font-size: 1.3rem;
  }
}

.the__project-table {
  .top {
    .part__two {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      text-align: left;

      .parameter_contained {
        label {
          font-size: 1.4rem;
        }

        .search {
          margin: 0;
          width: 70%;
          @include search;

          #nav_search {
            width: 70%;
          }
        }
      }
    }
  }
}

.allow {
  .main_contents {
    .checking {
      display: inline-block;
      margin-right: 4rem;
      font-size: 1.4rem;
      font-weight: normal;
    }
  }
}

.upload_section {
  margin: 3rem 0;
  .the_upload-title {
    font-size: 1.4rem;
    cursor: pointer;
    .ti-upload {
      color: $orange;
      font-weight: bold;
      margin: 0 1rem;
    }
  }
  .message {
    font-size: 1.2rem;
    color: orangered;
    margin-left: 1rem;
  }
}

.back__btn {
  @include secondary_button;
}

.performance_dash {
  .heading {
    font-size: 1.7vw;
    font-weight: normal;
    padding: 0 2rem;
  }
  .info {
    width: 100%;
    display: flex;
    justify-content: center;
    .leave_detail {
      width: 20%;
      text-align: center;
      span {
        display: block;
      }
      .available {
        font-size: 4.5rem;
      }

      .info_name {
        font-size: 1.2vw;
        font-weight: 500;
        display: inline-block;
        width: 60%;
        color: $white;
        @include dark_shadow;
        // padding: 0.5rem 0;
      }

      .green {
        background-color: $green;
      }

      .peach {
        background: rgb(223, 0, 74);
      }

      .orange {
        background: $orange;
      }

      .sub_info {
        font-size: 1.3rem;
        font-weight: normal;
        color: darken($table_grey, 30%);
      }
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right_part {
      display: flex;
      align-items: center;
      justify-content: right;
      width: 40%;

      .new {
        @include primary_button;
        // height: 7vh;
        font-size: 0.8vw;
        cursor: pointer;
      }

      .search {
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 7vh;
        padding:1rem;
        margin: 1rem 3rem;
        @include search;
        
        #nav_search{
          border:none;
          outline:none;
        }
      }
    }
  }
}


.priority_selection {
  padding: 0 3rem;
  display: flex;
  align-items: center;

  font-size: 1vw;

  span {
    margin-left: 1rem;
  }
}

.activites_group {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 2rem 1rem;
  // display: none;
  .task_card {
    @include shadow;
    // height: 34vh;
    width: 90%;
    background: #fff;
    border-radius: 7px;
    padding: 1rem;
    cursor: pointer;
    // border-top: 5px solid $button_green-1;

    &:hover {
      background: $button_green-1;
      color: white;
    }

    &:hover .badge {
      color: black;
    }

    &:hover .description {
      color: black;
    }

    .first {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      // margin: 2rem 0;
      .header {
        font-size: 1.4vw;
        font-weight: normal;
        width: 90%;
        margin-bottom: 1rem;
      }

      .ti-more-alt {
        font-size: 1vw;
        cursor: pointer;
      }
    }

    .description {
      color: orange;
      font-size: 0.8vw;
    }

    .more_info {
      // margin-bottom: 1.5rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin: 1rem 0;

      .the_info {
        font-size: 0.9vw;
        font-weight: bold;

        .activity {
          font-weight: normal;
        }
      }
    }

    .the__members {
      font-size: 0.8vw;
      background: #eee;
      padding: 0.5rem 1rem;
      border-radius: 50px;
    }

    .badge {
      padding-right: 1rem;
      padding-bottom: 1rem;
      text-align: right;
    }
  }
}


button{
  outline:none !important;
}