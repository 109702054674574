.grid_container {
  display: grid;
  grid-template-columns: 0.3fr 1fr;

  .form_holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #eee;
    height: 100vh;
    padding: 1rem;
    box-sizing: border-box;
    .title {
      font-size: 1.2vw;
      margin-bottom: 2rem;
      // margin-top: 2rem;
      margin-left: 0;
      padding-left: 0;
      text-align: left;
      display: block;
    }

    .hold_input {
      height: 85%;

      .info_input {
        margin-bottom: 2rem;
        label {
          display: block;
          font-size: 0.9vw;
        }

        input {
          @include registeration_form(94%);
          height:fit-content;
        }
      }

      .checker {
        margin-bottom: 2rem;
        padding: 0 3rem;
        text-align: right;
        font-size: 0.9vw;
      }

      .btn_hold {
        text-align: center;

        .submit {
          background: none;
          border: none;
          background-image:linear-gradient(to right, $button_green-2, $button_green-1);
          color: $white;
          box-shadow: 0 3px 6px $shadow;
          padding: 1.6rem 3.2rem;
          outline: none;
          border-radius: 5px;
          font-weight: 500;
          cursor: pointer;
        }

        .grey_out{
          background:grey;
          color:black;
        }
      }
    }
  }

  .content_right {
    padding: 1rem 2rem;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .title {
        padding: 0;
        margin: 0;
        display: block;
      }
      .secondary-btn {
        @include primary_button;
        margin-bottom: 1rem;
      }
    }

    .site_table {
      .the_row {
        display: grid;
        grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 0.5fr 0.5fr;
        padding: 1rem;

        &:nth-child(odd) {
          background: #f1f1f1;
          box-shadow: 0 2px 1px rgb(216, 216, 216);
        }

        .item {
          font-size: 0.9vw;
        }

        .delete{
         text-align: right;
         i{
           color:red;
           font-weight: bold;
           font-size: 1.2vw;
           cursor:pointer;

           &:hover{
             color:#ff9000;
           }
         }
        }
      }
    }
  }
}


.whole_span{
  display:block !important;
}

.hide_element{
  display:none !important;
}
