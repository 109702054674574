.chats {
    max-height: 80vh;
    .preview {
      width: 100%;
      height: 100%;

      .title {
        font-size: 1.4rem;
        font-weight: 600;
        width: 100%;
        text-align: center;
        padding-left: 2rem;
        padding-bottom: 2rem;

        span {
          margin-left: 4rem;
        }
      }

      .old_date {
        text-align: center;
        margin: 2rem 0;
        font-weight: 600;
        font-size: 1.1rem;
        border-bottom: 1px solid rgb(214, 214, 214);
      }
      .old {
        height: 87%;
        overflow-y: scroll;
        padding: 2rem;
        .message_bubble {
          width: 60%;
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;

          .date {
            font-size: 1rem;
            width: 12%;
          }

          .message {
            font-size: 1.2rem;
            background: white;
            box-shadow: 0 0 15px rgb(212, 212, 212);
            height: fit-content;
            height: -moz-fit-content;
            padding: 1rem;
            width: 90%;
            border-radius: 10px;

            .document_container {
              background: rgb(150, 149, 149);
              padding: 1rem;
              .doc_attached {
                background: none;
                outline: none;
                border: none;
                color: blue;
                text-decoration: underline;
                cursor: pointer;
                display: block;
              }
            }
          }
        }

        .sender .date {
          order: 2;
        }

        .sender {
          margin-left: 35rem;
          margin-top: 1rem;

          .message {
            background: rgb(70, 70, 70);
            color: white;
            margin-right: 1rem;
          }
        }
      }

      .converse {
        padding: 0 2rem;
        position: relative;
        input {
          font-size: 1.4rem;
          width: 80%;
          background: white;
          box-shadow: 0 0 10px rgb(116, 116, 116);
          padding: 0.9rem;
          border: none;
          outline: none;
          border-radius: 0.7rem;
          margin-right: 1rem;
        }

        .attach {
          padding: 1rem;

          .no_of-doc {
            color: white;
            background: rgb(190, 52, 2);
            height: 0.5rem;
            width: 2rem;
            padding: 0.2rem 0.5rem;
          }
        }

        .attachment_container {
          position: absolute;
          right: 0;
          bottom: 5rem;
          background: white;
          box-shadow: 0 0 10px rgb(190, 190, 190);
          width: 30rem;
          height: fit-content;
          height: -moz-fit-content;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 3rem 1rem;

          .choose {
            span {
              font-size: 1.2rem;

              &:first-of-type {
                margin-right: 1rem;
              }

              input {
                width: unset;
              }
            }
          }

          .the_destination {
            text-align: center;

            input {
              &:first-of-type {
                margin-bottom: 1rem;
              }
            }
          }
        }

        .submit {
          background: linear-gradient(to right, #0ca678, #06533c);
          color: white;
          padding: 0.7rem 1rem;
          border: none;
          outline: none;
          cursor: pointer;

          &:hover {
            color: rgb(236, 236, 236);
          }
        }
      }
    }
  }
