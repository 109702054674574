.grid_container {
  display: grid;
  grid-template-columns: 0.3fr 1fr;

  .form_holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #eee;
    height: 92vh;
    padding: 1rem;
    box-sizing: border-box;
    .title {
      font-size: 1.2vw;
      margin-bottom: 2rem;
      margin-top: 2rem;
      margin-left: 0;
      padding-left: 0;
      text-align: left;
    }

    .hold_input {
      height: 85%;

      .info_input {
        margin-bottom: 2rem;
        label {
          display: block;
          font-size: 0.9vw;
        }

        input {
          @include registeration_form(94%);
        }
      }

      .checker {
        margin-bottom: 2rem;
        padding: 0 3rem;
        text-align: right;
        font-size: 0.9vw;
      }

      .btn_hold {
        text-align: center;

        .submit {
          @include submit_button;
        }
      }
    }
  }

  .content_right {
    padding: 1rem 2rem;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .title {
        padding: 0;
        margin: 0;
      }
      .secondary-btn {
        @include primary_button;
        margin-bottom: 1rem;
        padding: 1rem;
        font-size: 0.9vw;
        cursor: pointer;
      }
      .filter_btn {
        @include primary_button;
        margin-bottom: 1rem;
        padding: 1rem;
        font-size: 0.9vw;
        cursor: pointer;
      }

      .criteria {
        background: rgb(80, 80, 80);
        border: none;
        outline: none;
        font-size: 0.8vw;
        padding: 0.5rem;
        cursor: pointer;
        border-radius: 10px;
        color: white;

        &:hover,
        &:active,
        &:focus {
          color: #ff9000;
        }
      }
    }

    .overtime_table {
      height: 65vh;
      .the_row {
        display: grid;
        grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr;
        padding: 1rem;

        &:nth-child(odd) {
          background: #f1f1f1;
          box-shadow: 0 2px 1px rgb(216, 216, 216);
        }

        .item {
          font-size: 0.9vw;
        }

        &:first-child {
          font-weight: 600;
        }
      }
    }
  }
}

.hide_element {
  display: none !important;
}

.hide_grid {
  display: block;
}

.last_info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 1vw;
    font-weight: 600;
    span {
      font-weight: 500;
    }
  }
}
