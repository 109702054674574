.page_title {
  padding-left: 2rem;
  padding-top: 1rem;
}

.corrective_module {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .corrective_form {
    width: 30%;
    // display: none;
    .info_input {
      //   margin-bottom: 1rem;
      margin-left: 1rem;
      label {
        display: block;
        font-size: 0.9vw;
      }

      input,
      select {
        @include registeration_form(100%);
        height: -moz-fit-content;
        height: fit-content;
        font-size: 1vw;
        margin: 0;
      }
    }

    .submit {
      @include submit_button;
      margin-top: 1rem;
    }
  }

  .step_one {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .info_input {
      //   margin-bottom: 1rem;
      margin-left: 1rem;
      label {
        display: block;
        font-size: 0.9vw;
      }

      input,
      select,
      textarea {
        @include registeration_form(100%);
        height: -moz-fit-content;
        height: fit-content;
        font-size: 1vw;
        margin: 0;
      }
    }

    .bottom_sec {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .auditor_info {
        display: flex;
      }
    }

    .submit {
      @include submit_button;
      padding: 1rem;
      font-size: 0.8vw;
    }

    .previous {
      @include primary_button;
      padding: 1rem;
      font-size: 0.8vw;
    }
  }

  .submit {
    @include submit_button;
    padding: 1rem;
    font-size: 0.8vw;
  }

  .second_person {
    .received_entry {
      display: grid;
      grid-template-columns: 0.6fr 1fr;
      width: 90%;
      padding: 2rem;

      .preview_values {
        p {
          font-size: 0.9vw;
          font-weight: 700;
          display: grid;
          grid-template-columns: 0.4fr 1fr;

          span {
            font-weight: normal;
            width: 70%;
            margin-left: 1rem;
          }
        }
      }

      .other_content-entered {
        .finding_heading {
          font-size: 1.2vw;
        }

        p {
          font-size: 0.9vw;
          margin: 2rem 0;
        }

        .hold_final_detail {
          display: flex;
          p {
            font-weight: 700;
            margin-left: 3rem;

            span {
              display: block;
              font-weight: normal;
            }
          }
        }
      }
    }

    .response {
      width: 50vw;
    }
    textarea {
      @include registeration_form(100%);
      height: -moz-fit-content;
      height: fit-content;
      font-size: 1vw;
      margin: 0;
    }

    .respondent_detail {
      font-size: 0.9vw;
      display: flex;
      justify-content: flex-end;

      p {
        font-weight: 700;
        margin-right: 3rem;

        span {
          font-weight: normal;
        }
      }
    }
  }
}
