.stationary {
  display: grid;
  grid-template-columns: 0.3fr 1fr;

  .form_holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #eee;
    height: 70vh;
    padding: 3rem 1rem;
    box-sizing: border-box;
    .title {
      margin-top: 0;
      font-size: 1.2vw;
      margin-bottom: 0;
      margin-left: 0;
      padding-left: 0;
      text-align: left;
      padding-top: 0;
    }

    .hold_input {
      // height: 80%;
      // overflow-y: scroll;

      .info_input {
        margin-bottom: 0;
        label {
          display: block;
          font-size: 0.9vw;
        }

        input {
          @include registeration_form(94%);
          height:unset;
        }
      }
    }

    .btn_hold {
      text-align: center;

      .submit {
        @include submit_button;
      }
    }
  }

  .content_right {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .top {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      width: 100%;

      .secondary-btn {
        @include primary_button;
        margin-bottom: 1rem;
        margin-left: 1rem;
        // padding: 0.5rem 1rem;
      }

      .top_filter {
        display: flex;
        margin-left: 2rem;
      }

      .stat_card {
        width: 19rem;
        height: 6rem;
        box-shadow: 0 0 8px #cecece;
        background: white;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        margin-right: 1rem;

        .notice {
          background: $employee_profile-color;
          border-top-right-radius: 100px;
          border-bottom-right-radius: 100px;
        }

        .transfer {
          background: $button_green-2;
          border-top-right-radius: 100px;
          border-bottom-right-radius: 100px;
        }

        .card_title {
          color: rgb(255, 255, 255);
          font-size: 1.3rem;
          text-align: left;
          font-weight: 500;
          height: 100%;
          width: 50%;
          padding: 1rem;
          line-height: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom:0;
        }

        .total_num {
          font-size: 3rem;
          padding-right: 1rem;
        }
      }

      .secondary-btn{
        margin:0;
        padding:.5rem 1rem;
      }
      .filter {
        display: flex;
        align-items: flex-end;
        width: 30%;
        justify-content: space-between;
        margin-left: 3rem;
        position: relative;

        .the_list {
          position: absolute;
          top: 6rem;
          left: 0;
          background: white;
          font-size: 0.8vw;
          @include shadow;
          width: fit-content;
          width: -moz-fit-content;
          padding: 2rem;
          z-index: 1000;

          .team_member {
            list-style: none;
            &:not(:first-child) {
              margin-top: 1rem;
            }
          }
        }

        .info_input {
          margin-bottom: 0;
          margin-left: 1rem;
          

          label {
            display: block;
            font-size: 0.9vw;
          }

          input {
            @include registeration_form(94%);
            margin: 0;
            height: unset;
          }
        }

        .search {
          display: flex;
          justify-content: space-around;
          align-items: center;
          margin: 0;
          border: none;
          @include shadow;
          background: $white;
          border-radius: 7px;
          width:35rem;
          padding:1rem;

          i {
            font-size: 1.5rem;
          }

          input{
            width:90%;
            border:none;
            outline:none;
            background:transparent;
            font-size: 1.4rem;

          }
        }
      }
    }

    .request_table {
      height: 65vh;
      overflow-y: scroll;
      margin-top: 1rem;
      width: 100%;

      .the_row {
        display: grid;
        grid-template-columns: 0.1fr 1fr 1fr 0.5fr 1fr 0.3fr 0.5fr 0.2fr;
        grid-column-gap: 1rem;
        padding: 1rem;

        &:nth-child(odd) {
          background: #f1f1f1;
          box-shadow: 0 2px 1px rgb(216, 216, 216);
        }

        &:first-child {
          font-weight: 600;
        }

        .item {
          font-size: 1.2rem;
        }

        .actions {
          position: relative;
          .reveal {
            background: none;
            border: none;
            outline: none;
            cursor: pointer;
          }

          .hold_actions {
            position: absolute;
            right: 1rem;
            z-index: 1000;

            .action {
              color: white;
              border: none;
              outline: none;
              padding: 0.5rem 1rem;
              margin-bottom: 1rem;
              border-radius: 5px;
              color: black;
              cursor: pointer;
              font-size: 1.3rem;
            }

            .edit {
              background: green;
              color: white;
            }
          }
        }
      }
    }

    .item_table {
      height: 65vh;
      overflow-y: scroll;
      margin-top: 1rem;
      width: 100%;

      .the_row {
        display: grid;
        grid-template-columns: 0.1fr 1fr 0.5fr 0.5fr 0.3fr .1fr;
        grid-column-gap: 1rem;
        padding: 1rem;

        &:nth-child(odd) {
          background: #f1f1f1;
          box-shadow: 0 2px 1px rgb(216, 216, 216);
        }

        &:first-child {
          font-weight: 600;
        }

        .item {
          font-size: 1.2rem;
        }

        .actions {
          position: relative;
          .reveal {
            background: none;
            border: none;
            outline: none;
            cursor: pointer;
          }

          .hold_actions {
            position: absolute;
            right: 2rem;
            top: 0;

            .action {
              color: white;
              border: none;
              outline: none;
              padding: 0.5rem 1rem;
              margin-bottom: 1rem;
              border-radius: 5px;
              color: black;
              cursor: pointer;
              font-size: 1.3rem;
            }

            .edit {
              background: green;
              color: white;
            }
          }
        }
      }
    }
  }
}

.requestors_section {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .form {
    width: 30%;
    height: fit-content;
    height: -moz-fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    box-shadow: 0 0 10px rgb(199, 199, 199);
    border-radius: 5px;
    padding: 4rem 0;

    .form_title {
      font-size: 2rem;
      font-weight: normal;
      width: 30rem;
      margin-bottom: 3rem;
    }

    .hold_input {
      width: 30rem;
      .info_input {
        margin-bottom: 0;

        label {
          display: block;
          font-size: 0.9vw;
        }

        input {
          @include registeration_form(90%);
          margin-top: 0.1rem;
          font-size: 1.3rem;
          height: unset;
        }
      }
      .card_suggest-container {
        position: relative;

        .card_suggest {
          position: absolute;
          width: 90%;
          background: rgb(44, 44, 44);
          z-index: 100;
          height: 20rem;
          top: 4rem;
          padding: 1rem;

          .item {
            width: 100%;
            font-size: 1.3rem;
            color: rgb(204, 204, 204);
            display: flex;
            justify-content: space-around;
            cursor: pointer;

            &:not(:last-of-type) {
              border-bottom: 1px solid rgb(66, 66, 66);
            }

            &:hover {
              color: rgb(223, 145, 2);
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.hide_element {
  display: none !important;
}

.hide_grid {
  display: block;
}

.the_top {
  display: flex;
  align-items: center;
  background: #eee;
  padding: 1rem;
  margin-bottom: 2rem;

  .title {
    padding: 0;
  }
  .other_links {
    margin-left: 5rem;
    .links {
      @include primary_button;
      font-size: 0.8vw;
      cursor: pointer;
      margin-right: 1rem;

      &:hover {
        color: #ff9000;
      }
    }
  }
}
