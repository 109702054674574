.main_info {
  .reference_table {
    margin-top: 0;
    padding: 3rem 0;
    .top {
      width: 100%;
      padding: 0 2rem;
      .right_part {
        display: inline-block;
        text-align: right;
        width: 100%;
        .search {
          display: inline-block;
          margin-left: 4rem;
          @include search;
          width: 35%;
          height: 4rem;
          padding: 0 0.5rem;

          #nav_search {
            border: none;
            background: none;
            width: 90%;
            height: 100%;
            outline: none;
          }

          .search_icon {
            img {
              margin-bottom: -0.5rem;
              width: 2rem;
            }
          }
        }
      }
    }
  }
}

.filter_criteria {
  @include input;
  margin: 0 1rem;
  border: none;
}

.search_for-staff {
  @include input;
  height: 4rem;
  font-size: 1.5rem;
  width: 40%;
  margin-left: 30%;
  margin-top: 3%;
}

.main__button {
  text-align: center;
  .payroll_category-btn {
    @include submit_button;
    font-size: 1.3rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

.the_staff {
  margin: 3rem;
  .the_name {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .the_salary {
    font-size: 1.6rem;
  }
}

.payroll__table {
  .the_payroll-table {
    text-align: center;
    width: 100%;
    .add__button {
      @include primary_button;
      margin: 3rem 0;
      padding: 1rem 2rem;
      font-size: 1.3rem;
      color: $green;
    }
    .the_payroll-div,
    .the__payroll-row {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      span {
        font-size: 1.4rem;
        display: block;
        padding: 2rem;
      }
    }

    .the_payroll-div {
      margin-bottom: 2rem;
      font-weight: bold;
      background: $table_grey;
      @include dark_shadow;
    }

    .the__payroll-row {
      &:nth-child(even) {
        background: $table_grey;
        @include dark_shadow;
        color: rgb(90, 90, 90);
      }
    }
  }
}

.payroll___drop {
  text-align: left;
  margin-bottom: 3rem;
  select {
    @include input;
    width: 90%;
    font-size: 1.3rem;
  }
}

.info_input {
  margin-bottom: 2rem;
  label {
    color: black;
    margin-bottom: 0.5rem;
    font-weight: normal;
  }

  h6 {
    color: white;
    font-size: 1.7rem;
    font-weight: normal;
  }
}
